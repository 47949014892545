import React, {useState} from 'react';
import { Container } from './styles';

import Tituloh3 from '../../../components/Tituloh3';
import {Button} from '@material-ui/core';
import Agendaaccordion from '../../../components/Agendaaccordion';
import Agendanew from '../../../components/Agendanew';


function Agenda(props) {
  const [newevent, setNewevent] = useState(false);
  const [showacor, setShowacor] = useState(true);

  // filtros
  const ftCriar=obj=>{
    if (('slug' in obj && obj.slug =='create_events')) {
      return true;
    } else {
      return false;
    }
  }
  const ftListar=obj=>{
    if (('slug' in obj)) {
      return true;
    } else {
      return false;
    }
  }

  return (
  <Container style={{maxWidth:'1920px', margin:'auto'}}>
        <Tituloh3 
          text="Agenda"
          description="Nesta página é possível visualizar, excluir e adicionar os eventos presentes da agenda do site."
        />
        <br/>
        {/* Novo evento  */}
        {(props.user.roles.filter(ftCriar).length>0)?
        <div style={{textAlign:'right'}}>
          <Button onClick={()=> {setNewevent(true);setShowacor(false)}} variant='contained' color='secondary'>Novo Evento</Button>
        </div>
        :''}
        <br/>
        {/* Lista de eventos  */}
        {showacor &&  (props.user.roles.filter(ftListar).length>0)? <Agendaaccordion user={props.user}/> :''}
        {/* Diálogo de adicionar novo  */}
        {newevent?
          <Agendanew user={props.user} fecharDialogo={()=> {setNewevent(false);setShowacor(true)}}/>
        :''}
  </Container>
    );
}

export default Agenda;