import styled from 'styled-components';

export const Container = styled.div`

    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.1);
    padding:1rem;
    min-height: ${window.innerHeight};
    
`;