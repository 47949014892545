import styled from 'styled-components';

export const Options = styled.div`
    display: flex ; 
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    font-family:Arial, Helvetica, sans-serif;
    /* font-weight:bold; */

    /* opacity: 0.5;
    :hover{
      opacity: 1;
    } */

    div{
        display:flex;
         justify-content: center;
        justify-items: center;
        justify-self: center;
        text-align: center;
    }

    a{
        text-decoration: none;
    }

`;