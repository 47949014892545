import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import PostAddIcon from '@material-ui/icons/PostAdd';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SettingsIcon from '@material-ui/icons/Settings';

import Requisicaorss from '../Requisicaorss';

import {Link, useParams} from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
   
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  customlink:{
    '& a':{
      color: 'rgba(0,0,0,1)',
      textDecoration: 'none',
      '&:hover':{
        color: 'rgba(0,83,81,1)',
      },
    }
  },
  cabinfo:{
    background:'rgba(0,0,0,0.1)', 
    padding:'0.9rem 1rem', 
    flex:1,
    float:"right",
    "&:hover":{
      background:'rgba(255,255,255,0.1)',
      cursor:'default',
    }
  },
  cablogoff:{
    // margin:'auto 1.5rem', 
    padding: 'auto 1.5rem', 
    width:'50px',
    float:"right",
    cursor:'pointer',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    "&:hover":{
      background:'rgba(255,255,255,0.1)'
    }
  },
  itemdomenu:{
    ['& 	.MuiListItemText-primary']:{
      fontSize:'14px ',
    },
    ['& .MuiListItemIcon-root']:{
      ['& svg']:{width:'20px !important'},
      color: 'rgba(0,0,0, 0.3)',
    },
    '&:hover':{
      borderLeft: '3px solid rgba(0,83,81,1)',
      transition: '0.2s',
    }
},
selecionado:{
  borderLeft: '3px solid rgba(0,83,81,1)',
  color: 'rgba(0,83,81,1)',
  transition: '0.2s',
  ['& 	.MuiListItemText-primary']:{
      fontSize:'14px ',
    },
  ['& .MuiListItemIcon-root']:{
      ['& svg']:{width:'20px !important'},
      color: 'rgba(0,83,81,1)',
  }
},
}));

export default function MiniDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(window.innerWidth>675? true: false);

  const handleDrawerOpen = () => {
    setOpen(true);
    props.abrir();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    props.abrir();
  };

  //filtros
  const ftbanner=obj=>{
    if (('slug' in obj)) {
      return true;
    } else {
      return false;
    }
  }
  const ftpost=obj=>{
    if (('slug' in obj)) {
      return true;
    } else {
      return false;
    }
  }
  const ftevento=obj=>{
    if (('slug' in obj)) {
      return true;
    } else {
      return false;
    }
  }
  const ftfuncionario=obj=>{
    if (('slug' in obj)) {
      return true;
    } else {
      return false;
    }
  }
  const ftalbum=obj=>{
    if (('slug' in obj)) {
      return true;
    } else {
      return false;
    }
  }
  const ftrelatorio=obj=>{
    if (('slug' in obj)) {
      return true;
    } else {
      return false;
    }
  }
const ftusuario=obj=>{
  if ('id' in obj && obj.id == 22) {
    return true;
  } else {
    return false;
  }
}

let { id2 } = useParams();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{ backgroundColor: 'rgba(0,83,81,1)',}}
      >
        <Toolbar style={{padding:'0rem'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
            style={{margin:'0rem 0.8rem'}}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap={window.innerWidth>675? false:true} style={{width:'100%',margin:'0rem 1rem', textAlign:'left'}}>
            Admin Alan Araújo Engenharia
          </Typography>
          <div style={{width:'40%', maxWidth:'300px', textAlign:'right', display:`${window.innerWidth>675?'flex':'none'}`, flexDirection:'row'}}>
              <div className={classes.cabinfo}>
                {props.user.name}<br/>
                {props.user.email}
              </div>
              <div className={classes.cablogoff} onClick={props.logOff}>
                <ExitToAppIcon/>
              </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
        <a href="https://www.alanaraujo.eng.br" target="_blank"><img src='../files/images/logo/image001.png' style={{width:'95%', }}/></a>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List  className={classes.customlink}>
            {window.innerWidth>675?
             <ListItem >
               <ListItemText secondary={<div style={{opacity:'90%', textAlign:'center'}}>Menu</div>}/>
             </ListItem>
             :
             <ListItem button>
             <ListItemIcon><AccountCircleIcon/></ListItemIcon>
               <ListItemText primary={<>
                 <small style={{opacity:'90%'}}>Seja bem vindo</small>
                 <br/> 
                 {props.user.name}
                 <br/>
                 <button onClick={props.logOff} style={{
                   border:'0px',
                   fontFamily:"'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                 }}>Sair</button>
               </>} />
            </ListItem>
            }
            <Divider />
    
            {(props.user.roles.filter(ftbanner).length>0)?
             <Link to='/banners'>
             <ListItem button className={(id2 =='banners')? classes.selecionado:classes.itemdomenu}>
                  <ListItemIcon><AspectRatioIcon /> </ListItemIcon>
                  <ListItemText primary={'Banners'} />
             </ListItem>
             </Link>
            :''}
             {(props.user.roles.filter(ftevento).length>0)?
              <Link to='/agenda'>
              <ListItem button className={(id2 =='agenda')? classes.selecionado:classes.itemdomenu}>
                 <ListItemIcon><DateRangeIcon/></ListItemIcon>
                 <ListItemText primary={'Agenda'} />
              </ListItem>
            </Link>
            :''}
             {(props.user.roles.filter(ftalbum).length>0)?
            <Link to='/albuns'>
            <ListItem button className={(id2 =='albuns')? classes.selecionado:classes.itemdomenu}>
              <ListItemIcon><PhotoAlbumIcon/></ListItemIcon>
              <ListItemText primary={'Álbuns'} />
            </ListItem>
          </Link>
            :''}
         {(props.user.roles.filter(ftpost).length>0)?
          <Link to='/blog' >
          <ListItem button className={(id2 =='blog')? classes.selecionado:classes.itemdomenu}>
            <ListItemIcon><PostAddIcon/></ListItemIcon>
            <ListItemText primary={'Blog'} />
          </ListItem>
         </Link>
            :''}
         {(props.user.roles.filter(ftfuncionario).length>0)?
         <Link to='/funcionarios'>
         <ListItem button className={(id2 =='funcionarios')? classes.selecionado:classes.itemdomenu}>
           <ListItemIcon><PeopleAltIcon/></ListItemIcon>
           <ListItemText primary={'Funcionários'} />
         </ListItem>
        </Link>
            :''}
            {(props.user.roles.filter(ftrelatorio).length>0)?
             <Link to='/relatorio'>
              <ListItem button  className={(id2 =='relatorio')? classes.selecionado:classes.itemdomenu}>
                <ListItemIcon><ListAltIcon/></ListItemIcon>
                <ListItemText primary={<>Relatório de<br/> Inscrições</>} />
              </ListItem>
              </Link>
                 :''}
         
          <Link to='/configuracoes'>
           <ListItem button className={(id2 =='configuracoes')? classes.selecionado:classes.itemdomenu}>
             <ListItemIcon><SettingsIcon/></ListItemIcon>
             <ListItemText primary={<>Configurações de<br/> Conta</>} />
           </ListItem>
          </Link>
          <Divider />
          <Requisicaorss open={open}/>
          {/* {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <Link to='/' key={text}>
            <ListItem button >
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
            </Link>
          ))} */}
        </List>
      </Drawer>
    </div>
  );
}