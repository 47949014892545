import React, {useState} from 'react';
import { Container } from './styles';

import Tituloh3 from '../../../components/Tituloh3';

import Relatoriolist from '../../../components/Relatoriolist';
import Relatorionew from '../../../components/Relatorionew';


function Agenda(props) {

  // filtros 
  const ftListar=obj=>{
    if (('slug' in obj)) {
      return true;
    } else {
      return false;
    }
  }
 

  return (
  <Container style={{maxWidth:'1920px', margin:'auto'}}>
        <Tituloh3 
          text="Relatório de Incrições"
          description="Nesta página é possível visualizar as inscrições realizadas nos cursos."
        />
      
        {/* Lista de eventos  */}
        {(props.user.roles.filter(ftListar).length>0)?
        <Relatoriolist user={props.user}/>
        :''}
     
  </Container>
    );
}

export default Agenda;