import React from 'react';
import { Container } from './styles';

import Tituloh3 from '../../../components/Tituloh3';
import Configuracoesmeu from '../../../components/Configuracoesmeu';
import Configuracoesoutros from '../../../components/Configuracoesoutros';

const ftfuncionario=obj=>{
  if (('slug' in obj && obj.slug =='list_users')) {
    return true;
  } else {
    return false;
  }
}


function Configuracoes(props) {
  return (
  <Container style={{maxWidth:'1920px', margin:'auto'}}>
        <Tituloh3 
          text="Configurações de Conta"
          description="Dependendo de sua permissão, nesta página é possível visualizar, excluir e adicionar usuários ao sistema."
        />
        <Configuracoesmeu user={props.user}/>
        {props.user? props.user.roles.filter(ftfuncionario).length>0?  <Configuracoesoutros user={props.user}/>:'':''}
       
  </Container>
    );
}

export default Configuracoes;