import Styled from 'styled-components';

export const Conteudo = Styled.div`

    .minhaconta{
        display:flex;
        background: rgba(0,0,0,0.1);
        border-radius: 5px;
        justify-content: space-between;
        align-items:center;
        align-content:center;
        padding: 0.2rem 1rem;
        color: rgba(0,0,0,0.5);
        font-weight: bold;
        margin-top: 1rem;
    }
    .informacoes{
        text-align: left;
        padding: 1rem 2rem;
        font-family: Arial, Helvetica, sans-serif !important;
        color: rgba(0,0,0,0.8);
    }
`;