import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArchiveIcon from '@material-ui/icons/Archive';
import CreateIcon from '@material-ui/icons/Create';

import API from '../../services/api';
import {CardDeck} from 'react-bootstrap';
import {Options} from './styles';
import Funcionarionew from '../Funcionarionew';
import Dialogodeconfirmacao from '../Dialogosdeconfirmacao';


const useStyles = makeStyles({
  root: {
    width: 250,
    margin: '0.5rem',
  },
  rootrascunho:{
    width: 250,
    margin: '0.5rem',
    opacity: 0.8,
    ['&:hover']:{
      opacity:1,
    }
  }
});

export default function Bannerimagens(props) {
  const classes = useStyles();
  const [images,setImages] = useState([]);
  const [show, setShow] = useState(false);
  const [confirmacao, setConfirmacao] = useState([false,'']);
  const [editarlink, setEditarlink] = useState({
    status: false,
  });

  const [upload,setUpload] = useState(false);

  const carregamento =async e=>{

    const config = {
      headers: {
        'Authorization': 'Bearer ' + props.user.token,
      }
    };

    await API.get('private_staff',config)
    .then(a=>{
            setImages(a.data)
        // console.log(a);
    });

    // setShow(true);
    // console.log(images);
  }

  React.useEffect(()=>{
      if(images.length==0){ carregamento();}
  },[]);

const abrirDialogo=e=>{
  setUpload(true);
}
const fecharDialogo=e=>{
  setUpload(false);
  setEditarlink({
    status: false, 
  });
    carregamento();
}
const deleteImage=e=>{
  const config = {
    headers: {
      'Authorization': 'Bearer ' + props.user.token,
    }
  };
  API.delete(`staff/${e}`, config)
  .then(a=>{
      // window.location.reload(); 
      setTimeout(e=>{
        carregamento();
      },1000);
      setConfirmacao([false,'']);
  }).catch(error=>{
    console.log(error.response);
  });

}

const atualizar=e=>{
  API.get('banners')
  .then(a=>{
      a.data.map((a,i)=>(
          images.push({
              id: a.id,
              name: a.name,
              src: a.image_url,
              width: 4,
              height: 2   
          })
      ))   
  });
  setShow(true);
}

 // filtros 
 const ftEditar=obj=>{
  if (('slug' in obj && obj.slug =='edit_staff')) {
    return true;
  } else {
    return false;
  }
}
const ftDeletar=obj=>{
  if (('slug' in obj && obj.slug =='delete_staff')) {
    return true;
  } else {
    return false;
  }
}
const ftListar=obj=>{
  if (('slug' in obj)) {
//  if (('id' in obj)) {
    return true;
  } else {
    return false;
  }
}

const ftCriar=obj=>{
  if (('slug' in obj && obj.slug =='create_staff')) {
    return true;
  } else {
    return false;
  }
}

  return (
    <div >
       {(props.user.roles.filter(ftCriar).length>0)?
        <div style={{textAlign:'right'}}>
          <Button onClick={()=> setUpload(true)} variant='contained' color='secondary' >Novo Funcionário</Button>
        </div>
        :''}
         
    <CardDeck>
    {(props.user.roles.filter(ftListar).length>0)? images? images.map((a,i)=>(
        <Card className={a.publish? classes.root:classes.rootrascunho} key={i} style={{backgroundColor: a.publish? '':'rgba(255,237,0,.5)', borderRadius:'0px'}}>
         <CardActionArea>
           <CardMedia
             component="img"
             alt={a.name}
             height="250"
             image={a.image_url}
             title={a.name}
           />
         </CardActionArea>
          <div style={{fontSize:'12px',backgroundColor:a.publish? 'rgba(0,83,81,1)':'rgba(255,237,0,.2)', color: a.publish? "#fff":'rgba(150,75,0,1)', padding:"0.4rem", textAlign:'left', fontFamily:'calibri'}}>
            <div style={{fontSize:'14px'}}><b style={{letterSpacing:'0.1rem'}}>Nome:</b><br/>&nbsp;&nbsp; {a.name}</div>
            <div style={{fontSize:'14px'}}><b style={{letterSpacing:'0.1rem'}} >Descrição:</b><br/>&nbsp;&nbsp;{(a.graduation).slice(0,33) + (a.graduation.length>33? "..." : "")} </div>
            <div style={{fontSize:'14px'}}><b style={{letterSpacing:'0.1rem'}}>Função:</b><br/>&nbsp;&nbsp;{a.work_position} </div>
          </div>
         <CardActions>
          <Options >
            <div style={{marginTop:'0.3rem', opacity:0.6}}>
                Opções:
            </div>
            <div>
          
             {(props.user.roles.filter(ftDeletar).length>0)?
              <Tooltip title="Editar">
            <IconButton size="small" color="primary" onClick={()=> {setEditarlink({status:true, a});abrirDialogo()}}>
                <CreateIcon  style={{fontSize:'22px', marginBottom:'0.2rem'}}/>
            </IconButton>
            </Tooltip>
            :''}
            {(props.user.roles.filter(ftEditar).length>0)?
            <Tooltip title="Deletar">
           <IconButton size="small" color="secondary" onClick={()=>setConfirmacao([true,a.id])}>
             <DeleteIcon style={{fontSize:'22px', marginBottom:'0.2rem'}}/>
           </IconButton>
           </Tooltip>
           :''}
            </div>
           </Options>
         </CardActions>
        </Card>
    )):'':''}   
    {/* <Card className={classes.root} style={{display:'flex'}} onClick={abrirDialogo}>
         <CardActionArea style={{padding:'1rem'}}>
             <AddCircleIcon style={{fontSize:'40px'}}/>
            <Typography variant="h6" component="h3">
                Adicionar <br/>nova imagem
            </Typography>
         </CardActionArea>
        </Card> */}
    </CardDeck>   

    {upload? <Funcionarionew editInfo={editarlink.status? editarlink:''} fecharDialogo={()=>fecharDialogo()} user={props.user} newPosition={images.length+1}/> : ''}
    {confirmacao[0]?
    <Dialogodeconfirmacao
      tamanho="xs"
      message="Tem certeza de que deseja excluir este funcionário?"
      confirmar={()=>{deleteImage(confirmacao[1])}}
      negar={()=>setConfirmacao([false,''])}
    />:""}
    </div>
  );
}