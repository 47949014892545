import React,{useState, useEffect} from 'react';
import { Conteudo } from './styles';
import API from '../../services/api';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import Configuracoesnew from '../Configuracoesnew';
import Configuracoessenha from '../Configuracoessenha';
import Funcoesedit from '../Funcoesedit';
import Dialogodeconfirmacao from '../Dialogosdeconfirmacao';
import DeleteIcon from '@material-ui/icons/Delete';

// chips
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
      boxShadow:'0px 0px 0px rgba(0,0,0,0)',
      border:0,
    },
    chip: {
      margin: theme.spacing(0.5),
      padding: '0rem 0rem',
      backgroundColor:'rgba(255,255,100,0.5)',
    },
  }));

function Configuracoesoutros(props) {
    const classes = useStyles();
    const [minhaconta, setMinhaconta] = useState([]);
    const [minhaconta2, setMinhaconta2] = useState();
    const [confirmacao, setConfirmacao] = useState([false,'']);
    const [editar,setEditar] = useState({
      status: false,
      dados:'',
    });
    const [editsenha, setEditsenha] = useState({
      status: false,
      dados:'',
    });
    const [editfunc, setEditfunc] = useState({
      status: false,
      dados:'',
    });

    const carregamento2 =async e=>{
      const config = {
          headers: {
            'Authorization': 'Bearer ' + props.user.token,
          }
        };

      await API.get(`users/${props.user.id}`, config)
      .then(a=>{
          setMinhaconta2(a.data);
          // console.log(a.data);
      }).catch(error=>{
        console.log(error.response.data.error);
      });

      // console.log(images);
    }

    const carregamento =async e=>{
        const config = {
            headers: {
              'Authorization': 'Bearer ' + props.user.token,
            }
          };

        await API.get(`users`, config)
        .then(a=>{
            setMinhaconta(a.data);
            // console.log(a.data);
        });

        // console.log(images);
      }
      useEffect(e=>{
        if(minhaconta.length==0) carregamento();
        if(!minhaconta2) carregamento2();
      },[]);

       // filtros 
  const ftCriar=obj=>{
    if (('slug' in obj && obj.slug =='create_users')) {
      return true;
    } else {
      return false;
    }
  }
  const ftEditar=obj=>{
    if (('slug' in obj && obj.slug =='edit_users')) {
      return true;
    } else {
      return false;
    }
  }
  const ftDelete=obj=>{
    if (('slug' in obj && obj.slug =='delete_users')) {
      return true;
    } else {
      return false;
    }
  }
  const deleteImage=e=>{
    const config = {
      headers: {
        'Authorization': 'Bearer ' + props.user.token,
      }
    };
    API.delete(`users/${e}`, config)
    .then(a=>{
        // window.location.reload(); 
        carregamento();
        setConfirmacao([false,'']);
    }).catch(error=>{
      console.log(error.response);
    });
  
  }

  return (
    <Conteudo>
        <div className="minhaconta">
            {'Outras contas '}
            {(props.user.roles.filter(ftCriar).length>0)?
            <div>
              <Button onClick={()=> setEditfunc({status:true})} variant="contained" color="primary" style={{marginRight:'0.5rem'}}>
                  Funções
              </Button> 
              <Button onClick={()=> setEditar({status:true})} variant="contained" color="secondary">
                  Novo Usuário
              </Button> 
            </div>
            :''}
        </div>
        {minhaconta?
        <div>
            <div className="informacoes">
            <div  style={{display:'flex', flexDirection:'row', padding:'0.5rem'}}>
              <label style={{flex:'3'}}><b>Nome</b></label>
              <label style={{flex:'3'}}><b>Função</b></label>
              <label style={{flex:'1', textAlign:'right'}}><b>Opções</b></label>
              
            </div>
              {minhaconta.map((e,i)=>(
                (e.id!=props.user.id && e.id!=1)?
                <div key={i} style={{display:'flex',flexDirection:'row', border:'1px solid rgba(0,0,0,0.0)', alignItems:'center', margin:'0.2rem 0rem', padding:'0rem 0.5rem', borderRadius:'5px', color:'rgba(0,0,0,0.7)', boxShadow:'0px 0px 4px rgba(0,0,0,0.4)'}}>
                  <label style={{flex:'3',margin:'0rem'}}>{e.name} (<span style={{color:'#054f77', cursor:'pointer'}} onClick={()=> setEditsenha({status:true, dados:e})}> Alterar senha</span>) </label>
                  <label style={{flex:'3',margin:'0rem'}}>{e.role[0].name}</label>
                  <label style={{flex:'1',margin:'0rem', textAlign:'right'}}>
                    {(props.user.roles.filter(ftEditar).length>0)?
                     <IconButton onClick={()=> setEditar({status:true, dados:e})}>
                        <EditIcon style={{width:'20px'}}  style={{margin:'0rem'}}/>
                    </IconButton>
                    :''}
                     {(props.user.roles.filter(ftDelete).length>0)?
                     <IconButton onClick={()=>setConfirmacao([true,e.id])}>
                      <DeleteIcon style={{width:'20px'}} style={{margin:'0rem'}}/>
                    </IconButton>
                    :''}
                     
                  </label>
                </div>
                :''
              ))}
               

            </div>
        </div>:''}
        {editar.status? <Configuracoesnew user={props.user}  info={editar.dados? editar.dados:''} fecharDialogo={()=> {setEditar({status:false,dados:''}); carregamento()}} />:''}
        {editsenha.status? <Configuracoessenha user={props.user}  info={editsenha.dados} fecharDialogo={()=> setEditsenha({status:false, dados:''})} />:''}
        {editfunc.status? <Funcoesedit user={props.user}  info={minhaconta2? minhaconta2:''} fecharDialogo={()=> setEditfunc({status:false, dados:''})} />:''}
        {confirmacao[0]?
          <Dialogodeconfirmacao
            tamanho="xs"
            message="Tem certeza de que deseja deletar este usuário?"
            confirmar={()=>deleteImage(confirmacao[1])}
            negar={()=>setConfirmacao([false,''])}
          />:""}
    </Conteudo>
    );
}

export default Configuracoesoutros;