import styled from 'styled-components';

export const Options = styled.div`
    display: flex ; 
    justify-content: space-between;
    width:100%;

    a{
        text-decoration: none;
    }

`;