import Styled from 'styled-components';

export const Conteudo = Styled.div`
    margin-top: 0.5rem;
    padding:0.1rem 0.5rem;
    width: 100%;
    display: ${(props)=> props.open?'':'none'};
 
    white-space: pre-line;

    h6{
        font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        color: rgba(0,0,0,0.8);
    }
  
    .lista-noticias{
        /* background-color: rgba(0,0,0,0.3); */
        height: ${(props)=> props.tamanho*0.9}px;
        overflow:auto; 

        .noticia{
            margin:0.5rem 0.2rem;

            a{  display: flex;
                flex-direction: row;
                background-color: rgba(0,0,0,0.03);
                box-shadow: 0px 0px 4px rgba(0,0,0,0.2);
                border-radius: 5px;
                padding:0.5rem;
                opacity: 0.8;
            }
            a:hover{opacity:1}
            .mini-imagem{
                width:50px;
                height: 50px;
                border-radius: 5px;
            }
            p{
                margin:0.2rem;
                text-align: left;
                font-size: 12px;
                color: rgba(0,0,0,0.9);
            }
        }

        ::-webkit-scrollbar-track {
            background-color: #F4F4F4;
        }
        ::-webkit-scrollbar {
            width: 6px;
            background: #F4F4F4;
        }
        ::-webkit-scrollbar-thumb {
            background: #dad7d7;
        }
    }
`;