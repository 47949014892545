import Styled from 'styled-components';

export const Conteudo = Styled.div`
text-align: left;

.breadcrumbs {
  /* background: #fff; */
  /* margin-bottom: 5px; */
  padding: 0rem 1rem;
}
.breadcrumb {
  
  background-color: rgba(0,0,0,0);
  margin-bottom: 0;
  line-height: 1;
  display: inline-block; 
  border-radius: 0px;
  padding: 0.1rem 0.1rem;
  /* font-weight: bold; */
  color: #818a91;
}
.breadcrumb::before {
  display: inline-block;
  padding-right: 0px;
  padding-left: 0px;
  color: #818a91;
  content: "/"; }
.breadcrumb.active {
  color: #818a91; 
  font-weight: bold;
}
  
`;