import styled from 'styled-components';

export const Options = styled.div`
    display: flex ; 
    justify-content: space-between;
    width:100%;

    a{
        text-decoration: none;
    }

`;

var corBG = "rgba(0,0,0,0.5)";
export const Albunscard = styled.div`
     display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
    align-content: center;
    align-items: center;
    margin:0;

    /* border:2px solid ${corBG}; */
    width: 235px;
    cursor: pointer;
    opacity: 90%;
    &:hover{
        opacity: 100%;
    }

    .imgList{

        height:180px;
        width: 235px;
        background: url(${(props)=> props.imagem});
        background-size: auto 235px;
        background-position: center top;
    }
    .nameList{
        display:flex;
        flex-direction: column;
        align-items: center;
        align-content:center;
        justify-content:center;
        width: 100%;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font-weight: bold;
        color: rgba(255,255,255,0.9);
        text-shadow: 0px 0px 3px rgba(0,0,0,0.5);
        background-color: ${corBG};
        padding-top: 0;
        height: 50px;
    }
    .descList{
        width: 100%;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 12px;
        line-height: 12px;
        text-align: justify;
        padding:  0rem 0.5rem;
        color: rgba(255,255,255,0.8);
        text-shadow: 0px 0px 3px rgba(0,0,0,0.5);
        background-color: ${corBG};
        height:62px;
    }
    .duasColList{
        width: 100%;
        display: flex;
        flex-direction: row;
        /* align-content: center;
        align-items: center; */
    }
    .textoList{
        flex:2;
    }
    .dataList{
        display:flex;
        flex-direction: column;
        align-items: center;
        align-content:center;
        justify-content:center;

        height:50px;
        flex:1;

        background: rgba(0,83,81,0.9);
        color: rgba(255,255,255,1);
        padding: 0rem;
        font-family: Georgia, 'Times New Roman', Times, serif;
        margin:0%;
        align-items: center;
        justify-content: center;
    }
    .diaList{font-size: 18px; padding:0; margin:0}
    .mesanoList{font-size: 12px;}
`;
