import React,{useState, useEffect} from 'react';
import { Container } from './styles';

import Tituloh3 from '../../../components/Tituloh3';
import Albunsimagens from '../../../components/Albunsimagens';
import {Link, useParams} from 'react-router-dom';
import API from '../../../services/api';

function Albuns(props) {
  let {id} = useParams();
  const [existe, setExiste] = useState(false);
  const [info, setInfo] = useState();

  const carregamento =async e=>{
    const config = {
      headers: {
        'Authorization': 'Bearer ' + props.user.token,
      }
    };

    await API.get(`private_albums/${id}`, config)
    .then(a=>{
          setInfo(a.data);
    }).catch(error=>{
      console.log(error.response);  
    });

    setExiste(true);
  }

  React.useEffect(()=>{
      if(!existe){ carregamento();}
  },[carregamento]);

  return (
  <Container style={{maxWidth:'1920px', margin:'auto'}}>
       {info?
       <div>
        <Tituloh3 
        text={info? `Álbum: ${info.title}`:' Álbum não existe '}
        description={info? `Descrição: ${info.description}`:''}
      />
      <div style={{width:'100%'}}>
      <Albunsimagens user={props.user} info={info}/>
      </div>
      </div>
      :
      <div>
        <Tituloh3 
        text={` Álbum não existe `}
        description={`Retorne à página de álbuns`}
      />
      </div>
      }
  </Container>
    );
}

export default Albuns;