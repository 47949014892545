import React from 'react';
import { Container } from './styles';

import Tituloh3 from '../../../components/Tituloh3';
import Bannerimagens from '../../../components/Bannerimagens';

function Banners(props) {
  return (
  <Container style={{maxWidth:'1920px', margin:'auto'}}>
        <Tituloh3 
          text="Banners"
          description={<>
          Nesta página é possível visualizar, excluir e adicionar os banners presentes na página inicial do site.<br/> 
          <small>IMPORTANTE: As imagens devem ter tamanho 1200x3000 ou seguir proporção similar de forma a manter a qualidade de apresentação.</small><br/> 
          <b>Legenda: </b>
            <label style={{padding:'0.2rem',backgroundColor:'rgba(0,83,81,1)', color:'rgba(255,255,255)', borderRadius:'5px', fontSize:'12px'}}>
              Publicado </label>{' '}
              <label style={{padding:'0.2rem',backgroundColor:'rgba(255,237,0,.5)', color:'rgba(150,75,0,1)', borderRadius:'5px', fontSize:'12px'}}>
                Rascunho </label>  
          </>}
        />
        <Bannerimagens user={props.user}/>
  </Container>
    );
}

export default Banners;