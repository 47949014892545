import styled from 'styled-components';

export const Conteudo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    /* background-image: linear-gradient( to bottom, rgba(255,255,255), rgba(0,0,0,0.5)); */

    img{
        max-width: 150px;
    }
    label{
        /* margin:0.5rem; */
        /* color: rgba(255,255,255,1); */
        text-align: left;
        width: 100%;
        margin-bottom: -0.05rem;
        /* font-weight:bold; */
        color: rgba(0,83,81);
    }
    input{
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 5px;
        text-align: center;
        padding:0.5rem;
        margin: 0px;
        width: 250px;
    }
    button{
        border: 0px;
        margin:0.5rem;
        padding: 0.5rem 2rem;
        width: 100%;
        background: rgba(0,83,81);
        color: #ffffff;
        border-radius: 4px;
        opacity: 80%;
        :hover{
            transition: 0.5s;
            opacity:100%;
        }
    }

    .umaCol{
        display: flex;
        flex-direction: column;
        align-items:center;
        max-width: 500px;
        margin-top: -200px;
    }

`;