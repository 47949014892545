import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {Conteudo} from './styles';
import API from '../../services/api';
import {DropzoneArea} from 'material-ui-dropzone';
import Backdrop from '../Backdrop';


export default function Bannersupload(props) {
  const [temArq, setTemarq] = React.useState(false)
  const [open, setOpen] = React.useState(true);
  const [files, setFiles] = React.useState([]);
  const [dados, setDados] = React.useState({
      name: '',
      link:'',
  });
  const  [load, setLoad] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    props.fecharDialogo();
  };

  const handleChange=e=>{
   setFiles(e);
  }

  const submeterNovo= e=>{
    e.preventDefault();
    setLoad(true);

      const config = {
        headers: {
          "Authorization": "Bearer " + props.user.token,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true"
        }
      };
      const data = new FormData();
     
      if(props.editInfo){
        // data.append('name', dados.name);
        // data.append('link', dados.link);
        // data.append('publish', true);
        // data.append('position', dados.position);
        // API.put(`banners/${props.editInfo.a.id}`, data, config)
        // .then(a=>{
        //   window.location.reload();
        // }).catch(error=>{
        //   console.log(error.response);
        // });

      }else{
        files.map((a,i)=>{data.append('images',a)});
        API.post(`albums/${props.id}/photos`, data, config)
        .then(a=>{
          window.location.reload();
          setLoad(false);
          handleClose();
        }).catch(error=>{
          console.log(error.response);
        });
      }

      // console.log(config);
  }

  const verificarEdit=e=>{
    setDados({
      name: props.editInfo.a.name,
      link:props.editInfo.a.link,
      position: props.editInfo.a.position,
      publish: props.editInfo.a.publish,
    });
    // console.log(props.editInfo)
  }

  React.useEffect(()=>{
    if(!dados.name){
      if(props.editInfo){
        verificarEdit();
      }
    }
  },[])
  return (
    <Conteudo>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" scroll={'body'}>
       <form onSubmit={submeterNovo}>
       <DialogTitle id="form-dialog-title">{props.editInfo? 'Editar Banner': 'Nova imagem'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {props.editInfo? 'Preencha os campos abaixo e confirme para aplicar a edição.':
           'Preencha os campos abaixo corretamente para que a nova imagem seja inserida.'}
          </DialogContentText>
         
          {props.editInfo? 
            <div style={{display:'flex', flexAlign:'column'}}>
              <img src={props.editInfo.a.image_url} style={{maxWidth:"250px", margin:'auto'}}/>
            </div>: 
            <div >
            <p style={{fontSize:"17.5px", color:"rgba(0,0,0,0.5)"}}>Selecione quantas imagens desejar: </p>
                  <DropzoneArea
                  onChange={handleChange}
                  acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                  filesLimit={"50"}
                  dropzoneText="Arraste as imagens para este campo ou clique aqui para buscar"
                  maxFileSize="6000000"
                  getFileAddedMessage={(fileName)=>`Arquivo ${fileName} adicionado com sucesso.`}
                  getFileRemovedMessage={(fileName)=>`Arquivo ${fileName} removido.`}
                  getFileLimitExceedMessage={(filesLimit)=>`Número máximo de ${filesLimit} arquivos excedido.`}
                  getDropRejectMessage={()=>`Tipo de arquivo não permitido.`}
            />
            </div>
            }
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancelar
          </Button>
          <Button type="submit" color="primary"  variant="contained">
          {props.editInfo? 'Aplicar Edição': 'Enviar'}
          </Button>
        </DialogActions>
       </form>
      </Dialog>
      {load? <Backdrop/>: ''}
    </Conteudo>
  );
}