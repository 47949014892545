import React, { useMemo } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Moment from 'react-moment';
import moment from 'react-moment';
import { format } from 'date-fns';

//campos de texto
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import { Conteudo } from './styles';
import API from '../../services/api';
import { DropzoneArea } from 'material-ui-dropzone';

//campo de data
import 'date-fns'
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// editor de texto
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//Seletor auto completável
import Backdrop from '../Backdrop';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// mascaras 
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

// alertas
import { parseISO, isAfter } from 'date-fns';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  editText: {
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: '3px',
    padding: '0.6rem',

  },
  subAreas: {
    border: '2px dashed rgba(0,0,0,0.2)',
  }
}));

// mascara do cpf 
function CpfMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
    // showMask
    // required
    />
  );
}
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={
        onlyNumbers(props.value).length > 11 ?
          (onlyNumbers(props.value).length > 12 ?
            ['+', /\d/, /\d/, ' ', '(', /[0-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] :
            ['+', /\d/, /\d/, ' ', '(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]
          ) :
          ['(', /[0-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,]}
      placeholderChar={'\u2000'}
      // showMask
      required
    />
  );
}


export default function Relatorionew(props) {
  const classes = useStyles();
  const [temArq, setTemarq] = React.useState(false);
  const [vetcursos, setVetcursos] = React.useState([]);
  const [vetpayments, setVetpayments] = React.useState([]);
  const [open, setOpen] = React.useState(true);
  const [userlist, setUserlist] = React.useState([]);
  const [dados, setDados] = React.useState({
    course_id: '',
    name: '',
    cpf: '',
    company: '',
    number: '',
    email: '',
    payment: '',
    note: '',
    clerk_id: '',
  });
  // alertas
  const [alertas, setAlertas] = React.useState({
    status: false,
    type: '',
    message: ""
  });
  const [load, setLoad] = React.useState(false);

  const [textdescription, setTextdescription] = React.useState({
    description: EditorState.createEmpty(),
  });
  // editor de texto 
  const onEditorStateChange = (editorState) => {
    setTextdescription({
      ...textdescription,
      description: editorState,
    });
    setDados({
      ...dados,
      description: draftToHtml(convertToRaw(textdescription.description.getCurrentContent())),
    })
  };
  // fim do editor 

  // referencia das mascaras 
  CpfMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };
  TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };


  // campo de data
  const handleDateChange = (date) => {
    setDados({ ...dados, start_date: date });
  };
  const handleDateFinalChange = (date) => {
    setDados({ ...dados, end_date: date });
  };

  // fim do campo de data

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    props.fecharDialogo();
  };


  const submeterNovo = e => {
    e.preventDefault();
    // console.log(dados);
    setLoad(true);
    const config = {
      headers: {
        "Authorization": "Bearer " + props.user.token,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true"
      }
    };

    API.post('private_subscriptions', dados, config)
      .then(a => {
        // window.location.reload();
        setLoad(false);
        props.fecharDialogo();
      }).catch(error => {
        console.log(error.response);
      });

  }

  const submeterAtualizacao = e => {
    e.preventDefault();
    // console.log(dados);
    setLoad(true);
    const config = {
      headers: {
        "Authorization": "Bearer " + props.user.token,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true"
      }
    };

    const dados2 = {
      course_id: dados.course_id,
      name: dados.name,
      cpf: dados.cpf,
      company: dados.company,
      number: dados.number,
      email: dados.email,
      payment: dados.payment,
      note: dados.note,
      status: props.info.status,
      clerk_id: dados.clerk_id
    }

    API.put(`private_subscriptions/${props.info.id}`, dados2, config)
      .then(a => {
        // window.location.reload();
        props.fecharDialogo();
      }).catch(error => {
        console.log(error.response);
      });

  }

  const atualizarListaCurso = e => {
    const config = {
      headers: {
        "Authorization": "Bearer " + props.user.token,
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true"
      }
    };

    API.get('events', config)
      .then(a => {

        setVetcursos(a.data);

      }).catch(error => {
        console.log(error.response);
      });
  }

  const listarusuarios = async e => {
    const config = {
      headers: {
        'Authorization': 'Bearer ' + props.user.token,
      }
    };

    await API.get(`users`, config)
      .then(a => {
        setUserlist(a.data);
        console.log(a.data);
      }).catch(error =>
        console.log("Erro>> ", error.response)
      );


  }

  React.useEffect(a => {
    if (props.info) {
      if (!dados.name) edicaoCampos();
    }
    if (userlist.length == 0) listarusuarios();
    if (vetcursos.length == 0) atualizarListaCurso();
  }, []);

  const edicaoCampos = e => {
    setDados({
      ...dados,
      course_id: props.info.course_id,
      course: props.info.course,
      name: props.info.name,
      cpf: props.info.cpf,
      company: props.info.company,
      number: props.info.number,
      email: props.info.email,
      payment: props.info.payment,
      note: props.info.note,
      status: props.info.status,
      clerk_id: props.info.clerk_id,
    });
  }


  var temp;
  return (
    <Conteudo>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" scroll={'body'}>
        <form onSubmit={props.info ? submeterAtualizacao : submeterNovo}>
          <DialogTitle id="form-dialog-title">{props.info ? 'Atualizar Inscrição' : 'Nova Inscrição'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os campos abaixo corretamente para que a inscrição seja {props.info ? 'atualizada' : 'inserida'}.
            </DialogContentText>
            <br />

            {/* responsavel */}
            {
              userlist.length > 0 &&

              <FormControl variant='outlined' className='' margin="dense" fullWidth >
                <InputLabel id="posicao" style={{ backgroundColor: '#fff', padding: '0rem 0.5rem' }}>Selecionar Responsável</InputLabel>
                <Select
                  labelId="curso"
                  id="curso"
                  value={dados.clerk_id}
                  defaultValue={props.info ? props.info.clerk_id : ''}
                  onChange={e => { setDados({ ...dados, clerk_id: e.target.value }) }}

                >
                  <MenuItem value={null}>
                    <em>Nenhum</em>
                  </MenuItem>
                  {userlist.map((e, j) => <MenuItem value={e.id}>{e.name}</MenuItem>)}
                </Select>
              </FormControl>
            }

            {/* fim responsavel  */}
            <p style={{ fontSize: "17.5px", color: "rgba(0,0,0,0.5)" }}>Informações Gerais: </p>
            <div className={classes.subAreas} style={{ padding: '0.5rem' }}>
              {props.info ?
                <TextField
                  value={dados.course}
                  onChange={e => setDados({ ...dados, course: e.target.value })}
                  margin="dense"
                  id="curso"
                  label="Curso"
                  type="text"
                  variant="outlined"
                  disabled={true}
                  required
                  fullWidth
                />
                :
                <FormControl variant='outlined' className='' margin="dense" fullWidth required>
                  <InputLabel id="posicao" style={{ backgroundColor: '#fff', padding: '0rem 0.5rem' }}>Selecionar Curso</InputLabel>
                  <Select
                    labelId="curso"
                    id="curso"
                    value={dados.course}
                    defaultValue={props.info ? dados.course : ''}
                    onChange={e => { setDados({ ...dados, course_id: e.target.value.id, course: `[${e.target.value.location}] ${format(new Date(e.target.value.start_date), "dd/MM/yyyy")} - ${e.target.value.title}` }); setVetpayments(e.target.value.payment ? e.target.value.payment : []) }}
                    required
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {vetcursos.map((e, j) => <MenuItem value={e}>[{e.location}] {format(new Date(e.start_date), "dd/MM/yyyy")} - {e.title}</MenuItem>)}
                  </Select>
                </FormControl>}

              <TextField
                value={dados.name}
                onChange={e => setDados({ ...dados, name: e.target.value })}
                margin="dense"
                id="nome-completo"
                label="Nome Completo"
                type="text"
                variant="outlined"
                required
                fullWidth
              />
              <TextField
                // required
                id="cpf"
                label="CPF (opcional)"
                //   defaultValue="Hello World"
                variant="outlined"
                value={dados.cpf}
                onChange={e => setDados({ ...dados, cpf: e.target.value })}
                InputProps={{
                  inputComponent: CpfMaskCustom,
                }}
                fullWidth
                margin="dense"
              />
              <TextField
                value={dados.company}
                onChange={e => setDados({ ...dados, company: e.target.value })}
                margin="dense"
                id="empresa"
                label="Empresa (opcional)"
                type="text"
                variant="outlined"
                fullWidth
              />
              <TextField
                required
                id="telefone"
                label="Telefone/Whatsapp"
                //   defaultValue="Hello World"
                variant="outlined"
                value={dados.number}
                onBlur={e => setDados({ ...dados, number: e.target.value })}
                onChange={e => setDados({ ...dados, number: e.target.value })}
                InputProps={{
                  inputComponent: TextMaskCustom,
                }}
                fullWidth
                margin="dense"
              />

              <TextField
                value={dados.email}
                onChange={e => setDados({ ...dados, email: e.target.value })}
                margin="dense"
                id="email"
                label="Email"
                type="email"
                variant="outlined"
                required
                fullWidth
              />
              {props.info ?
                <TextField
                  value={dados.payment}
                  onChange={e => setDados({ ...dados, payment: e.target.value })}
                  margin="dense"
                  id="forma-de-pagamento"
                  label="Forma de pagamento"
                  type="text"
                  variant="outlined"
                  // required
                  fullWidth
                />
                :
                <FormControl variant='outlined' className='' margin="dense" fullWidth required>
                  <InputLabel id="pagamento" style={{ backgroundColor: '#fff', padding: '0rem 0.5rem' }}>Forma de Pagamento</InputLabel>
                  <Select
                    labelId="pagamento"
                    id="pagamento"
                    value={dados.payment}
                    onChange={e => setDados({ ...dados, payment: e.target.value })}
                    disabled={vetpayments.length == 0 ? true : false}
                    required
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {vetpayments.map((e, j) => <MenuItem value={e}>{e}</MenuItem>)}
                  </Select>
                </FormControl>
              }

              <TextField
                margin="dense"
                id="observacao"
                label="Observação (opcional)"
                multiline={true}
                rows={4}
                variant='outlined'
                onChange={e => setDados({ ...dados, note: e.target.value })}
                value={dados.note}
                fullWidth
              />

            </div>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary" variant="contained">
              Cancelar
            </Button>
            <Button type="submit" color="primary" variant="contained">
              {props.info ? 'Atualizar' : 'Enviar'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {load ? <Backdrop /> : ''}
      {alertas.status ?
        <Snackbar open={true} autoHideDuration={6000} onClose={() => setAlertas({ ...alertas, status: false, type: '', message: '' })}>
          <Alert onClose={() => setAlertas({ ...alertas, status: false, type: '', message: '' })} severity={alertas.type}>
            {alertas.message}
          </Alert>
        </Snackbar> : ""}
    </Conteudo>
  );
}