import React, {useMemo} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';

//campos de texto
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import {Conteudo} from './styles';
import API from '../../services/api';
import {DropzoneArea} from 'material-ui-dropzone';

//campo de data
import {ptBR} from 'date-fns/locale';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// editor de texto
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//Seletor auto completável
import Backdrop from '../Backdrop';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// alertas
import { parseISO, isAfter } from 'date-fns';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  editText:{
    border:"1px solid rgba(0,0,0,0.1)",
    borderRadius:'3px',
    padding: '0.6rem',
    ['& .public-DraftEditor-content']:{
      minHeight:'200px',
    }
  },
  subAreas:{
    border: '2px dashed rgba(0,0,0,0.2)',
  },
}));


export default function Agendanew(props) {
  const classes = useStyles();
  const [temArq, setTemarq] = React.useState(false)
  const [open, setOpen] = React.useState(true);
  const [files, setFiles] = React.useState([]);
  const [fileementa, setFileementa] = React.useState();
  const [listementa, setListementa] = React.useState([]);
  const [dados, setDados] = React.useState({
      title: '',
      author: '',
      start_date: new Date(),
      descricao:'',
      description: '',
      file_name: '',
      file: fileementa,
      publish: true,
      contentBlocks:'',
  });
  // alertas
  const [alertas, setAlertas] = React.useState({
    status: false,
    type:'',
    message:""
  });
  const  [load, setLoad] = React.useState(false);

  const [textdescription, setTextdescription] = React.useState({
    description: EditorState.createEmpty(),
  });
  // editor de texto 
  const onEditorStateChange = (editorState) => {
    setTextdescription({
      ...textdescription,
      description: editorState,
    });
    setDados({
      ...dados,
      description: draftToHtml(convertToRaw(textdescription.description.getCurrentContent())),
    })
  };
  // fim do editor 

  // campo de data
  const handleDateChange = (date) => {
    setDados({...dados, start_date: date});
  };
  const handleDateFinalChange = (date) => {
    setDados({...dados, end_date: date});
  };

  // fim do campo de data

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    props.fecharDialogo();
  };

  const handleChange=e=>{
   setFiles(e);
  }

  const submeterNovo= e=>{
    e.preventDefault();
    // console.log(dados);
    setLoad(true);
      const config = {
        headers: {
          "Authorization": "Bearer " + props.user.token,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true"
        }
      };
      const formData = new FormData();
      formData.append('title', dados.title);
      formData.append('date', moment(dados.start_date).toISOString());
      formData.append('author', dados.author);
      // formData.append('description', dados.descricao);
      formData.append('body', dados.description);
      formData.append('image', files[0]);
      formData.append('publish', true);

      for (var pair of formData.entries()) {
          // console.log(pair[0]+ ', ' + pair[1]); 
      }

        if(files[0]){
          if(dados.start_date){
            if(dados.description){
              API.post('posts', formData, config)
              .then(a=>{
                // console.log(a);
                // window.location.reload();
                props.fecharDialogo();
                setLoad(false);
              }).catch(error=>{
                console.log(error.response);
              });
            }else{
              setAlertas({...alertas, status: true, type:"error", message:"O campo de conteúdo da postagem é obrigatório."});
              setLoad(false);
            }   
          }else{
            setAlertas({...alertas, status: true, type:"error", message:"O campo de data da postagem é obrigatório."});
            setLoad(false);
          }   
        }else{
          setAlertas({...alertas, status: true, type:"error", message:"Imagem não foi selecionada"});
          setLoad(false);
        }
      
  }

  const submeterAtualizacao= e=>{
    e.preventDefault();
    // console.log(dados);
    setLoad(true);
      const config = {
        headers: {
          "Authorization": "Bearer " + props.user.token,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true"
        }
      };
      const formData = new FormData();
      formData.append('title', dados.title);
      formData.append('date', moment(dados.start_date).toISOString());
      formData.append('author', dados.author);
      // formData.append('description', dados.descricao);
      formData.append('body', dados.description);
      formData.append('image', files[0]);
      formData.append('publish', true);

      for (var pair of formData.entries()) {
          // console.log(pair[0]+ ', ' + pair[1]); 
      }
          if(dados.description ){
              if(dados.start_date){
                API.put(`posts/${props.info.id}`, formData, config)
                .then(a=>{
                  // window.location.reload();
                  props.fecharDialogo();
                  setLoad(false);
                }).catch(error=>{
                  console.log(error.response);
                });
              }else{
                setAlertas({...alertas, status: true, type:"error", message:"O campo de data da postagem é obrigatório."});
                setLoad(false);
              }
          }else{
            setAlertas({...alertas, status: true, type:"error", message:"O campo de conteúdo da postagem é obrigatório."});
            setLoad(false);
          }   

  }

  const submeterRascunho = e=>{
    setLoad(true);
    const config = {
      headers: {
        "Authorization": "Bearer " + props.user.token,
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true"
      }
    };
      if(props.info){
        const formData = new FormData();
        formData.append('title', dados.title);
        formData.append('date', moment(dados.start_date).toISOString());
        formData.append('author', dados.author);
        // formData.append('description', dados.descricao);
        formData.append('body', dados.description);
        formData.append('image', files[0]);
        formData.append('publish', false);
  
        for (var pair of formData.entries()) {
            // console.log(pair[0]+ ', ' + pair[1]); 
        }
            if(dados.description ){
                if(dados.start_date){
                  API.put(`posts/${props.info.id}`, formData, config)
                  .then(a=>{
                    // window.location.reload();
                    props.fecharDialogo();
                    setLoad(false);
                  }).catch(error=>{
                    console.log(error.response);
                  });
                }else{
                  setAlertas({...alertas, status: true, type:"error", message:"O campo de data da postagem é obrigatório."});
                  setLoad(false);
                }
            }else{
              setAlertas({...alertas, status: true, type:"error", message:"O campo de conteúdo da postagem é obrigatório."});
              setLoad(false);
            }   
      }else{
        const formData = new FormData();
        formData.append('title', dados.title);
        formData.append('date', moment(dados.start_date).toISOString());
        formData.append('author', dados.author);
        // formData.append('description', dados.descricao);
        formData.append('body', dados.description);
        formData.append('image', files[0]);
        formData.append('publish', false);
  
        for (var pair of formData.entries()) {
            // console.log(pair[0]+ ', ' + pair[1]); 
        }
  
          if(files[0]){
            if(dados.start_date){
              if(dados.description){
                API.post('posts', formData, config)
                .then(a=>{
                  // console.log(a);
                  // window.location.reload();
                  props.fecharDialogo();
                  setLoad(false);
                }).catch(error=>{
                  console.log(error.response);
                });
              }else{
                setAlertas({...alertas, status: true, type:"error", message:"O campo de conteúdo da postagem é obrigatório."});
                setLoad(false);
              }   
            }else{
              setAlertas({...alertas, status: true, type:"error", message:"O campo de data da postagem é obrigatório."});
              setLoad(false);
            }   
          }else{
            setAlertas({...alertas, status: true, type:"error", message:"Imagem não foi selecionada"});
            setLoad(false);
          }
      }
  }
  const atualizarListaEmenta=e=>{
    const config = {
      headers: {
        "Authorization": "Bearer " + props.user.token,
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true"
      }
    };

    API.get('pdfs', config)
    .then(a=>{
      a.data.map((a,i)=>(
        listementa.push(a)
      ))
    }).catch(error=>{
      console.log(error.response);
    });

    // console.log(listementa);
  }

  React.useEffect(a=>{
    if(listementa.length==0) atualizarListaEmenta();
    if(props.info){
        if(!dados.title) edicaoCampos();
    }
  },[])

  const edicaoCampos =e=>{
   setDados({
     ...dados,
     title: props.info.title,
     start_date: parseISO(props.info.date),
     author:props.info.author,
    //  descricao: props.info.description,
     file_name: props.info.file_name,
     file: fileementa,
     payment: props.info.payment,
     description: props.info.body,
     contentBlocks: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.info.body))),
   });
  //  console.log(dados);
  }

  var aux;
  var contentBlocks;

  return (
    <Conteudo>
      <Dialog 
        open={open} 
        // onClose={handleClose} 
        aria-labelledby="form-dialog-title" 
        scroll={'body'} 
        maxWidth='lg'
      >
       <form onSubmit={props.info? submeterAtualizacao: submeterNovo}>
       <DialogTitle id="form-dialog-title">{props.info? 'Atualiza Postagem': 'Nova postagem'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
           Preencha os campos abaixo corretamente para que o novo evento seja inserido.
          </DialogContentText>
          <br/>
          <p style={{fontSize:"17.5px", color:"rgba(0,0,0,0.5)"}}>Informações Gerais: </p>
          <div className={classes.subAreas} style={{padding:'0.5rem'}}>
          <TextField
            value={dados.title}
            onChange={e=> setDados({...dados, title:e.target.value})}
            margin="dense"
            id="title"
            label="Título"
            type="text"
            required
            fullWidth
          />
           <TextField
            value={dados.author}
            onChange={e=> setDados({...dados, author:e.target.value})}
            margin="dense"
            id="title"
            label="Autor"
            type="text"
            required
            fullWidth
          />
           <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
           <Grid container justify="space-around">
           <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline-2"
              label="Data da publicação"
              fullWidth
              // className={classes.textJust}
              value={dados.start_date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              // disabled = {values.abrirDialogo.estado}
            />
            </Grid>
           </MuiPickersUtilsProvider>
          </div>
          <br/><br/>
          <p style={{fontSize:"17.5px", color:"rgba(0,0,0,0.5)"}}>Conteúdo: </p>
          <div className="editText" className={classes.editText}>
           <Editor
            defaultEditorState={props.info? dados.contentBlocks: textdescription.description}
            // editorState={textdescription.description}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={onEditorStateChange}
          />
          </div>
         
          <p style={{fontSize:"17.5px", color:"rgba(0,0,0,0.5)"}}>Seletor de imagem: </p>
          <DropzoneArea
                onChange={handleChange}
                acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                filesLimit={"1"}
                dropzoneText="Arraste a imagem para este campo ou clique aqui para buscar"
                maxFileSize="6000000"
                getFileAddedMessage={(fileName)=>`Arquivo ${fileName} adicionado com sucesso.`}
                getFileRemovedMessage={(fileName)=>`Arquivo ${fileName} removido.`}
                getFileLimitExceedMessage={(filesLimit)=>`Número máximo de ${filesLimit} arquivos excedido.`}
                getDropRejectMessage={()=>`Tipo de arquivo não permitido.`}
           />
           {props.info?
           <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
             <br/>
             <p style={{fontSize:"14.5px", color:"rgba(0,0,0,0.5)", margin:'auto'}}>Imagem atual</p>
             <img src={props.info.image_url} style={{width:'50%', margin: 'auto'}}/>
           </div>:""}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancelar
          </Button>
          <Button onClick={()=> submeterRascunho()}  variant="contained">
            Rascunho
          </Button>
          <Button type="submit" color="primary"  variant="contained">
             {props.editInfo?  (props.editInfo.publish? 'Aplicar Edição':'Publicar'):'Publicar'}
          </Button>
        </DialogActions>
       </form>
      </Dialog>
      {load? <Backdrop/>: ''}
      {alertas.status?
       <Snackbar open={true} autoHideDuration={6000} onClose={()=>setAlertas({...alertas, status:false, type:'', message:''})}>
       <Alert onClose={()=>setAlertas({...alertas, status:false, type:'', message:''})} severity={alertas.type}>
         {alertas.message}
       </Alert>
     </Snackbar>:""}
    </Conteudo>
  );
}