import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import xlsx from 'json-as-xlsx';
import { format } from 'date-fns';
import excelicon from '../../files/images/excel-icon.png';

import API from '../../services/api';
import Backdrop from '../Backdrop';

import Relatorioview from '../Relatorioview';

import { Button } from '@material-ui/core';

// dropdown
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import Badge from '@material-ui/core/Badge';

// editar relatorio
import Relatorionew from '../Relatorionew';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const rows = [
  createData('Cupcake', 305, 3.7),
  createData('Donut', 452, 25.0),
  createData('Eclair', 262, 16.0),
  createData('Frozen yoghurt', 159, 6.0),
  createData('Gingerbread', 356, 16.0),
  createData('Honeycomb', 408, 3.2),
  createData('Ice cream sandwich', 237, 9.0),
  createData('Jelly Bean', 375, 0.0),
  createData('KitKat', 518, 26.0),
  createData('Lollipop', 392, 0.2),
  createData('Marshmallow', 318, 0),
  createData('Nougat', 360, 19.0),
  createData('Oreo', 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

export default function CustomPaginationActionsTable(props) {
  const [newevent, setNewevent] = React.useState(false);

  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [showrelatorio, setShowrelatorio] = React.useState({
    status: false,
    dados: '',
  });
  const [editevent, setEditevent] = React.useState({
    status: false,
    dados: '',
  });

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /// modificadas para consumir api
  const [bdshow, setBdshow] = React.useState(false);
  const [lista, setLista] = React.useState([]);
  // const [lista2, setLista2] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [filtro, setFiltro] = React.useState({
    tipo: '',
    descricao: '',
  });

  const consultarApi = useCallback(async (e) => {
    setBdshow(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
      },
    };

    await API.get('subscriptions', config)
      .then((a) => {
        setLista(a.data);
        // console.log(a.data);
        setBdshow(false);
      })
      .catch((error) => {
        console.log(error.response);
      });

    setShow(true);
  });

  React.useEffect(() => {
    if (lista.length == 0) consultarApi();
  }, [consultarApi, lista.length]);

  const filtroAplicado = (e) => {
    if (filtro.tipo && filtro.descricao) {
      if (filtro.tipo == 'course') {
        let date = new Date(e.event.start_date).toLocaleDateString('pt-BR');
        let place = `[${e.event.location}]`;
        let title = e.event.title;
        // console.log(place, date, title);
        //console.log(e.course);
        let course = `${place} ${date} - ${title}`;

        if (course.toLowerCase().indexOf(filtro.descricao.toLowerCase()) > -1)
          return e;
      }
      if (filtro.tipo == 'name') {
        if (e.name.toLowerCase().indexOf(filtro.descricao.toLowerCase()) > -1)
          return e;
      }
      if (filtro.tipo == 'email') {
        if (e.email.toLowerCase().indexOf(filtro.descricao.toLowerCase()) > -1)
          return e;
      }
      if (filtro.tipo == 'company' && e.company) {
        if (
          e.company.toLowerCase().indexOf(filtro.descricao.toLowerCase()) > -1
        )
          return e;
      }
    } else {
      return true;
    }
  };
  const filtrodig = (e) => {
    setFiltro({ ...filtro, descricao: e.target.value });
  };

  var util = '';
  var lista2 = lista.filter(filtroAplicado);

  // filtros
  const ftEditar = (obj) => {
    if ('slug' in obj && obj.slug == 'edit_subs') {
      return true;
    } else {
      return false;
    }
  };
  const ftCriar = (obj) => {
    if ('slug' in obj && obj.slug == 'edit_subs') {
      return true;
    } else {
      return false;
    }
  };

  const colorSelector = (e) => {
    let identificador = '' + e.id;
    // console.log('Iden: ', identificador.slice(identificador.length-1,identificador.length));
    if (
      e.type == 'back' &&
      identificador.slice(identificador.length - 1, identificador.length) == 0
    )
      return '#FE6D73';
    if (
      e.type == 'back' &&
      identificador.slice(identificador.length - 1, identificador.length) == 1
    )
      return '#CDEC6E';
    if (
      e.type == 'back' &&
      identificador.slice(identificador.length - 1, identificador.length) == 2
    )
      return '#FE8C2F';
    if (
      e.type == 'back' &&
      identificador.slice(identificador.length - 1, identificador.length) == 3
    )
      return '#FE68C1';
    if (
      e.type == 'back' &&
      identificador.slice(identificador.length - 1, identificador.length) == 4
    )
      return '#93EBDE';
    if (
      e.type == 'back' &&
      identificador.slice(identificador.length - 1, identificador.length) == 5
    )
      return 'rgb(137, 96, 158)';
    if (
      e.type == 'back' &&
      identificador.slice(identificador.length - 1, identificador.length) == 6
    )
      return '#C9A9CF';
    if (
      e.type == 'back' &&
      identificador.slice(identificador.length - 1, identificador.length) == 7
    )
      return '#7DB8DD';
    if (
      e.type == 'back' &&
      identificador.slice(identificador.length - 1, identificador.length) == 8
    )
      return '#dbf048';
    if (
      e.type == 'back' &&
      identificador.slice(identificador.length - 1, identificador.length) == 9
    )
      return '#94C4E3';
    if (
      e.type == 'back' &&
      identificador.slice(identificador.length - 1, identificador.length) == 'a'
    )
      return '#FE6D73';
    if (
      e.type == 'back' &&
      identificador.slice(identificador.length - 1, identificador.length) == 'b'
    )
      return '#CDEC6E';
    if (
      e.type == 'back' &&
      identificador.slice(identificador.length - 1, identificador.length) == 'c'
    )
      return '#FE68C1';
    if (
      e.type == 'back' &&
      identificador.slice(identificador.length - 1, identificador.length) == 'd'
    )
      return '#FE8C2F';
    if (
      e.type == 'back' &&
      identificador.slice(identificador.length - 1, identificador.length) == 'e'
    )
      return '#93EBDE';
    if (
      e.type == 'back' &&
      identificador.slice(identificador.length - 1, identificador.length) == 'f'
    )
      return 'rgb(137, 96, 158)';

    if (
      e.type == 'letter' &&
      identificador.slice(identificador.length - 1, identificador.length) == 0
    )
      return '#0E131F';
    if (
      e.type == 'letter' &&
      identificador.slice(identificador.length - 1, identificador.length) == 1
    )
      return '#0E131F';
    if (
      e.type == 'letter' &&
      identificador.slice(identificador.length - 1, identificador.length) == 2
    )
      return '#0E131F';
    if (
      e.type == 'letter' &&
      identificador.slice(identificador.length - 1, identificador.length) == 3
    )
      return '#0E131F';
    if (
      e.type == 'letter' &&
      identificador.slice(identificador.length - 1, identificador.length) == 4
    )
      return '#0E131F';
    if (
      e.type == 'letter' &&
      identificador.slice(identificador.length - 1, identificador.length) == 5
    )
      return '#0E131F';
    if (
      e.type == 'letter' &&
      identificador.slice(identificador.length - 1, identificador.length) == 6
    )
      return '#0E131F';
    if (
      e.type == 'letter' &&
      identificador.slice(identificador.length - 1, identificador.length) == 7
    )
      return '#0E131F';
    if (
      e.type == 'letter' &&
      identificador.slice(identificador.length - 1, identificador.length) == 8
    )
      return '#0E131F';
    if (
      e.type == 'letter' &&
      identificador.slice(identificador.length - 1, identificador.length) == 9
    )
      return '#0E131F';
  };

  var convert = function () {
  var data=[
    {
      sheetName: 'sheet',
      columns:[
      {label: 'Data de Inscrição',
        value: (row) =>
          format(new Date(row.createdAt), 'dd/MM/yyyy') +
          ' às ' +
          format(new Date(row.createdAt), 'HH:mm'),
      }, // Top level data
      { label: 'Cód. Inscrição', value: (row) => row.code }, // Top level data
      { label: 'Curso/Evento', value: (row) => row.course }, // Top level data
      { label: 'Nome', value: (row) => row.name }, // Run functions
      { label: 'E-mail', value: (row) => (row.email ? row.email : '-') }, // Run functions
      { label: 'CPF', value: (row) => (row.cpf ? row.cpf : '-') }, // Run functions
      { label: 'Telefone', value: (row) => (row.number ? row.number : '-') }, // Run functions
      { label: 'Empresa', value: (row) => (row.company ? row.company : '-') }, // Run functions
      {
        label: 'Método de Pagamento',
        value: (row) => (row.payment_method ? row.payment_method : '-'),
      }
      ],

      content: lista2,
    }
  ]
    
  //  var columns = [
  //     {
  //       label: 'Data de Inscrição',
  //       value: (row) =>
  //         format(new Date(row.createdAt), 'dd/MM/yyyy') +
  //         ' às ' +
  //         format(new Date(row.createdAt), 'HH:mm'),
  //     }, // Top level data
  //     { label: 'Cód. Inscrição', value: (row) => row.code }, // Top level data
  //     { label: 'Curso/Evento', value: (row) => row.course }, // Top level data
  //     { label: 'Nome', value: (row) => row.name }, // Run functions
  //     { label: 'E-mail', value: (row) => (row.email ? row.email : '-') }, // Run functions
  //     { label: 'CPF', value: (row) => (row.cpf ? row.cpf : '-') }, // Run functions
  //     { label: 'Telefone', value: (row) => (row.number ? row.number : '-') }, // Run functions
  //     { label: 'Empresa', value: (row) => (row.company ? row.company : '-') }, // Run functions
  //     {
  //       label: 'Método de Pagamento',
  //       value: (row) => (row.payment_method ? row.payment_method : '-'),
  //     }, // Run functions

  //     // { label: 'Tipo', value: row => (row[2]) }, // Deep props
  //     // { label: 'Coordenadas', value: row => (row[3])},
  //     // { label: 'UTMY-N', value: row => (row[4])},
  //     // { label: 'UTMX-E', value: row => (row[5])}
  //   ];

  //   var content= lista2;
  //   var content = [
  //     { createdAt:"03/04/2022", code: 4456662475, more:
  //      {course: "Fibra", name: "Maria Angelica", email: "teste@agil.com", cpf:"0873457609", number:"88996339866", company: "Agil" }
  //     }
  //   ];
  // ]
    var settings = {
      // sheetName: 'sheet', // The name of the sheet
      fileName: `Relatório ${filtro.tipo ? filtro.tipo : 'Geral'}${
        filtro.descricao ? ' - ' + filtro.descricao : ''
      } gerado em ${
        format(new Date(), 'dd/MM/yyyy hh') + 'h' + format(new Date(), 'mm')
      }`, // The name of the spreadsheet
      extraLength: 2, // A bigger number means that columns should be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };
    var download = true; // If true will download the xlsx file, otherwise will return a buffer

    xlsx(data, settings);
    // xlsx(columns, content, settings, download);

  };

  React.useEffect(() => {
    console.log('Dados dos selecionados: ', lista2);
  }, [filtro.descricao, lista2]);

  return (
    <div>
      <br />
      {/* Novo evento  */}
      {props.user.roles.filter(ftCriar).length > 0 ? (
        <div style={{ textAlign: 'right' }}>
          <button
            onClick={() => convert()}
            style={{
              backgroundColor: 'rgb(0,83,81)',
              color: '#fff',
              border: '0rem',
              padding: '0.55rem 1.5rem',
              marginRight: '0.5rem',
              borderRadius: '0.4rem',
            }}
          >
            <img
              src={excelicon}
              style={{
                maxWidth: '1.5rem',
                marginRight: '0.2rem',
                marginBottom: '0.2rem',
              }}
            />{' '}
            Imprimir Relatório
          </button>
          <Button
            onClick={() => setNewevent(true)}
            variant="contained"
            color="secondary"
          >
            Nova Inscrição
          </Button>
        </div>
      ) : (
        ''
      )}
      <br />

      <div
        style={{
          display: 'flex',
          margin: '0.5rem',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'left',
          justifyItems: 'center',
        }}
      >
        <label style={{ fontWeight: 'bold', color: 'rgba(0,0,0,0.5)' }}>
          Filtro:{' '}
        </label>
        <FormControl
          variant="outlined"
          margin="dense"
          style={{ margin: '0rem 1rem', marginTop: '-0.5rem', width: '200px' }}
        >
          <InputLabel id="demo-simple-select-outlined-label-tipo">
            {'Tipo'}
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label-tipo"
            id="demo-simple-select-outlined-tipo"
            value={filtro.tipo}
            onChange={(e) =>
              setFiltro({ ...filtro, tipo: e.target.value, descricao: '' })
            }
            label="Tipo"
            // style={{padding:'0rem 1rem'}}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={'course'}>Curso</MenuItem>
            <MenuItem value={'email'}>Email</MenuItem>
            <MenuItem value={'company'}>Empresa</MenuItem>
            <MenuItem value={'name'}>Nome</MenuItem>
          </Select>
        </FormControl>

        {filtro.tipo == 'course' ? (
          <FormControl
            variant="outlined"
            margin="dense"
            style={{
              margin: '0rem 0rem',
              marginTop: '-0.5rem',
              width: '250px',
            }}
          >
            <InputLabel id="demo-simple-select-outlined-label-tipo">
              {'Curso'}
            </InputLabel>
            <Select
              labelId="demo-curso-select-outlined-label-tipo"
              id="demo-simple-curso-outlined-tipo"
              value={filtro.descricao}
              onChange={(e) => {
                setFiltro({ ...filtro, descricao: e.target.value });
                // console.log(lista2);
              }}
              label="Curso"
              // style={{padding:'0rem 1rem'}}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {/* {filtro.tipo
                ? lista.map((a, i) =>
                    console.log('ID: ', a.course_id, ' Curso: ', a.course)
                  )
                : ''} */}

              {filtro.tipo
                ? lista
                    .filter(function (a) {
                      return (
                        !this[JSON.stringify(a.course_id)] &&
                        (this[JSON.stringify(a.course_id)] = true)
                      );
                    }, Object.create(null))
                    .map((e, j) =>
                      util ==
                      `[${e.event.location}] ${new Date(
                        e.event.start_date
                      ).toLocaleDateString('pt-BR')} - ${e.event.title}` ? (
                        ''
                      ) : (
                        <MenuItem
                          value={`[${e.event.location}] ${new Date(
                            e.event.start_date
                          ).toLocaleDateString('pt-BR')} - ${e.event.title}`}
                        >
                          {
                            (util = `[${e.event.location}] ${new Date(
                              e.event.start_date
                            ).toLocaleDateString('pt-BR')} - ${e.event.title}`)
                          }
                        </MenuItem>
                      )
                    )
                : ''}
            </Select>
          </FormControl>
        ) : (
          <TextField
            onKeyUp={() => setPage(0)}
            onChange={filtrodig}
            value={filtro.descricao}
            id="outlined-basic"
            label={
              filtro.tipo
                ? filtro.tipo == 'email'
                  ? 'Email'
                  : filtro.tipo == 'name'
                  ? 'Nome'
                  : filtro.tipo == 'company'
                  ? 'Empresa'
                  : ''
                : 'Selecione o filtro'
            }
            disabled={filtro.tipo ? false : true}
            variant="outlined"
            margin="dense"
            style={{
              margin: '0rem 0rem',
              marginTop: '-0.5rem',
              width: '250px',
            }}
          />
        )}
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell align="rleft">
                <b>Inscrito</b>
              </TableCell>
              <TableCell align="right">
                <b>Curso</b>
              </TableCell>
              <TableCell width={100} align="right">
                <b>Opções</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage >= 0
              ? lista2.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : lista2
            ).map((row) => (
              <TableRow
                key={row.id}
                style={{
                  borderLeft:
                    row.status == 0
                      ? '5px solid #ffee00'
                      : row.status == 1
                      ? '8px solid #52d869'
                      : '5px solid rgba(255,25,60)',
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {row.name}
                  {row.clerk_id && (
                    <div
                      style={{
                        backgroundColor: colorSelector({
                          id: row.clerk_id,
                          type: 'back',
                        }),
                        color: colorSelector({
                          id: row.clerk_id,
                          type: 'letter',
                        }),
                        fontSize: '11px',
                        padding: '0.2rem 0.5rem',
                        borderRadius: '10px',
                        opacity: '0.8',
                        marginLeft: '0.4rem',
                      }}
                    >
                      {row.clerk.name}
                    </div>
                  )}
                </TableCell>
                <TableCell align="right">
                  {`[${row.event.location}] ${new Date(
                    row.event.start_date
                  ).toLocaleDateString('pt-BR')} `}
                </TableCell>

                <TableCell
                  width={100}
                  style={{ padding: '0rem', margin: '0rem' }}
                  align="left"
                >
                  <IconButton
                    onClick={() =>
                      setShowrelatorio({ status: true, dados: row })
                    }
                  >
                    <Badge
                      badgeContent={row.comments.length}
                      color={'secondary'}
                    >
                      <VisibilityIcon />
                    </Badge>
                  </IconButton>
                  {props.user.roles.filter(ftEditar).length > 0 ? (
                    <IconButton
                      onClick={() => setEditevent({ status: true, dados: row })}
                    >
                      <EditIcon />
                    </IconButton>
                  ) : (
                    ''
                  )}
                </TableCell>

                {/* <TableCell align="right">{row.course}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={lista2.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Colunas por página' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
        {bdshow ? <Backdrop /> : ''}
        {editevent.status ? (
          <Relatorionew
            user={props.user}
            fecharDialogo={() => {
              setEditevent({ ...editevent, dados: '', status: false });
              consultarApi();
            }}
            info={editevent.dados}
          />
        ) : (
          ''
        )}
        {showrelatorio.status ? (
          <Relatorioview
            user={props.user}
            fecharDialogo={() => {
              setShowrelatorio({ ...editevent, dados: '', status: false });
              consultarApi();
            }}
            info={showrelatorio.dados}
          />
        ) : (
          ''
        )}
      </TableContainer>
      <div
        style={{ textAlign: 'left', padding: '1rem', color: 'rgba(0,0,0,0.5)' }}
      >
        <b>Legenda:</b>
        <br />
        <div style={{ padding: '0rem 0.5rem', fontSize: '12px' }}>
          <FiberManualRecordIcon
            style={{ color: '#52d869', width: '18px', marginTop: '-0.1rem' }}
          />{' '}
          - Pagamento confirmado <br />
          <FiberManualRecordIcon
            style={{ color: '#ffed00', width: '18px', marginTop: '-0.1rem' }}
          />{' '}
          - Pagamento não confirmado <br />
          <FiberManualRecordIcon
            style={{
              color: 'rgba(255,25,60)',
              width: '18px',
              marginTop: '-0.1rem',
            }}
          />{' '}
          - Inscrição Cancelada
        </div>
      </div>
      {/* Diálogo de adicionar novo  */}
      {newevent ? (
        <Relatorionew
          user={props.user}
          fecharDialogo={() => {
            setNewevent(false);
            consultarApi();
          }}
        />
      ) : (
        ''
      )}
    </div>
  );
}
