import React, {useMemo} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';

//campos de texto
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import {Conteudo} from './styles';
import API from '../../services/api';
import {DropzoneArea} from 'material-ui-dropzone';

//campo de data
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// editor de texto
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//Seletor auto completável
import Backdrop from '../Backdrop';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


// chips
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';


// alertas
import { parseISO, isAfter } from 'date-fns';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  editText:{
    border:"1px solid rgba(0,0,0,0.1)",
    borderRadius:'3px',
    padding: '0.6rem',

  },
  subAreas:{
    border: '2px dashed rgba(0,0,0,0.2)',
  },
  chip: {
    margin: theme.spacing(0.5),
    padding: '0rem 0rem',
    // backgroundColor:'rgba(255,255,100,0.5)',
    
  },
  root2: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    boxShadow:'0px 0px 0px rgba(0,0,0,0)',
    border:0,
  },
}));



export default function Configuracoesnew(props) {
  const classes = useStyles();
  const [temArq, setTemarq] = React.useState(false)
  const [open, setOpen] = React.useState(true);
  const [files, setFiles] = React.useState([]);
  const [fileementa, setFileementa] = React.useState();
  const [listementa, setListementa] = React.useState([]);
  const [dados, setDados] = React.useState({
     name:'',
     email:'',
     id:'',
     roles:[]
  });
  // alertas
  const [alertas, setAlertas] = React.useState({
    status: false,
    type:'',
    message:""
  });
  const  [load, setLoad] = React.useState(false);

  const [textdescription, setTextdescription] = React.useState({
    description: EditorState.createEmpty(),
  });
  // editor de texto 
  const onEditorStateChange = (editorState) => {
    setTextdescription({
      ...textdescription,
      description: editorState,
    });
    setDados({
      ...dados,
      description: draftToHtml(convertToRaw(textdescription.description.getCurrentContent())),
    })
  };
  // fim do editor 

  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    props.fecharDialogo();
  };

  const handleChange=e=>{
   setFiles(e);
  }

  const submeterNovo= e=>{
    e.preventDefault();
    // console.log(dados);
    setLoad(true);
    const config = {
      headers: {
        'Authorization': 'Bearer ' + props.user.token,
      }
    };
      var subdados={
        id: dados.id,
        name: dados.name,
        email: dados.email,
        role: dados.roles.id,
        password: dados.password,
        password_confirmation: dados.password_confirmation,
      };
      console.log(subdados);

      if(dados.password.length>7){
        if(dados.password.length==dados.password_confirmation.length){
          if(subdados.role){
              API.post(`users`, subdados, config)
              .then(a=>{
                // window.location.reload();
                props.fecharDialogo();
                setLoad(false);
              }).catch(error=>{
                setAlertas({...alertas, status:true, type:'error', message:error.response.data.error});
                setLoad(false);
                console.log(error.response);
              });     
          }else{
            setAlertas({...alertas, status:true, type:'error', message:'Função não atribuída.'});
            setLoad(false);
          }
        }else{
         setAlertas({...alertas, status:true, type:'error', message:'As senhas não correspondem.'});
         setLoad(false);
        }
      }else{
        setAlertas({...alertas, status:true, type:'error', message:'A senha deve conter no mínimo 8 caracters.'});
        setLoad(false);
      } 

    
  }

  const submeterAtualizacao= e=>{
    e.preventDefault();
    // console.log(dados);
    setLoad(true);
    const config = {
      headers: {
        'Authorization': 'Bearer ' + props.user.token,
      }
    };
      var subdados={
        id: dados.id,
        name: dados.name,
        email: dados.email,
        role: dados.roles.id
      };
      
      if(subdados.role){
        API.put(`users/${props.info.id}`, subdados, config)
        .then(a=>{
          // window.location.reload();
          props.fecharDialogo();
          setLoad(false);
        }).catch(error=>{
          console.log(error.response);
        }); 
      }else{
        setAlertas({...alertas, status:true, type:'error', message:'Função não atribuída.'});
        setLoad(false);
      }    

  }

  const atualizarListaEmenta=e=>{
    const config = {
      headers: {
        "Authorization": "Bearer " + props.user.token,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true"
      }
    };

    API.get('roles', config)
    .then(a=>{
      a.data.map((e,i)=>{
        if(e.permission.length>0) listementa.push(e);
        // console.log(e)
      })
    }).catch(error=>{
      console.log(error.response);
    });

    // console.log(listementa);
  }

  React.useEffect(a=>{
    if(listementa.length==0) atualizarListaEmenta();
    if(props.info){
        if(!dados.name) edicaoCampos();
    }
  },[])

  const edicaoCampos =e=>{
   setDados({
     ...dados,
     name: props.info.name,
     email: props.info.email,
     id: props.info.id,
     roles: props.info.role[0]   
   });
  }


  var aux;
  var contentBlocks;

  return (
    <Conteudo>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" scroll={'body'}>
       <form onSubmit={props.info? submeterAtualizacao: submeterNovo}>
       <DialogTitle id="form-dialog-title">{props.info? 'Atualizar Conta':'Nova Conta'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
           Preencha os campos abaixo corretamente para que a conta seja {props.info?'atualizado':'inserido'}.
          </DialogContentText>
          <br/>
          <div className={classes.subAreas} style={{padding:'0.5rem'}}>
          <TextField
            value={dados.name}
            onChange={e=> setDados({...dados, name:e.target.value})}
            margin="dense"
            id="name"
            label="Nome"
            type="text"
            required
            fullWidth
          />
          
          <TextField
            value={dados.email}
            onChange={e=> setDados({...dados,email:e.target.value})}
            margin="dense"
            id="email"
            label="Email"
            type="email"
            required
            fullWidth
          />

        {props.info?'':
        <div>
            <TextField
            value={dados.password}
            onChange={e=> setDados({...dados, password:e.target.value})}
            margin="dense"
            id="name"
            label="Senha"
            type="password"
            required
            fullWidth
          />
          
          <TextField
            value={dados.password_confirmation}
            onChange={e=> setDados({...dados,password_confirmation:e.target.value})}
            margin="dense"
            id="email"
            label="Confirmação de Senha"
            type="password"
            required
            fullWidth
          />  
        </div>}

          <br/>
          
        <Autocomplete
          id="combo-box-demo"
          // defaultValue={props.info? props.info.role[0].name:''}
          value={dados.roles}
          onChange={(event, newValue) => {
            if(newValue){
              setDados({...dados, roles: newValue});
            }else{ setDados({...dados, roles:''});}
            // console.log(newValue);
          }}
          disabled={props.info.id==1? true:false}
          options={listementa}
          getOptionLabel={(option) => option.name}
          style={{flex:'1', margin:'0rem 0.4rem'}}
          renderInput={(params) => <TextField {...params} disabled={props.info.id==1? true:false} label="Selecionar função existente"/>}
        />
        <br/>
        {/* chips */}
        {dados.roles.permission? <p style={{fontSize:"17px", color:"rgba(0,0,0,0.5)"}}>Permissões: </p> : ''}
         <Paper component="ul" className={classes.root2}>
              {dados.roles.permission? dados.roles.permission.map((data) => {
                let icon;
                if (data.label === 'xxxxx') {
                icon = <TagFacesIcon />;
                }
                return (
                    <li key={data.key}>
                      <Chip
                        icon={icon}
                        label={data.name}
                        // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                        className={classes.chip}
                      />
                    </li>
                  );
                }):''}

            </Paper>
       
          </div>
    
         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary"  variant="contained">
            Cancelar
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {props.info?'Atualizar': 'Cadastrar'}
          </Button>
        </DialogActions>
       </form>
      </Dialog>
      {load? <Backdrop/>: ''}
      {alertas.status?
       <Snackbar open={true} autoHideDuration={6000} onClose={()=>setAlertas({...alertas, status:false, type:'', message:''})}>
       <Alert onClose={()=>setAlertas({...alertas, status:false, type:'', message:''})} severity={alertas.type}>
         {alertas.message}
       </Alert>
     </Snackbar>:""}
    </Conteudo>
  );
}