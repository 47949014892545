import React, { useMemo } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';

import Switch from '@material-ui/core/Switch';

//campos de texto
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

// icones
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Conteudo } from './styles';
import API from '../../services/api';
import { DropzoneArea } from 'material-ui-dropzone';
import { ptBR } from 'date-fns/locale';

//campo de data
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// editor de texto
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//Seletor auto completável
import Backdrop from '../Backdrop';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// alertas
import { parseISO, isAfter } from 'date-fns';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  editText: {
    border: '1px solid rgba(0,0,0,0.1)',
    borderRadius: '3px',
    padding: '0.6rem',
  },
  subAreas: {
    border: '2px dashed rgba(0,0,0,0.2)',
  },
  movimentar: {
    cursor: 'pointer',
    '&:hover': {
      color: 'rgba(0,81,83,1)',
      boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
    },
  },
}));



export default function Agendanew(props) {
  const classes = useStyles();
  const [temArq, setTemarq] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [files, setFiles] = React.useState([]);
  const [fileementa, setFileementa] = React.useState();
  const [listementa, setListementa] = React.useState([]);
  const [oneDay, setoneDay] = React.useState(false);
  const [dados, setDados] = React.useState({
    title: '',
    start_date: new Date(),
    end_date: new Date(),
    duration: '',
    location: '',
    hoster: '',
    description: '',
    file_name: '',
    file: fileementa,
    subscription_link: '',
    payment: [''],
    contentBlocks: '',
    publish: true,
    is_active: false,
    type: 1,
    eventType: '',
  });
  // alertas
  const [alertas, setAlertas] = React.useState({
    status: false,
    type: '',
    message: '',
  });
  const [load, setLoad] = React.useState(false);
  const [textdescription, setTextdescription] = React.useState({
    description: EditorState.createEmpty(),
  });

  // imagem ṕatrocinador
  const [imgSponsor, setImgSponsor] = React.useState(
    props.info &&
      Object.keys(props.info).includes('sponsor_image_url') &&
      props.info.sponsor_image_url !== null
      ? [props.info.sponsor_image_url]
      : [null]
  );

  if (undefined === imgSponsor[0]) {
    if (undefined !== props.info) {
      Object.keys(props.info).includes('sponsor_image_url') &&
        setImgSponsor([props.info.sponsor_image_url]);
    }
  }

  // console.log(imgSponsor[0], 'TESTE IMAGEM FRONT');
  // console.log(props.info.sponsor_image_url, 'TESTE IMAGEM BACK');

  // editor de texto
  const onEditorStateChange = (editorState) => {
    setTextdescription({
      ...textdescription,
      description: editorState,
    });
    setDados({
      ...dados,
      description: draftToHtml(
        convertToRaw(textdescription.description.getCurrentContent())
      ),
    });
  };
  // fim do editor

  // campo de data
  const handleDateChange = (date) => {
    setDados({ ...dados, start_date: date });
  };
  const handleDateFinalChange = (date) => {
    setDados({ ...dados, end_date: date });
  };

  // fim do campo de data

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    props.fecharDialogo();
  };

  const handleChange = (e) => {
    setFiles(e);
  };

  const handleSponsor = (e) => {
    setImgSponsor(e);
  };

  const submeterNovo = (e) => {
    e.preventDefault();
    // console.log(dados);
    setLoad(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'Access-Control-Allow-Credentials': 'true',
      },
    };
    const formData = new FormData();
    formData.append('title', dados.title);
    formData.append('start_date', moment(dados.start_date).toISOString());
    formData.append(
      'end_date',
      moment(oneDay ? dados.start_date : dados.end_date).toISOString()
    );
    formData.append('duration', dados.duration);
    formData.append('location', dados.location);
    dados.type == 1 && formData.append('hoster', dados.hoster);
    formData.append('description', dados.description);
    formData.append('publish', true);
    // formData.append('type', dados.type);
    dados.type == 0 &&
      formData.append('subscription_link', dados.subscription_link);
    formData.append('is_active', dados.is_active);
    dados.type == 1 &&
      dados.payment.map((a, i) => formData.append('payment[]', a));
    dados.type == 1 && formData.append('file_name', dados.file_name);
    if (fileementa) formData.append('file', fileementa[0]);
    formData.append('image', files[0]);
    formData.append('sponsor', imgSponsor[0]);
    formData.append('type', dados.eventType);


    for (var pair of formData.entries()) {
      // console.log(pair[0]+ ', ' + pair[1]);
    }

    if (
      dados.type === 1 && !oneDay
        ? isAfter(dados.end_date, dados.start_date)
        : true
    ) {
      if (files[0]) {
        if (dados.description) {
          API.post(
            dados.type == 1 ? 'events' : 'general_events',
            formData,
            config
          )
            .then((a) => {
              // window.location.reload();
              setLoad(false);
              props.fecharDialogo();
            })
            .catch((error) => {
              console.log(error.response);
            });
        } else {
          setAlertas({
            ...alertas,
            status: true,
            type: 'error',
            message: 'O campo de descrição é obrigatório.',
          });
          setLoad(false);
        }
      } else {
        setAlertas({
          ...alertas,
          status: true,
          type: 'error',
          message: 'Imagem não foi selecionada',
        });
        setLoad(false);
      }
    } else {
      setAlertas({
        ...alertas,
        status: true,
        type: 'error',
        message: 'Intervalo de datas inválido',
      });
      setLoad(false);
    }
  };

  const submeterAtualizacao = (e) => {
    e.preventDefault();
    // console.log(dados);
    setLoad(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'Access-Control-Allow-Credentials': 'true',
      },
    };
    const formData = new FormData();
    formData.append('title', dados.title);
    formData.append('start_date', moment(dados.start_date).toISOString());
    formData.append(
      'end_date',
      moment(oneDay ? dados.start_date : dados.end_date).toISOString()
    );
    formData.append('duration', dados.duration);
    formData.append('location', dados.location);
    dados.type == 1 && formData.append('hoster', dados.hoster);
    // formData.append('type', dados.type);
    dados.type == 0 &&
      formData.append('subscription_link', dados.subscription_link);
    formData.append('description', dados.description);
    formData.append('publish', true);
    formData.append('is_active', props.info.publish ? dados.is_active : false);
    dados.type == 1 &&
      dados.payment.map((a, i) => formData.append('payment[]', a));
    dados.type == 1 && formData.append('file_name', dados.file_name);
    if (fileementa) formData.append('file', fileementa[0]);
    formData.append('image', files[0]);
    dados.type == 1 && formData.append('sponsor', imgSponsor[0]);
    formData.append('type', dados.eventType);

    for (var pair of formData.entries()) {
      // console.log(pair[0]+ ', ' + pair[1]);
    }

    if (
      dados.type === 1 && !oneDay
        ? isAfter(dados.end_date, dados.start_date)
        : true
    ) {
      if (dados.description) {
        API.put(
          dados.type == 1
            ? `events/${props.info.id}`
            : `general_events/${props.info.id}`,
          formData,
          config
        )
          .then((a) => {
            // window.location.reload();
            setLoad(false);
            props.fecharDialogo();
          })
          .catch((error) => {
            console.log(error.response);
          });

        if (imgSponsor[0] === null && null !== props.info.sponsor_image_url) {
          API.put(`/remove_event_sponsor/${props.info.id}`, {}, config)
            .then((response) => {
              // console.log(response);
              setImgSponsor([null]);
              props.fecharDialogo();
            })
            .catch((error) => {
              console.log(error.response);
            });
        }
      } else {
        setAlertas({
          ...alertas,
          status: true,
          type: 'error',
          message: 'O campo de descrição é obrigatório.',
        });
        setLoad(false);
      }
    } else {
      setAlertas({
        ...alertas,
        status: true,
        type: 'error',
        message: 'Intervalo de datas inválido',
      });
      setLoad(false);
    }
  };

  const submeterRascunho = (e) => {
    // console.log(dados);
    setLoad(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'Access-Control-Allow-Credentials': 'true',
      },
    };
    if (props.info) {
      const formData = new FormData();
      formData.append('title', dados.title);
      formData.append('start_date', moment(dados.start_date).toISOString());
      formData.append(
        'end_date',
        moment(oneDay ? dados.start_date : dados.end_date).toISOString()
      );
      formData.append('duration', dados.duration);
      formData.append('location', dados.location);
      // formData.append('type', dados.type);
      dados.type == 0 &&
        formData.append('subscription_link', dados.subscription_link);
      dados.type == 1 && formData.append('hoster', dados.hoster);
      formData.append('is_active', dados.is_active);
      formData.append('description', dados.description);
      formData.append('publish', false);
      dados.type == 1 &&
        dados.payment.map((a, i) => formData.append('payment[]', a));
      dados.type == 1 && formData.append('file_name', dados.file_name);
      if (fileementa) formData.append('file', fileementa[0]);
      formData.append('image', files[0]);
      formData.append('sponsor', imgSponsor[0]);
      formData.append('type', dados.eventType);

      for (var pair of formData.entries()) {
        // console.log(pair[0]+ ', ' + pair[1]);
      }

      if (
        dados.type === 1 && !oneDay
          ? isAfter(dados.end_date, dados.start_date)
          : true
      ) {
        if (dados.description) {
          API.put(
            dados.type == 1
              ? `events/${props.info.id}`
              : `general_events/${props.info.id}`,
            formData,
            config
          )
            .then((a) => {
              // window.location.reload();
              setLoad(false);
              props.fecharDialogo();
            })
            .catch((error) => {
              console.log(error.response);
            });
        } else {
          setAlertas({
            ...alertas,
            status: true,
            type: 'error',
            message: 'O campo de descrição é obrigatório.',
          });
          setLoad(false);
        }
      } else {
        setAlertas({
          ...alertas,
          status: true,
          type: 'error',
          message: 'Intervalo de datas inválido',
        });
        setLoad(false);
      }
    } else {
      const formData = new FormData();
      formData.append('title', dados.title);
      formData.append('start_date', moment(dados.start_date).toISOString());
      formData.append(
        'end_date',
        moment(oneDay ? dados.start_date : dados.end_date).toISOString()
      );
      formData.append('duration', dados.duration);
      formData.append('location', dados.location);
      // formData.append('type', dados.type);
      dados.type == 0 &&
        formData.append('subscription_link', dados.subscription_link);
      formData.append('is_active', dados.is_active);
      dados.type == 1 && formData.append('hoster', dados.hoster);
      formData.append('description', dados.description);
      formData.append('publish', false);
      dados.type == 1 &&
        dados.payment.map((a, i) => formData.append('payment[]', a));
      dados.type == 1 && formData.append('file_name', dados.file_name);
      if (fileementa) formData.append('file', fileementa[0]);
      formData.append('image', files[0]);
      formData.append('sponsor', imgSponsor[0]);
      formData.append('type', dados.eventType);

      for (var pair of formData.entries()) {
        // console.log(pair[0]+ ', ' + pair[1]);
      }

      if (
        dados.type === 1 && !oneDay
          ? isAfter(dados.end_date, dados.start_date)
          : true
      ) {
        if (files[0]) {
          if (dados.description) {
            API.post(
              dados.type == 1 ? 'events' : 'general_events',
              formData,
              config
            )
              .then((a) => {
                // window.location.reload();
                setLoad(false);
                props.fecharDialogo();
              })
              .catch((error) => {
                console.log(error.response);
              });
          } else {
            setAlertas({
              ...alertas,
              status: true,
              type: 'error',
              message: 'O campo de descrição é obrigatório.',
            });
            setLoad(false);
          }
        } else {
          setAlertas({
            ...alertas,
            status: true,
            type: 'error',
            message: 'Imagem não foi selecionada',
          });
          setLoad(false);
        }
      } else {
        setAlertas({
          ...alertas,
          status: true,
          type: 'error',
          message: 'Intervalo de datas inválido',
        });
        setLoad(false);
      }
    }
  };

  const atualizarListaEmenta = (e) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'Access-Control-Allow-Credentials': 'true',
      },
    };

    API.get('pdfs', config)
      .then((a) => {
        a.data.map((a, i) => listementa.push(a));
      })
      .catch((error) => {
        console.log(error.response);
      });

    // console.log(listementa);
  };

  const edicaoCampos = (e) => {
    setoneDay(props.info.start_date == props.info.end_date ? true : false);
    setDados({
      ...dados,
      title: props.info.title,
      start_date: parseISO(
        props.info.start_date ? props.info.start_date : new Date()
      ),
      end_date: parseISO(
        props.info.end_date ? props.info.end_date : new Date()
      ),
      duration: props.info.duration,
      location: props.info.location,
      hoster: props.info.hoster ? props.info.hoster : '',
      description: props.info.description,
      file_name: props.info.file_name,
      file: fileementa ? fileementa : '',
      payment: props.info.payment ? props.info.payment : [],
      is_active: props.info.is_active ? true : false,
      subscription_link: props.info.subscription_link
        ? props.info.subscription_link
        : '',
      type: props.info.type,
      contentBlocks: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(props.info.description)
        )
      ),
    });
    //  console.log(dados);
  };

  React.useEffect(
    (a) => {
      if (listementa.length == 0) atualizarListaEmenta();
      if (props.info) {
        if (!dados.title) edicaoCampos();
      }
    },
    [
      atualizarListaEmenta,
      dados.title,
      edicaoCampos,
      listementa.length,
      props.info,
    ]
  );

  const removerPatrocinador = (id) => {
    setImgSponsor([null]);
  };

  const restaurarPatrocinador = () => {
    if (props.info) {
      setImgSponsor([props.info.sponsor_image_url]);
    }
  };

  //mudar posicao
  function changePosition(arr, from, to) {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    setDados({ ...dados, payment: arr });
    return arr;
  }

  var aux;
  var contentBlocks;

  return (
    <Conteudo>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        scroll={'body'}
      >
        <form onSubmit={props.info ? submeterAtualizacao : submeterNovo}>
          <DialogTitle id="form-dialog-title">
            {props.info ? 'Atualizar Evento' : 'Novo Evento'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha os campos abaixo corretamente para que o evento seja{' '}
              {props.info ? 'atualizado' : 'inserido'}.
            </DialogContentText>
            <br />
            {props.info && props.info.publish ? (
              <div
                style={{
                  margin: '0.5rem auto',
                  marginTop: '-1rem',
                  textAlign: 'center',
                }}
              >
                <label style={{ opacity: '70%' }}>Encerrar evento:</label>
                <Switch
                  disabled={!isAfter(dados.end_date, new Date())}
                  checked={dados.is_active}
                  onChange={(e) =>
                    setDados({ ...dados, is_active: !dados.is_active })
                  }
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </div>
            ) : (
              ''
            )}
            <FormControl variant="outlined" required fullWidth margin="dense">
              <InputLabel id="demo-simple-select-outlined-label">
                Categoria de evento
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={dados.type}
                onChange={(e) => setDados({ ...dados, type: e.target.value })}
                // className={classes.campoMenor}
                label="Categoria de evento"
                disabled={props.info ? true : false}
              >
                <MenuItem value={1}>Treinamento ou Formulario de inscricao </MenuItem>
                <MenuItem value={0}>Evento geral (Inscrição externa)</MenuItem>
              </Select>
            </FormControl>

            <p style={{ fontSize: '17.5px', color: 'rgba(0,0,0,0.5)' }}>
              Informações Gerais:{' '}
            </p>
            <div className={classes.subAreas} style={{ padding: '0.5rem' }}>
              <FormControl variant="outlined" required fullWidth margin="dense">
                <InputLabel id="demo-simple-select-outlined-label">
                  Tipo de evento
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={dados.eventType}
                  onChange={(e) => setDados({ ...dados, eventType: e.target.value })}
                  // className={classes.campoMenor}
                  label="Tipo de evento"
                >
                  <MenuItem value={'treinamento'}>Treinamento</MenuItem>
                  <MenuItem value={'formulario de inscricao'}>Formulario de inscricao</MenuItem>
                </Select>
              </FormControl>
              <TextField
                value={dados.title}
                onChange={(e) => setDados({ ...dados, title: e.target.value })}
                margin="dense"
                id="title"
                label="Título"
                type="text"
                required
                fullWidth
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <Grid container justify="space-around">
                  <KeyboardDateTimePicker
                    // disableToolbar
                    variant="inline"
                    ampm={false}
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline-2"
                    label="Data inicial"
                    // className={classes.textJust}
                    value={dados.start_date}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    fullWidth={dados.type == 0 ? true : false}
                    // disabled = {values.abrirDialogo.estado}
                  />
                  {dados.type == 1 && (
                    <KeyboardDateTimePicker
                      // disableToolbar
                      variant="inline"
                      ampm={false}
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline-1"
                      label="Data final"
                      value={oneDay ? dados.start_date : dados.end_date}
                      onChange={handleDateFinalChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disabled={oneDay}
                    />
                  )}
                </Grid>
              </MuiPickersUtilsProvider>
              <div
                className="div__select_two_days"
                style={{
                  display: 'flex',
                  // justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0.2rem 0.4rem',
                }}
              >
                <input
                  id="check_one_day"
                  type="checkbox"
                  checked={oneDay}
                  onChange={() => setoneDay(!oneDay)}
                  style={{ marginTop: '-0.5rem' }}
                />
                <label
                  for="check_one_day"
                  style={{ padding: '0.2rem', color: 'rgba(0,0,0,0.7)' }}
                >
                  Evento será apenas em um dia
                </label>
              </div>
              {dados.type == 1 && (
                <TextField
                  value={dados.duration}
                  onChange={(e) =>
                    setDados({ ...dados, duration: e.target.value })
                  }
                  margin="dense"
                  id="duration"
                  label="Duração"
                  type="text"
                  required
                  fullWidth
                />
              )}
              <TextField
                value={dados.location}
                onChange={(e) =>
                  setDados({ ...dados, location: e.target.value })
                }
                margin="dense"
                id="location"
                label="Local"
                type="text"
                required
                fullWidth
              />
              {dados.type == 1 && (
                <TextField
                  value={dados.hoster}
                  onChange={(e) =>
                    setDados({ ...dados, hoster: e.target.value })
                  }
                  margin="dense"
                  id="hoster"
                  label="Responsável"
                  type="text"
                  required
                  fullWidth
                />
              )}
              {dados.type == 0 && (
                <TextField
                  value={dados.subscription_link}
                  onChange={(e) =>
                    setDados({ ...dados, subscription_link: e.target.value })
                  }
                  margin="dense"
                  id="link_externo"
                  label="Link do evento (ex.: http://www...)"
                  type="text"
                  required
                  fullWidth
                />
              )}
            </div>
            <br />
            <br />
            <p style={{ fontSize: '17.5px', color: 'rgba(0,0,0,0.5)' }}>
              Descrição:{' '}
            </p>
            <div className={classes.editText}>
              <Editor
                defaultEditorState={
                  props.info ? dados.contentBlocks : textdescription.description
                }
                // editorState={textdescription.description}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
              />
            </div>
            <br />
            {dados.type == 1 && (
              <p style={{ fontSize: '17.5px', color: 'rgba(0,0,0,0.5)' }}>
                Formas de pagamento:{' '}
              </p>
            )}
            {dados.type == 1 ? (
              <div className={classes.subAreas}>
                {dados.payment.map((a, i) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        justifyItems: 'center',
                      }}
                    >
                      <ArrowDropUpIcon
                        className={i != 0 ? classes.movimentar : ''}
                        style={{ opacity: i != 0 ? '100%' : '40%' }}
                        onClick={(e) => {
                          if (i != 0) changePosition(dados.payment, i, i - 1);
                        }}
                      />
                      <ArrowDropDownIcon
                        className={
                          dados.payment.length > 1 &&
                          i != dados.payment.length - 1
                            ? classes.movimentar
                            : ''
                        }
                        style={{
                          opacity:
                            dados.payment.length > 1 &&
                            i != dados.payment.length - 1
                              ? '100%'
                              : '40%',
                        }}
                        onClick={(e) => {
                          if (
                            dados.payment.length > 1 &&
                            i != dados.payment.length - 1
                          )
                            changePosition(dados.payment, i, i + 1);
                        }}
                      />
                    </div>
                    <Grid container justify="space-around">
                      <FormControl
                        className={clsx(classes.margin, classes.textField)}
                        required
                      >
                        <InputLabel htmlFor="standard-adornment-password">
                          {i + 1}º Método
                        </InputLabel>
                        <Input
                          id="standard-adornment-password"
                          type={'text'}
                          value={a}
                          onChange={(e) => {
                            aux = dados.payment;
                            aux[i] = e.target.value;
                            setDados({ ...dados, payment: aux });
                          }}
                          startAdornment={
                            <InputAdornment position="start">
                              {i + 1}° -{' '}
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  aux = dados.payment;
                                  aux.push('');
                                  setDados({ ...dados, payment: aux });
                                }}
                                //  onMouseDown={handleMouseDownPassword}
                              >
                                <AddCircleIcon />
                              </IconButton>
                              {i != 0 ? (
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => {
                                    aux = dados.payment;
                                    aux.splice(i, 1);
                                    setDados({ ...dados, payment: aux });
                                  }}
                                  //  onMouseDown={handleMouseDownPassword}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : (
                                ''
                              )}
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                  </div>
                ))}
              </div>
            ) : (
              ''
            )}
            <br />
            {dados.type == 1 && (
              <p style={{ fontSize: '17.5px', color: 'rgba(0,0,0,0.5)' }}>
                Seletor de ementa:{' '}
              </p>
            )}
            {dados.type == 1 && (
              <div className={classes.subAreas}>
                <Autocomplete
                  id="combo-box-demo"
                  value={dados.file_name}
                  onChange={(event, newValue) => {
                    setDados({ ...dados, file_name: newValue });
                  }}
                  options={listementa}
                  getOptionLabel={(option) => option}
                  style={{ flex: '1', margin: '0rem 0.4rem' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecionar ementa existente"
                    />
                  )}
                />
                <div
                  style={{
                    margin: '1rem auto',
                    opacity: '80%',
                    width: '100%',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  OU
                </div>
                <div
                  style={{
                    pointerEvents: dados.file_name ? 'none' : 'all',
                    opacity: dados.file_name ? '20%' : '100%',
                    width: '90%',
                    margin: '1rem auto',
                  }}
                >
                  <DropzoneArea
                    onChange={(e) => setFileementa(e)}
                    acceptedFiles={['.pdf']}
                    filesLimit={'1'}
                    dropzoneText="Arraste o PDF para este campo ou clique aqui para buscar"
                    maxFileSize="6000000"
                    getFileAddedMessage={(fileName) =>
                      `Arquivo ${fileName} adicionado com sucesso.`
                    }
                    getFileRemovedMessage={(fileName) =>
                      `Arquivo ${fileName} removido.`
                    }
                    getFileLimitExceedMessage={(filesLimit) =>
                      `Número máximo de ${filesLimit} arquivos excedido.`
                    }
                    getDropRejectMessage={() =>
                      `Tipo de arquivo não permitido.`
                    }
                  />
                </div>
              </div>
            )}
            <br />
            <br />
            <p style={{ fontSize: '17.5px', color: 'rgba(0,0,0,0.5)' }}>
              Seletor de imagem:{' '}
            </p>
            <DropzoneArea
              onChange={handleChange}
              acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
              filesLimit={'1'}
              dropzoneText="Arraste a imagem para este campo ou clique aqui para buscar"
              maxFileSize="6000000"
              getFileAddedMessage={(fileName) =>
                `Arquivo ${fileName} adicionado com sucesso.`
              }
              getFileRemovedMessage={(fileName) =>
                `Arquivo ${fileName} removido.`
              }
              getFileLimitExceedMessage={(filesLimit) =>
                `Número máximo de ${filesLimit} arquivos excedido.`
              }
              getDropRejectMessage={() => `Tipo de arquivo não permitido.`}
            />
            {props.info ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <br />
                <p
                  style={{
                    fontSize: '14.5px',
                    color: 'rgba(0,0,0,0.5)',
                    margin: 'auto',
                  }}
                >
                  Imagem atual
                </p>
                <img
                  src={props.info.image_url}
                  style={{ width: '50%', margin: 'auto' }}
                />
              </div>
            ) : (
              ''
            )}

            {dados.type == 1 && (
              <>
                <p
                  style={{
                    fontSize: '17.5px',
                    color: 'rgba(0,0,0,0.5)',
                    marginTop: '2.5rem',
                  }}
                >
                  Seletor de imagem do Patrocinador:{' '}
                </p>
                <DropzoneArea
                  onChange={handleSponsor}
                  acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                  filesLimit={'1'}
                  dropzoneText="Arraste a imagem para este campo ou clique aqui para buscar"
                  maxFileSize="6000000"
                  getFileAddedMessage={(fileName) =>
                    `Arquivo ${fileName} adicionado com sucesso.`
                  }
                  getFileRemovedMessage={(fileName) =>
                    `Arquivo ${fileName} removido.`
                  }
                  getFileLimitExceedMessage={(filesLimit) =>
                    `Número máximo de ${filesLimit} arquivos excedido.`
                  }
                  getDropRejectMessage={() => `Tipo de arquivo não permitido.`}
                />

                {props.info &&
                imgSponsor[0] !== null &&
                imgSponsor[0] === props.info.sponsor_image_url ? (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <br />
                    <p
                      style={{
                        fontSize: '16.5px',
                        color: 'rgba(0,0,0,0.5)',
                        margin: 'auto',
                        marginTop: '.25rem',
                      }}
                    >
                      Imagem atual
                    </p>
                    <img
                      src={imgSponsor[0]}
                      style={{ width: '50%', margin: 'auto' }}
                    />
                    <Button
                      onClick={() => removerPatrocinador(props.info.id)}
                      style={{
                        backgroundColor: '#f50057',
                        maxHeight: '2.5rem',
                        color: '#fff',
                        margin: '.5rem auto 2rem auto',
                      }}
                      className="button"
                      variant="contained"
                    >
                      Remover imagem
                    </Button>
                  </div>
                ) : (
                  ''
                )}

                {props.info &&
                imgSponsor[0] === null &&
                null !== props.info.sponsor_image_url ? (
                  <div
                    style={{
                      width: '100%',
                      margin: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      onClick={() => restaurarPatrocinador()}
                      style={{
                        backgroundColor: '#f58057',
                        maxHeight: '2.5rem',
                        color: '#fff',
                        margin: '1rem auto',
                      }}
                      className="button"
                      variant="contained"
                    >
                      Restaurar imagem anterior
                    </Button>
                  </div>
                ) : (
                  ''
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary" variant="contained">
              Cancelar
            </Button>
            <Button onClick={() => submeterRascunho()} variant="contained">
              Rascunho
            </Button>
            <Button type="submit" color="primary" variant="contained">
              {props.info
                ? props.info.publish
                  ? 'Salvar'
                  : 'Publicar'
                : 'Publicar'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {load ? <Backdrop /> : ''}
      {alertas.status ? (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() =>
            setAlertas({ ...alertas, status: false, type: '', message: '' })
          }
        >
          <Alert
            onClose={() =>
              setAlertas({ ...alertas, status: false, type: '', message: '' })
            }
            severity={alertas.type}
          >
            {alertas.message}
          </Alert>
        </Snackbar>
      ) : (
        ''
      )}
    </Conteudo>
  );
}
