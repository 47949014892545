import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArchiveIcon from '@material-ui/icons/Archive';
import CreateIcon from '@material-ui/icons/Create';
import {Albunscard} from './styles';

import API from '../../services/api';
import {CardDeck} from 'react-bootstrap';
import {Options} from './styles';
import Albumimagensupload from '../Albumimagensupload';
import Dialogodeconfirmacao from '../Dialogosdeconfirmacao';

// Datas e horas
import Moment from 'react-moment';


const useStyles = makeStyles({
  root: {
    maxWidth: 235,
    margin: '0.5rem',
  },
});

export default function Albunsimagens(props) {
  const classes = useStyles();
  const [images,setImages] = useState([]);
  const [show, setShow] = useState(false);
  const [confirmacao, setConfirmacao] = useState([false,'']);
  const [editarlink, setEditarlink] = useState({
    status: false,
  });

  const [upload,setUpload] = useState(false);


const abrirDialogo=e=>{
  setUpload(true);
}
const fecharDialogo=e=>{
  setUpload(false);
  setEditarlink({
    status: false, 
  });
}
const deleteImage=e=>{
  const config = {
    headers: {
      'Authorization': 'Bearer ' + props.user.token,
    }
  };
  API.delete(`private_albums/${props.info.id}/photos/${e}`, config)
  .then(a=>{
      window.location.reload(); 
  }).catch(error=>{
    console.log(error.response);
  });

}


const atualizar=e=>{
  const config = {
    headers: {
      'Authorization': 'Bearer ' + props.user.token,
    }
  };
  API.get('private_albums', config)
  .then(a=>{
      a.data.map((a,i)=>(
          images.push({
              id: a.id,
              name: a.name,
              src: a.image_url,
              width: 4,
              height: 2   
          })
      ))   
  });
  setShow(true);
}

// filtros 
const ftDeletar=obj=>{
  if (('slug' in obj && obj.slug =='delete_album')) {
    return true;
  } else {
    return false;
  }
}
const ftCriar=obj=>{
  if (('slug' in obj && obj.slug =='create_album')) {
    return true;
  } else {
    return false;
  }
}
  return (
    <div style={{margin:"auto"}}>
    <CardDeck>
    {props.info.photos.map((a,i)=>(
        <Card className={classes.root} key={i}>
         <CardActionArea>
           <CardMedia
             component="img"
             alt={a.name}
             height="150"
             image={a.image_url}
             title={a.image}
           />
         </CardActionArea>
           <Albunscard>
              </Albunscard>
         {/* <div style={{fontSize:'12px', backgroundColor:'rgba(0,83,81,1)', color:"#fff", padding:"0.2rem"}}>LINK: {a.link? a.link : "Sem link"} <CreateIcon style={{fontSize:'18px', cursor:"pointer"}} onClick={()=> {setEditarlink({status:true, a});abrirDialogo()}}/></div> */}
         <CardActions>
          <Options >
          {(props.user.roles.filter(ftDeletar).length>0)?
           <Button size="small" color="secondary" onClick={()=>setConfirmacao([true,a.id])}>
             <DeleteIcon/> Excluir
           </Button>
           :''}
                <a href={a.image_url} target="_blank">
                <Button size="small" color="primary">
                    <ArchiveIcon style={{fontSize:'20px'}}/> Download
                </Button>
                </a>
           </Options>
         </CardActions>
        </Card>
    ))}   
    {(props.user.roles.filter(ftCriar).length>0)?
    <Card className={classes.root} style={{display:'flex'}} onClick={abrirDialogo}>
         <CardActionArea style={{padding:'1rem'}}>
             <AddCircleIcon style={{fontSize:'40px'}}/>
            <Typography variant="h6" component="h3">
                Adicionar <br/>nova imagem
            </Typography>
         </CardActionArea>
        </Card>
        :''}
    </CardDeck>   

    {upload? <Albumimagensupload editInfo={editarlink.status? editarlink:''} id={props.info.id} fecharDialogo={()=>fecharDialogo()} user={props.user} newPosition={images.length+1}/> : ''}
    {confirmacao[0]?
    <Dialogodeconfirmacao
      tamanho="xs"
      message="Tem certeza de que deseja deletar esta imagem?"
      confirmar={()=>deleteImage(confirmacao[1])}
      negar={()=>setConfirmacao([false,''])}
    />:""}
    </div>
  );
}