import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {Conteudo} from './styles';
import API from '../../services/api';
import {DropzoneArea} from 'material-ui-dropzone';
import Backdrop from '../Backdrop';

//campo de data
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

// dropdown 
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

// alertas
import { parseISO, isAfter } from 'date-fns';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: `0.6rem 0rem`,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Albunsupload(props) {
  const classes = useStyles();
  
  const [temArq, setTemarq] = React.useState(false)
  const [open, setOpen] = React.useState(true);
  const [files, setFiles] = React.useState([]);
  const [dados, setDados] = React.useState({
      title: '',
      description: '',
      date: new Date(),
      images: [],
      thumbnail: props.editInfo? props.editInfo.thumbnail:'',
      thumbnail_url: '',
  });
  const [alertas, setAlertas] = React.useState({
    status: false,
    type:'',
    message:""
  });

  const  [load, setLoad] = React.useState(false);

  const handleDateChange = (date) => {
    setDados({...dados, date: date});
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    props.fecharDialogo();
  };

  const handleChange=e=>{
   setFiles(e);
  }

  const submeterNovo= e=>{
    e.preventDefault();
    setLoad(true);
      const config = {
        headers: {
          "Authorization": "Bearer " + props.user.token,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true"
        }
      };
      const data = new FormData();
     
      if(props.editInfo){

        const config = {
          headers: {
            "Authorization": "Bearer " + props.user.token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Credentials": "true"
          }
        };

        const info = {
          title: dados.title,
          date: dados.date,
          description: dados.description,
          thumbnail: dados.thumbnail,
          publish: true
        }

        API.put(`albums/${props.editInfo.id}`, info, config)
        .then(a=>{
          props.fecharDialogo();
          setLoad(false);
        }).catch(error=>{
          console.log(error.response);
          setAlertas({...alertas, status:true, type:'error', message: error.response.error});
        });

      }else{
        data.append('title', dados.title);
        data.append('publish', true);
        data.append('date', moment(dados.date).toISOString());
        data.append('description', dados.description);
        files.map((a,i)=>{data.append('images',a)});

        if(files.length>0){

          API.post('albums', data, config)
          .then(a=>{
            props.fecharDialogo();
            setLoad(false);
          }).catch(error=>{
            console.log(error.response);
            setAlertas({...alertas, status:true, type:'error', message:error.response.data.error});
          });

        }else{
          setAlertas({...alertas, status:true, type:'error', message:'Nenhuma imagem foi selecionada.'});
          setLoad(false);
        }

      }
      
      for (var pair of data.entries()) {
        // console.log(pair[0]+ ', ' + pair[1]); 
      }
  }
  const submeterRascunho= e=>{
    setLoad(true);
      const config = {
        headers: {
          "Authorization": "Bearer " + props.user.token,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true"
        }
      };
      const data = new FormData();
     
      if(props.editInfo){

        const config = {
          headers: {
            "Authorization": "Bearer " + props.user.token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Credentials": "true"
          }
        };

        const info = {
          title: dados.title,
          date: dados.date,
          description: dados.description,
          thumbnail: dados.thumbnail,
          publish: false
        }

        API.put(`albums/${props.editInfo.id}`, info, config)
        .then(a=>{
          props.fecharDialogo();
          setLoad(false);
        }).catch(error=>{
          console.log(error.response);
          setAlertas({...alertas, status:true, type:'error', message: error.response.error});
        });

      }else{
        data.append('title', dados.title);
        data.append('publish', false);
        data.append('date', moment(dados.date).toISOString());
        data.append('description', dados.description);
        files.map((a,i)=>{data.append('images',a)});

        if(files.length>0){

          API.post('albums', data, config)
          .then(a=>{
            props.fecharDialogo();
            setLoad(false);
          }).catch(error=>{
            console.log(error.response);
            setAlertas({...alertas, status:true, type:'error', message:error.response.data.error});
          });

        }else{
          setAlertas({...alertas, status:true, type:'error', message:'Nenhuma imagem foi selecionada.'});
          setLoad(false);
        }
      }
  }

  const verificarEdit=e=>{
    setDados(
      props.editInfo
    );
    // console.log(props.editInfo)
  }

  React.useEffect(()=>{
    if(!dados.name){
      if(props.editInfo){
        verificarEdit();
      }
    }
  },[])
  return (
    <Conteudo>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" scroll={'body'}>
       <form onSubmit={submeterNovo}>
       <DialogTitle id="form-dialog-title">{props.editInfo? 'Editar Álbum': 'Novo Álbum'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {props.editInfo? 'Preencha os campos abaixo e confirme para aplicar a edição.':
           'Preencha os campos abaixo corretamente para que o novo banner seja inserido.'}
          </DialogContentText>
          <TextField
            value={dados.title}
            onChange={e=> setDados({...dados, title:e.target.value})}
            margin="dense"
            id="name"
            label="Título do Álbum"
            type="text"
            required
            fullWidth
            // disabled={props.editInfo? true: false}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
           <Grid container justify="space-around">
           <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline-2"
              label="Data"
              fullWidth
              // className={classes.textJust}
              value={dados.date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              // disabled = {values.abrirDialogo.estado}
            />
            </Grid>
           </MuiPickersUtilsProvider>
           <TextField
            value={dados.description}
            onChange={e=> setDados({...dados, description:(e.target.value).slice(0,255)})}
            margin="dense"
            id="name"
            label={`Descrição (${dados.description.length}/255)`}
            type="text"
            fullWidth
            multiline={true}
            rows={4}
            variant='outlined'
            required
          />
          <br/>
    
          {props.editInfo? 
            <div style={{display:'flex', flexDirection:'column'}}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Escolher capa</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    defaultValue={dados.thumbnail}
                    onChange={(k)=> setDados({...dados,thumbnail:k.target.value.image, thumbnail_url:k.target.value.image_url })}
                    label="escolher capa"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {dados.photos? dados.photos.map((e,j)=> <MenuItem value={e}>{e.image}</MenuItem>):''}
                  </Select>
                </FormControl>

              <img src={dados.thumbnail_url? dados.thumbnail_url : props.editInfo.photos[0].image_url} style={{maxWidth:"250px", margin:'auto'}}/>
            </div>: 
            <div >
            <p style={{fontSize:"17.5px", color:"rgba(0,0,0,0.5)"}}>Selecione quantas imagens desejar: </p>
                  <DropzoneArea
                  onChange={handleChange}
                  acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                  filesLimit={"50"}
                  dropzoneText="Arraste as imagens para este campo ou clique aqui para buscar"
                  maxFileSize="6000000"
                  getFileAddedMessage={(fileName)=>`Arquivo ${fileName} adicionado com sucesso.`}
                  getFileRemovedMessage={(fileName)=>`Arquivo ${fileName} removido.`}
                  getFileLimitExceedMessage={(filesLimit)=>`Número máximo de ${filesLimit} arquivos excedido.`}
                  getDropRejectMessage={()=>`Tipo de arquivo não permitido.`}
            />
            </div>
            }
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancelar
          </Button>
          <Button onClick={()=> submeterRascunho()}  variant="contained">
            Rascunho
          </Button>
          <Button type="submit" color="primary"  variant="contained">
          {props.editInfo?  (props.editInfo.publish? 'Aplicar Edição':'Publicar'):'Publicar'}
          </Button>
        </DialogActions>
       </form>
      </Dialog>
      {load? <Backdrop/>: ''}
      {alertas.status?
       <Snackbar open={true} autoHideDuration={6000} onClose={()=>setAlertas({...alertas, status:false, type:'', message:''})}>
       <Alert onClose={()=>setAlertas({...alertas, status:false, type:'', message:''})} severity={alertas.type}>
         {alertas.message}
       </Alert>
     </Snackbar>:""}
    </Conteudo>
  );
}