import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '../Backdrop';

import {Conteudo} from './styles';
import API from '../../services/api';
import {DropzoneArea} from 'material-ui-dropzone';

// dropdown 
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// alertas
import { parseISO, isAfter } from 'date-fns';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

// estilos
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Bannersupload(props) {
  const classes = useStyles();
  const [vetPosition, setVetPosition] = React.useState([]);
  const [bdshow,setBdshow]  = React.useState(false);
  const [temArq, setTemarq] = React.useState(false)
  const [open, setOpen] = React.useState(true);
  const [files, setFiles] = React.useState([]);
  const [dados, setDados] = React.useState({
      name: '',
      link:'',
      publish: true,
      position: props.newPosition
  });
  const [alertas, setAlertas] = React.useState({
    status: false,
    type:'',
    message:""
  });
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    props.fecharDialogo();
  };

  const handleChange=e=>{
   setFiles(e);
  }

  const submeterNovo= e=>{
    e.preventDefault();
    setBdshow(true);

      const config = {
        headers: {
          "Authorization": "Bearer " + props.user.token,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true"
        }
      };
      const data = new FormData();
     
      if(props.editInfo){
        data.append('name', dados.name);
        data.append('link', dados.link);
        data.append('publish', true);

        data.append('position', (dados.position==(props.newPosition-1))? dados.position+1 : dados.position);
        
        API.put(`banners/${props.editInfo.a.id}`, data, config)
        .then(a=>{
          props.fecharDialogo();
          setBdshow(false);
          // window.location.reload();
          
        }).catch(error=>{
          console.log(error.response);
        });

      }else{
        data.append('name', dados.name);
        data.append('link', dados.link);
        data.append('publish', true);
        data.append('image', files[0]);
        data.append('position',  (dados.position==(props.newPosition-1))? dados.position+1 : dados.position);
        
        if(files.length>0){
          API.post('banners', data, config)
          .then(a=>{
            props.fecharDialogo();
            setBdshow(false);
            // window.location.reload();
          }).catch(error=>{
            console.log(error.response);
          });
        }else{
          setAlertas({status:true, type:'error', message:'Nenhuma imagem foi selecionada'});
          setBdshow(false);
        }
      }

      // console.log(config);
  }

  // codigo de rascunho 
  const submeterRascunho= e=>{

    setBdshow(true);

      const config = {
        headers: {
          "Authorization": "Bearer " + props.user.token,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true"
        }
      };
      const data = new FormData();
     
      if(props.editInfo){
        data.append('name', dados.name);
        data.append('link', dados.link);
        data.append('publish', false);

        data.append('position', (dados.position==(props.newPosition-1))? dados.position+1 : dados.position);
        API.put(`banners/${props.editInfo.a.id}`, data, config)
        .then(a=>{
          setBdshow(false);
          // window.location.reload();
          props.fecharDialogo();
        }).catch(error=>{
          console.log(error.response);
        });

      }else{
        data.append('name', dados.name);
        data.append('link', dados.link);
        data.append('publish', false);
        data.append('image', files[0]);
        data.append('position',  (dados.position==(props.newPosition-1))? dados.position+1 : dados.position);
        if(files.length>0){
          API.post('banners', data, config)
          .then(a=>{
            setBdshow(false);
            // window.location.reload();
            props.fecharDialogo();
          }).catch(error=>{
            console.log(error.response);
          });
        }else{
          setAlertas({status:true, type:'error', message:'Nenhuma imagem foi selecionada'});
          setBdshow(false);
        }
      }

      // console.log(config);
  }


  const verificarEdit=e=>{
    setDados({
      name: props.editInfo.a.name,
      link:props.editInfo.a.link,
      position: props.editInfo.a.position,
      publish: props.editInfo.a.publish,
    });

    for(var i=1;i<(props.newPosition);i++){
      vetPosition.push(i)
    }
  }

  React.useEffect(()=>{
    if(!dados.name){
      if(props.editInfo){
        verificarEdit();
      }
    }
    if(vetPosition.length==0){
      for(var i=1;i<(props.newPosition+1);i++){
        vetPosition.push(i)
      }
    }
  },[]);
  

  return (
    <Conteudo>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" scroll={'body'}>
       <form onSubmit={submeterNovo}>
       <DialogTitle id="form-dialog-title">{props.editInfo? 'Editar Banner': 'Novo banner'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {props.editInfo? 'Preencha os campos abaixo e confirme para aplicar a edição.':
           'Preencha os campos abaixo corretamente para que o novo banner seja inserido.'}
          </DialogContentText>
          <TextField
            value={dados.name}
            onChange={e=> setDados({...dados, name:e.target.value})}
            margin="dense"
            id="name"
            label="Nome da imagem"
            type="text"
            required
            fullWidth
            // disabled={props.editInfo? true: false}
          />
           <TextField
            value={dados.link}
            onChange={e=> setDados({...dados, link:e.target.value})}
            margin="dense"
            id="name"
            label="Link da imagem (opcional)"
            type="text"
            fullWidth
          />
         <br/>
              <FormControl variant='outlined' className='' margin="dense" fullWidth>
                <InputLabel id="posicao" style={{backgroundColor:'#fff', padding:'0rem 0.5rem'}}>Selecionar Posição</InputLabel>
                <Select
                  labelId="posicao"
                  id="dposicao"
                  value={dados.position}
                  onChange={e=>setDados({...dados,position:e.target.value})}
                >
                  <MenuItem value="">
                    {/* <em>None</em> */}
                  </MenuItem>
                  {vetPosition.map((e,j)=> <MenuItem value={e}>{e}</MenuItem>)}
                </Select>
              </FormControl>

          
                  <DropzoneArea
                  onChange={handleChange}
                  acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                  filesLimit={"1"}
                  dropzoneText="Arraste a imagem para este campo ou clique aqui para buscar"
                  maxFileSize="6000000"
                  getFileAddedMessage={(fileName)=>`Arquivo ${fileName} adicionado com sucesso.`}
                  getFileRemovedMessage={(fileName)=>`Arquivo ${fileName} removido.`}
                  getFileLimitExceedMessage={(filesLimit)=>`Número máximo de ${filesLimit} arquivos excedido.`}
                  getDropRejectMessage={()=>`Tipo de arquivo não permitido.`}
            />
            
            {props.editInfo? 
            <div>
              <br/>
              <img src={props.editInfo.a.image_url} style={{width:"100%"}}/>
            </div>: ''}
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancelar
          </Button>
          <Button onClick={()=> submeterRascunho()}  variant="contained">
            Rascunho
          </Button>
          <Button type="submit" color="primary" variant='contained'>
          {props.editInfo?  (props.editInfo.a.publish? 'Aplicar Edição':'Publicar'):'Publicar'}
          </Button>
        </DialogActions>
       </form>
      </Dialog>
      {bdshow? <Backdrop/>:''}
      {alertas.status?
       <Snackbar open={true} autoHideDuration={6000} onClose={()=>setAlertas({...alertas, status:false, type:'', message:''})}>
       <Alert onClose={()=>setAlertas({...alertas, status:false, type:'', message:''})} severity={alertas.type}>
         {alertas.message}
       </Alert>
     </Snackbar>:""}
    </Conteudo>
  );
}