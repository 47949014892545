import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Link } from 'react-router-dom';
import API from '../../services/api';
import Moment from 'react-moment';
import { parseISO, isAfter } from 'date-fns';

import Agendaoptions from '../Agendaoptions';
import Dialogodeconfirmacao from '../Dialogosdeconfirmacao';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

import Agendanew from '../Agendanew';
// paginação
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: window.innerWidth > 675 ? '66.66%' : '90%',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    opacity: '80%',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    textAlign: 'right !important',
  },
  maisDetalhes: {
    display: 'flex',
    flexDirection: window.innerWidth > 675 ? 'row' : 'column',
    fontSize: '16px',
    width: '100%',
  },
  maisInformacoes: {
    flex: 1,
    opacity: '90%',
    marginBottom: '2rem',
    '& a': {
      padding: '0.5rem 2rem',
      borderRadius: '5px',
      textDecoration: 'none',
      border: '0px',
      display: 'inline-block',
    },
  },
  maisImagem: {
    flex: 1,
    '& img': {
      width: '90%',
      minHeight: 'auto',
      maxWidth: '400px',
      // float:'left'
    },
  },
  pagina: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',

    '& > *': {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
  rootrascunho: {
    opacity: 0.8,
    ['&:hover']: {
      opacity: 1,
    },
  },
}));

export default function Agendaaccordion(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const [lista, setLista] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [confirmacao, setConfirmacao] = React.useState([false, '']);

  const [nbpg, setNbpg] = React.useState('1');

  const [editevent, setEditevent] = React.useState({
    status: false,
    dados: '',
  });

  React.useEffect(() => {
    if (lista.length == 0) consultarApi();
  }, []);

  const consultarApi = async (e) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
      },
    };
    await API.get('all_events_list', config)
      .then((a) => {
        setLista(a.data);
        // console.log('INFO: ', a.data);
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });

    setShow(true);
    // console.log(lista);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const deleteEvent = (e, tipo) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
      },
    };
    API.delete(tipo == 1 ? `events/${e}` : `general_events/${e}`, config)
      .then((a) => {
        // window.location.reload();
        consultarApi();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const paginacao = (event, value) => {
    setNbpg(value);
  };

  var aux;
  var npp = 10;

  // filtros
  const ftEditar = (obj) => {
    if ('slug' in obj && obj.slug == 'edit_events') {
      return true;
    } else {
      return false;
    }
  };
  const ftDeletar = (obj) => {
    if ('slug' in obj && obj.slug == 'delete_events') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={classes.root} style={{ margin: 'auto' }}>
      {show
        ? lista.slice((nbpg - 1) * npp, nbpg * npp).map((a, i) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
              }}
              key={i}
            >
              <ExpansionPanel
                expanded={expanded === `panel${i}`}
                onChange={handleChange(`panel${i}`)}
                key={a.id}
                style={{
                  flex: '1',
                  backgroundColor: a.publish ? '' : 'rgba(255,237,0,.5)',
                }}
                className={a.publish ? '' : classes.rootrascunho}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>
                    <b>
                      [{a.location}]{' '}
                      <Moment format="DD/MM/YYYY">{a.start_date}</Moment> -{' '}
                      {a.title}
                    </b>
                    <div>
                      {a.publish ? (
                        <div
                          style={{
                            color:
                              a.is_active ||
                              !isAfter(parseISO(a.start_date), new Date())
                                ? 'rgba(187, 0, 0,1)'
                                : 'rgba(52, 175, 35,1)',
                            backgroundColor:
                              a.is_active ||
                              !isAfter(parseISO(a.start_date), new Date())
                                ? 'rgba(187, 0, 0,0.3)'
                                : 'rgba(52, 175, 35,0.3)',
                            borderRadius: '10px',
                            fontSize: '10px',
                            padding: '0.2rem 0.3rem',
                            marginLeft:
                              window.innerWidth > 675 ? '0.2rem' : '0rem',
                            textAlign: 'center',
                            textTransform: 'uppercase',
                          }}
                        >
                          {a.is_active ||
                          !isAfter(parseISO(a.start_date), new Date())
                            ? 'Encerrado'
                            : 'Em aberto'}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>

                    <div
                      style={{
                        color: '#777',
                        backgroundColor: '#ddd',
                        borderRadius: '10px',
                        fontSize: '10px',
                        padding: '0.2rem 0.3rem',
                        marginLeft: window.innerWidth > 675 ? '0.2rem' : '0rem',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                      }}
                    >
                      {a.type == 1 ? 'Treinamento' : 'Geral'}
                    </div>
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.maisDetalhes}>
                    <div className={classes.maisInformacoes}>
                      <br />
                      <p
                        style={{
                          lineHeight: window.innerWidth > 923 ? '1.1rem' : '',
                          textAlign: 'left',
                        }}
                      >
                        <b>Data:</b>{' '}
                        {a.type == 1 ? (
                          <>
                            <Moment format="DD/MM/YYYY">{a.start_date}</Moment>{' '}
                            { (a.start_date != a.end_date) && <>e <Moment format="DD/MM/YYYY">{a.end_date}</Moment>{' '}</>}
                            ({a.start_day} {(a.start_date != a.end_date) && ("e "+a.end_day)})
                          </>
                        ) : (
                          <Moment format="DD/MM/YYYY">{a.start_date}</Moment>
                        )}
                      </p>
                      {a.type == 1 && (
                        <p style={{ lineHeight: '1.1rem', textAlign: 'left' }}>
                          <b>Duração:</b> {a.duration}
                        </p>
                      )}
                      <p style={{ lineHeight: '1.1rem', textAlign: 'left' }}>
                        <b>Local:</b> {a.location}
                      </p>
                      {a.type == 1 && (
                        <p style={{ lineHeight: '1.1rem', textAlign: 'left' }}>
                          <b>Responsável:</b> {a.hoster}
                        </p>
                      )}
                      {a.type == 1 && (
                        <p style={{ lineHeight: '1.1rem', textAlign: 'left' }}>
                          <b>Ementa:</b>{' '}
                          {a.file_name ? (
                            <a
                              target="_blank"
                              href={`http://alanaraujo.eng.br/documentos/${a.file_name}`}
                              style={{ padding: '0px', magin: '0px' }}
                            >
                              Clique aqui
                            </a>
                          ) : (
                            'Indisponível'
                          )}
                        </p>
                      )}
                      {a.type == 0 && (
                        <p style={{ lineHeight: '1.1rem', textAlign: 'left' }}>
                          <b>Link:</b>{' '}
                          <a
                            href={a.subscription_link}
                            style={{ margin: '0rem', padding: '0rem' }}
                          >
                            {a.subscription_link}
                          </a>
                        </p>
                      )}
                      <p style={{ textAlign: 'justify' }}>
                        <b>Descrição:</b>
                        <br />
                        <div
                          style={{ fontSize: '14px', padding: '1.1rem' }}
                          dangerouslySetInnerHTML={{
                            __html: a.description.replace(/\n/g, '<br />'),
                          }}
                        ></div>
                      </p>
                    </div>
                    <div className={classes.maisImagem}>
                      <img
                        src={a.image_url}
                        style={{
                          filter: isAfter(parseISO(a.start_date), new Date())
                            ? ''
                            : 'grayscale(1)',
                        }}
                      />
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <div style={{ marginLeft: '0.5rem' }}>
                {props.user.roles.filter(ftEditar).length > 0 ? (
                  <IconButton
                    onClick={() =>
                      setEditevent({ ...editevent, status: true, dados: a })
                    }
                  >
                    <CreateIcon />
                  </IconButton>
                ) : (
                  ''
                )}
                {props.user.roles.filter(ftDeletar).length > 0 ? (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setConfirmacao([true, a.id, a.type])}
                    //  onMouseDown={handleMouseDownPassword}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  ''
                )}
              </div>
            </div>
          ))
        : ''}
      <div className={classes.pagina}>
        {show ? (
          <Pagination
            count={Math.ceil(lista.length / npp)}
            variant="outlined"
            shape="rounded"
            onChange={paginacao}
          />
        ) : (
          ''
        )}
      </div>

      {confirmacao[0] ? (
        <Dialogodeconfirmacao
          tamanho="xs"
          message="Tem certeza de que deseja excluir este evento?"
          confirmar={() => {
            deleteEvent(confirmacao[1], confirmacao[2]);
            setConfirmacao([false, '']);
          }}
          negar={() => setConfirmacao([false, ''])}
        />
      ) : (
        ''
      )}

      {editevent.status ? (
        <Agendanew
          user={props.user}
          fecharDialogo={() => {
            setEditevent({ ...editevent, status: false });
            consultarApi();
          }}
          info={editevent.dados}
        />
      ) : (
        ''
      )}
    </div>
  );
}
