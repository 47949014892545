import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {Conteudo} from './styles';
import API from '../../services/api';
import {DropzoneArea} from 'material-ui-dropzone';
import Backdrop from '../Backdrop';

//campo de data
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

// dropdown 
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

// alertas
import { parseISO, isAfter } from 'date-fns';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: `0.6rem 0rem`,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


export default function Funcionarionew(props) {
  const classes = useStyles();
  
  const [temArq, setTemarq] = React.useState(false)
  const [open, setOpen] = React.useState(true);
  const [files, setFiles] = React.useState([]);
  const [dados, setDados] = React.useState({
      name: '',
      graduation: '',
      work_position: '',
      images: [],
      position: props.newPosition,
  });
  const [alertas, setAlertas] = React.useState({
    status: false,
    type:'',
    message:""
  });
  
  const  [load, setLoad] = React.useState(false);

  const [vetPosition, setVetPosition] = React.useState([]);

  const handleDateChange = (date) => {
    setDados({...dados, date: date});
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    props.fecharDialogo();
  };

  const handleChange=e=>{
   setFiles(e);
  }

  const submeterNovo= e=>{
    e.preventDefault();
    setLoad(true);
      const config = {
        headers: {
          "Authorization": "Bearer " + props.user.token,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true"
        }
      };
      const data = new FormData();
     
      if(props.editInfo){

        data.append('name', dados.name);
        data.append('graduation', dados.graduation);
        data.append('work_position', dados.work_position);
        data.append('publish', true);
        data.append('image',files[0]);

        data.append('position', (dados.position==(props.newPosition-1))? dados.position+1 : dados.position);

          API.put(`staff/${props.editInfo.a.id}`, data, config)
          .then(a=>{
            // window.location.reload();
            props.fecharDialogo();
            setLoad(false);
          }).catch(error=>{
            console.log(error.response);
          });
      

      }else{
        data.append('name', dados.name);
        data.append('graduation', dados.graduation);
        data.append('work_position', dados.work_position);
        data.append('publish', true);
        data.append('image',files[0]);
        data.append('position',  (dados.position==(props.newPosition-1))? dados.position+1 : dados.position);

        if(files.length>0){
            API.post('staff', data, config)
            .then(a=>{
              window.location.reload();
              // props.fecharDialogo();
              setLoad(false);
            }).catch(error=>{
              console.log(error.response);
            });
          }else{
            setAlertas({status:true, type:'error', message:'Nenhuma imagem foi selecionada'});
            setLoad(false);
          }
      }
   
      for (var pair of data.entries()) {
        // console.log(pair[0]+ ', ' + pair[1]); 
      }
  }

  const submeterRascunho= e=>{
    setLoad(true);
      const config = {
        headers: {
          "Authorization": "Bearer " + props.user.token,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true"
        }
      };
      const data = new FormData();
     
      if(props.editInfo){

        data.append('name', dados.name);
        data.append('graduation', dados.graduation);
        data.append('work_position', dados.work_position);
        data.append('publish', false);
        data.append('image',files[0]);
        data.append('position', (dados.position==(props.newPosition-1))? dados.position+1 : dados.position);
     
          API.put(`staff/${props.editInfo.a.id}`, data, config)
          .then(a=>{
            // window.location.reload();
            props.fecharDialogo();
            setLoad(false);
          }).catch(error=>{
            console.log(error.response);
          });
      
      }else{
        data.append('name', dados.name);
        data.append('graduation', dados.graduation);
        data.append('work_position', dados.work_position);
        data.append('publish', false);
        data.append('image',files[0]);
        data.append('position',  (dados.position==(props.newPosition-1))? dados.position+1 : dados.position);
        if(files.length>0){
            API.post('staff', data, config)
            .then(a=>{
              // window.location.reload();
              props.fecharDialogo();
              setLoad(false);
            }).catch(error=>{
              console.log(error.response);
            });
          }else{
            setAlertas({status:true, type:'error', message:'Nenhuma imagem foi selecionada'});
            setLoad(false);
          }
      }
   
      for (var pair of data.entries()) {
        // console.log(pair[0]+ ', ' + pair[1]); 
      }
  }

  const verificarEdit=e=>{
    setDados({
      name: props.editInfo.a.name,
      graduation:props.editInfo.a.graduation,
      work_position: props.editInfo.a.work_position,
      position: props.editInfo.a.position,
    });

    for(var i=1;i<(props.newPosition);i++){
      vetPosition.push(i)
    }
  }

  React.useEffect(()=>{
    if(!dados.name){
      if(props.editInfo){
        verificarEdit();
      }
    }
    if(vetPosition.length==0){
      for(var i=1;i<(props.newPosition+1);i++){
        vetPosition.push(i)
      }
    }
  },[])
  return (
    <Conteudo>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" scroll={'body'}>
       <form onSubmit={submeterNovo}>
       <DialogTitle id="form-dialog-title">{props.editInfo? 'Editar Funcionário': 'Novo Funcionário'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {props.editInfo? 'Preencha os campos abaixo e confirme para aplicar a edição.':
           'Preencha os campos abaixo corretamente para que o novo funcionário seja inserido.'}
          </DialogContentText>
          <TextField
            value={dados.name}
            onChange={e=> setDados({...dados, name:e.target.value})}
            margin="dense"
            id="name"
            label="Nome"
            type="text"
            required
            fullWidth
            // disabled={props.editInfo? true: false}
          />
          <TextField
            value={dados.work_position}
            onChange={e=> setDados({...dados, work_position:(e.target.value).slice(0,50)})}
            margin="dense"
            id="função"
            label={`Função (${dados.work_position.length}/50)`}
            type="text"
            fullWidth
            multiline={false}
            required
            // variant='outlined'
            
          />
           <TextField
            value={dados.graduation}
            onChange={e=> setDados({...dados, graduation:(e.target.value).slice(0,255)})}
            margin="dense"
            id="name"
            label={`Descrição (${dados.graduation.length}/255)`}
            type="text"
            fullWidth
            multiline={true}
            rows={4}
            required
            variant='outlined'
            
          />
          <br/>
          <FormControl variant='outlined' className='' margin="dense" fullWidth>
                <InputLabel id="posicao" style={{backgroundColor:'#fff', padding:'0rem 0.5rem'}}>Selecionar Posição</InputLabel>
                <Select
                  labelId="posicao"
                  id="dposicao"
                  value={dados.position}
                  onChange={e=>setDados({...dados,position:e.target.value})}
                >
                  <MenuItem value="">
                    {/* <em>None</em> */}
                  </MenuItem>
                  {vetPosition.map((e,j)=> <MenuItem value={e}>{e}</MenuItem>)}
                </Select>
              </FormControl>
          <br/>
            <p style={{fontSize:"17.5px", color:"rgba(0,0,0,0.5)"}}>Selecione a foto do funcionário (1:1) : </p>
                <DropzoneArea
                  onChange={handleChange}
                  acceptedFiles={['image/jpeg', 'image/png', 'image/jpg']}
                  filesLimit={"1"}
                  dropzoneText="Arraste a imagem para este campo ou clique aqui para buscar"
                  maxFileSize="6000000"
                  getFileAddedMessage={(fileName)=>`Arquivo ${fileName} adicionado com sucesso.`}
                  getFileRemovedMessage={(fileName)=>`Arquivo ${fileName} removido.`}
                  getFileLimitExceedMessage={(filesLimit)=>`Número máximo de ${filesLimit} arquivos excedido.`}
                  getDropRejectMessage={()=>`Tipo de arquivo não permitido.`}
                  required
              />
          {props.editInfo? 
            <div style={{display:'flex', flexDirection:'column'}}>
              <br/>
              <p style={{fontSize:"14.5px", color:"rgba(0,0,0,0.5)", margin:'auto'}}>Imagem atual</p>
              <img src={dados.thumbnail_url? dados.thumbnail_url : props.editInfo.a.image_url} style={{maxWidth:"250px", margin:'auto'}}/>
            </div>: ''}
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary"  variant="contained">
            Cancelar
          </Button>
          <Button onClick={()=> submeterRascunho()}  variant="contained">
            Rascunho
          </Button>
          <Button type="submit" color="primary"  variant="contained">
          {props.editInfo?  (props.editInfo.a.publish? 'Aplicar Edição':'Publicar'):'Publicar'}
          </Button>
        </DialogActions>
       </form>
      </Dialog>
      {load? <Backdrop/>: ''}
      {alertas.status?
       <Snackbar open={true} autoHideDuration={6000} onClose={()=>setAlertas({...alertas, status:false, type:'', message:''})}>
       <Alert onClose={()=>setAlertas({...alertas, status:false, type:'', message:''})} severity={alertas.type}>
         {alertas.message}
       </Alert>
     </Snackbar>:""}
    </Conteudo>
  );
}