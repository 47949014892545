import React, { useReducer } from 'react';
import logo from './logo.svg';
import './App.css';
import {Conteudo} from './styles';
import API from './services/api';
import Backdrop from './components/Backdrop';
import {parseISO, addDays, addMinutes} from 'date-fns';

// React router dom 
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

import Login from './publico/Login';
import Logado from './logado';
import Backdropcomp from './components/Backdrop';
import Cookies from 'universal-cookie';

// Páginas do usuário
import Home from './logado/pages/Home';
import Banner from './logado/pages/Banners';
import Agenda from './logado/pages/Agenda';
import Albuns from './logado/pages/Albuns';
import Imagens from './logado/pages/Imagens';
import Blog from './logado/pages/Blog';
import Funcionarios from './logado/pages/Funcionarios';
import Relatorio from './logado/pages/Relatorio';
import Configuracoes from './logado/pages/Configuracoes';

// icone 
import HomeIcon from '@material-ui/icons/Home';

//Tema principal
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main:'rgba(0,83,81,1)',
    },
    secondary: {
        main: '#F44336'
    },
  },
});

function App() {

  const [aberto, setAberto] = React.useState(true);
  const [backdrop, setBackdrop] = React.useState(true);
  const cookies = new Cookies();

  const [user,setUser] = React.useState({
    // name:'Werlley Gonçalves',
    // email:'werlley.registros@hotmail.com',
    // token:'121fe45ere5fse2',
    name:'',
    email:'',
    token:'',
    roles:[],
  });

  const [permi,setPermi]=React.useState([]);

  const handleUser=e=>{
    // setUser({name:e.name, token: e.token, email:e.email, id:e.id});
    
    cookies.set('AAAsistemaadmin', e, { path: '/', expires: addDays(new Date(),6) });
    // console.log(e);
    if(e.name && !user.name) regras(e);
  }
  const logOff =e=>{
    setUser({name:"", token: "", email:"", id:"", roleId: ""});
    cookies.set('AAAsistemaadmin', "", { path: '/' });
    window.location.reload();
  }

  const regras =async e=>{
      // console.log("Permissao: ", e.permissions)
      const perm = JSON.parse(e.permissions);
      setUser({name:e.name, token: e.token, email:e.email, id:e.id, roles:perm});
      setPermi(perm);
      setBackdrop(false);


    // if(!backdrop) setBackdrop(true);
    // const config = {
    //   headers: {
    //     "Authorization": "Bearer " + e.token,
    //     "Content-Type": "application/json",
    //     "Access-Control-Allow-Origin": "http://localhost:3000",
    //     "Access-Control-Allow-Credentials": "true"
    //   }
    // };

    // await API.get(`roles/${e.roleId}`, config)
    // .then(a=>{
    //   setUser({name:e.name, token: e.token, email:e.email, id:e.id, roles:a.data.permission});
    //   setPermi(a.data.permission);
    //   setBackdrop(false);
    // }).catch(error=>{
    //   console.log(error.response);
    // });
      
  }

  const callBackdrop=e=>{
    setInterval(function() {
        setBackdrop(false);
    }, 1000)
  }
  React.useEffect(a=>{
      if(cookies.get('AAAsistemaadmin')){
        const e = cookies.get('AAAsistemaadmin');
        // setUser({name:e.name, token: e.token, email:e.email, id:e.id});
        if(e.name && !user.name) regras(e);
      }
      if(backdrop) callBackdrop();
      console.log('Data: ', (new Date()).setMinutes((new Date()).getMinutes()+1));
  },[])

//filtros
const ftbanner=obj=>{
  if (('slug' in obj)) {
    return true;
  } else {
    return false;
  }
}
const ftpost=obj=>{
  if ((('slug' in obj))) {
    return true;
  } else {
    return false;
  }
}
const ftevento=obj=>{
  if ((('slug' in obj))) {
    return true;
  } else {
    return false;
  }
}
const ftfuncionario=obj=>{
  if ((('slug' in obj))) {
    return true;
  } else {
    return false;
  }
}
const ftalbum=obj=>{
  if ((('slug' in obj))) {
    return true;
  } else {
    return false;
  }
}
const ftrelatorio=obj=>{
  if ((('slug' in obj))) {
    return true;
  } else {
    return false;
  }
}
const ftusuario=obj=>{
  if ('id' in obj && obj.id == 22) {
    return true;
  } else {
    return false;
  }
}


const Breadcrumbs = ({ match }) => (
  <span>
      <Link to={match.url || ''} className={match.isExact ? 'breadcrumb active' : 'breadcrumb'}>
          {match.url.substr(match.url.lastIndexOf('/')+1, match.url.length)}
      </Link>
      <Route path={`${match.url}/:path`} component={Breadcrumbs} />
  </span>
)
  return (
    <div className="App">
      <Router>
          {(user.token && permi.length>0)?
       
          <div style={{paddingTop:'5rem', paddingLeft:'5rem', paddingRight:'1rem'}}>
              {/* {console.log(permi)} */}
               {/* {console.log(permi.filter(ftbanner))} */}
            {/* <Breadcrumbs/>sadadadasdasda */}
            <Route path="/:id2" children={<Logado user={user} logOff={e=>logOff()} abrir={()=>setAberto(!aberto)}/>} />
           <div style={{
             marginLeft: window.innerWidth> 675? aberto? "11rem":"0rem" : '',
             transition: '1s',
           }}>
              <ThemeProvider theme={defaultMaterialTheme}>
            <Conteudo>
              <div className="container-fluid breadcrumbs">
              <Link to='/' className='breadcrumb'>
                <HomeIcon style={{width:'16px', marginBottom:'0.2rem'}}/>
              </Link>
                <Route path='/:path' component={Breadcrumbs} />
              </div>
            </Conteudo>
           <Switch>
                {/* <Route path="/" exact={true} component={Home} /> */}
                <Route exact path="/">
                  <Redirect to="/banners"/>
                </Route>
                <Route path="/banners" children={(permi.filter(ftbanner).length>0)? <Banner user={user}/>:''} />
                <Route path="/agenda" children={(permi.filter(ftevento).length>0)?<Agenda user={user}/>:''} />
                <Route exact path="/albuns" children={(permi.filter(ftalbum).length>0)?<Albuns user={user}/>:''} />
                <Route exact path="/albuns/:id" children={(permi.filter(ftalbum).length>0)?<Imagens user={user}/>:''} />
                <Route exact path="/blog" children={(permi.filter(ftpost).length>0)?<Blog user={user}/>:''} />
                <Route exact path="/funcionarios" children={(permi.filter(ftfuncionario).length>0)?<Funcionarios user={user}/>:''} />
                <Route exact path="/relatorio" children={(permi.filter(ftrelatorio).length>0)?<Relatorio user={user}/>:''} />
                <Route exact path="/configuracoes" children={<Configuracoes user={user}/>} />
                {/* <Route exact path="/blog/:id" children={<Blog user={user}/>} /> */}
                {/* <Route path="/servicos" exact={true} component={Servicos} />
                <Route path="/galeria" component={Galeria} />
                <Route path="/agenda" component={Agenda} />
                <Route path="/inscricao/:id" children={<Curso/>}/>
                <Route exact path="/inscricao">
                  <Redirect to="/agenda"/>
                </Route>
                <Route path="/agilacademy" component={Agilacademy} />
                <Route path="/blog/:id" children={<Blog/>}/>
                <Route exact path="/blog" children={<Blog/>}/>
                <Route path="/documentos/:id" children={<Documentos/>}/> */}
                {/* <Route exact path='*' component={My404} /> */}
            </Switch>
            </ThemeProvider>
           </div>
          </div>
          :<Login setUser={e=> handleUser(e)}/>}
         
      </Router>
        {backdrop? <Backdropcomp/> : ''}
    </div>
  );
}

const fakeAuth = {
  isAuthenticated: false,
  authenticate(cb) {
    fakeAuth.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};


function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        fakeAuth.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default App;
