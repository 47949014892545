import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArchiveIcon from '@material-ui/icons/Archive';
import CreateIcon from '@material-ui/icons/Create';
import {Albunscard} from './styles';
import {Link} from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import API from '../../services/api';
import {CardDeck} from 'react-bootstrap';
import {Options} from './styles';
import Albunsupload from '../Albunsupload';
import Dialogodeconfirmacao from '../Dialogosdeconfirmacao';

// Datas e horas
import Moment from 'react-moment';


const useStyles = makeStyles({
  root: {
    maxWidth: 235,
    margin: '0.5rem',
  },
  rootrascunho:{
    maxWidth: 235,
    margin: '0.5rem',
    opacity: 0.8,
    ['&:hover']:{
      opacity:1,
    }
  }
});

export default function Albunslist(props) {
  const classes = useStyles();
  const [images,setImages] = useState([]);
  const [show, setShow] = useState(false);
  const [confirmacao, setConfirmacao] = useState([false,'']);
  const [editarlink, setEditarlink] = useState({
    status: false,
  });
  const [back, setBack] = React.useState(true);
  const [upload,setUpload] = useState(false);

  const carregamento =async e=>{

    const config = {
      headers: {
        "Authorization": "Bearer " + props.user.token,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true"
      }
    };

    await API.get('private_albums', config)
    .then(a=>{
        setImages(a.data);   
        setBack(false);
        // console.log(a);
    }).catch(error=>{
      console.log(error.response.data.error);
    });

    setShow(true);
    // console.log(images);
  }

  React.useEffect(()=>{
      if(images.length==0){ carregamento();}
  },[]);

const abrirDialogo=e=>{
  setUpload(true);
}
const fecharDialogo=e=>{
  setUpload(false);
  setEditarlink({
    status: false, 
  });
}
const deleteImage=e=>{
  const config = {
    headers: {
      'Authorization': 'Bearer ' + props.user.token,
    }
  };
  API.delete(`albums/${e}`, config)
  .then(a=>{
      // window.location.reload(); 
      carregamento();
      setConfirmacao([false,'']);
  }).catch(error=>{
    console.log(error.response);
  });

}

  // filtros
  const ftCriar=obj=>{
    if (('slug' in obj && obj.slug =='create_album')) {
      return true;
    } else {
      return false;
    }
  }
  const ftEditar=obj=>{
    if (('slug' in obj && obj.slug =='edit_album')) {
      return true;
    } else {
      return false;
    }
  }
  const ftDeletar=obj=>{
    if (('slug' in obj && obj.slug =='delete_album')) {
      return true;
    } else {
      return false;
    }
  }
  const ftListar=obj=>{
    if (('id' in obj)) {
    // if (('id' in obj && obj.id == 31)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div >
    <CardDeck>
    {(props.user.roles.filter(ftListar).length>0)?
    images.map((a,i)=>(
        <Card className={a.publish? classes.root:classes.rootrascunho} key={i} style={{backgroundColor: a.publish? '':'rgba(255,237,0,.5)', borderRadius:'0px'}}>
         <CardActionArea>
         <Link to={`albuns/${a.id}`}>
           <CardMedia
             component="img"
             alt={a.title}
             height="150"
             image={a.thumbnail? a.thumbnail_url : a.photos[0].image_url}
            //  title={a.title}
            title={"Clique para adicionar mais imagens"}
           />
           </Link>
         </CardActionArea>
           <Albunscard>
              <div className="textoList">
                <div className="nameList">   
                  {a.title}
                  </div>
                {/* <div className="descList">{(a.description).substr(0,70)} (...)</div> */}
              </div>
              <div className="dataList" style={{fontSize:'12px', backgroundColor:a.publish? 'rgba(0,83,81,1)':'rgba(255,237,0,.2)', color: a.publish? "#fff":'rgba(150,75,0,1)' , padding:"0.2rem"}}>
                <Moment format="DD" className="diaList">{a.date}</Moment>
                <Moment format="MM/YYYY" className="mesanoList">{a.date}</Moment>
              </div>
              </Albunscard>
         {/* <div style={{fontSize:'12px', backgroundColor:'rgba(0,83,81,1)', color:"#fff", padding:"0.2rem"}}>LINK: {a.link? a.link : "Sem link"} <CreateIcon style={{fontSize:'18px', cursor:"pointer"}} onClick={()=> {setEditarlink({status:true, a});abrirDialogo()}}/></div> */}
         <CardActions>
          <Options >
          <div style={{marginTop:'0.3rem', opacity:0.6}}>
              Opções:
          </div>
          <div>
           {(props.user.roles.filter(ftEditar).length>0)?
                <Tooltip title="Editar">
                  <IconButton size="small" color="primary"  onClick={()=> {setEditarlink({status:true, a});abrirDialogo()}}>
                      <CreateIcon style={{fontSize:'22px'}}/> 
                  </IconButton>
                </Tooltip>
            :''}
            {(props.user.roles.filter(ftDeletar).length>0)?
            <Tooltip title="Deletar">
              <IconButton size="small" color="secondary" onClick={()=>setConfirmacao([true,a.id])}>
                <DeleteIcon/> 
              </IconButton>
           </Tooltip>
           :''}
           </div>
           </Options>
         </CardActions>
        </Card>
    )):''}   
      {(props.user.roles.filter(ftCriar).length>0)?
        <Card className={classes.root} style={{display:'flex'}} onClick={abrirDialogo}>
         <CardActionArea style={{padding:'1rem'}}>
             <AddCircleIcon style={{fontSize:'40px'}}/>
            <Typography variant="h6" component="h3">
                Adicionar <br/>novo álbum
            </Typography>
         </CardActionArea>
        </Card>
        :''}
    </CardDeck>   

    {upload? <Albunsupload editInfo={editarlink.status? editarlink.a:''} fecharDialogo={()=>{fecharDialogo();carregamento()}} user={props.user} newPosition={images.length+1}/> : ''}
    {confirmacao[0]?
    <Dialogodeconfirmacao
      tamanho="xs"
      message="Tem certeza de que deseja apagar este álbum?"
      confirmar={()=>deleteImage(confirmacao[1])}
      negar={()=>setConfirmacao([false,''])}
    />:""}
    <Backdrop open={back} style={{zIndex:1002}}>
        <CircularProgress style={{color:"#ffffff"}} />
    </Backdrop>
    </div>
  );
}