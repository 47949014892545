import React, {useMemo} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';

import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

//campos de texto
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import {Conteudo} from './styles';
import API from '../../services/api';

// checkbox 
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

//campo de data
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// editor de texto
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//Seletor auto completável
import Backdrop from '../Backdrop';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


// chips
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';


// alertas
import { parseISO, isAfter } from 'date-fns';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
  },
  editText:{
    border:"1px solid rgba(0,0,0,0.1)",
    borderRadius:'3px',
    padding: '0.6rem',

  },
  subAreas:{
    border: '2px dashed rgba(0,0,0,0.2)',
  },
  chip: {
    margin: theme.spacing(0.5),
    padding: '0rem 0rem',
    // backgroundColor:'rgba(255,255,100,0.5)',
    
  },
  root2: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    boxShadow:'0px 0px 0px rgba(0,0,0,0)',
    border:0,
  },
  typography: {
    padding: theme.spacing(2),
  },
  estiloPopover:{
    ['& .MuiPopover-paper']:{
      width:'300px'
    }
  }
}));



export default function Funcoesedit(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [listementa, setListementa] = React.useState([]);
  const [dados, setDados] = React.useState({
     name:'',
     email:'',
     id:'',
     roles:''
  });
  // alertas
  const [alertas, setAlertas] = React.useState({
    status: false,
    type:'',
    message:""
  });
  const  [load, setLoad] = React.useState(false);

  const [listpermission, setListpermission] = React.useState([]);

  const [novaFuncao, setNovaFuncao] = React.useState({
    name:'',
    description:'',
    permissions:[],
  });

  // popover 
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
    setNovaFuncao({name:'', description:'', permissions:[]})
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? 'simple-popover' : undefined;
  // fim do popover 

  const handleChangeListPermission = (event) => {

    
    var listtempo = listpermission;
    if((event.target.checked==false) && (listpermission.filter(e=> {if(e.status){return true}else{ return false}})).length==1){
      setAlertas({...alertas, status:true, type:'warning', message:'Pelo menos uma permissão deve ser associada a função.'});
      setLoad(false);
    }else{
      listtempo[event.target.value].status = event.target.checked ;
      setListpermission([...listpermission]);
      submeterAtualizacao(event);
    }
   
    // console.log(listpermission);
  };

  const [textdescription, setTextdescription] = React.useState({
    description: EditorState.createEmpty(),
  });
  // editor de texto 
  const onEditorStateChange = (editorState) => {
    setTextdescription({
      ...textdescription,
      description: editorState,
    });
    setDados({
      ...dados,
      description: draftToHtml(convertToRaw(textdescription.description.getCurrentContent())),
    })
  };
  // fim do editor 

  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    props.fecharDialogo();
  };


  const submeterNovo= e=>{
    e.preventDefault();
    // console.log(dados);
    setLoad(true);
    const config = {
      headers: {
        'Authorization': 'Bearer ' + props.user.token,
      }
    };
      var subdados={
        name: novaFuncao.name,
        description: novaFuncao.description,
        permissions: novaFuncao.permissions,
      };

      if(novaFuncao.name.length>4){
        if(novaFuncao.description.length>0){
          
              API.post(`roles`, subdados, config)
              .then(a=>{
                // window.location.reload();
                atualizarListaEmenta();
                setAlertas({...alertas, status:true, type:'success', message:'Função adicionada com sucesso.'});
                handleClosePopover();
                setLoad(false);
              }).catch(error=>{
                console.log(error.response);
              });     
        
        }else{
         setAlertas({...alertas, status:true, type:'error', message:'A descrição da função é um campo obrigatório.'});
         setLoad(false);
        }
      }else{
        setAlertas({...alertas, status:true, type:'error', message:'O nome da nova função deve ser preenchido com no mínimo 5 caracteres.'});
        setLoad(false);
      } 

    
  }

  const submeterAtualizacao= e=>{
    // console.log(dados);
    setLoad(true);
    const config = {
      headers: {
        'Authorization': 'Bearer ' + props.user.token,
      }
    };
      var subdados = {
        permissions: [listpermission[e.target.value].id],
      };

      if((e.target.checked==false) && (listpermission.filter(e=> {if(e.status){return true}else{ return false}})).length==0){
        setAlertas({...alertas, status:true, type:'warning', message:'Pelo menos uma permissão deve ser associada a função.'});
        setLoad(false);
      }else{
        if(dados.roles.name){
          if(e.target.checked){
              API.post(`roles/${dados.roles.id}/permissions`, subdados, config)
              .then(a=>{
                // window.location.reload();
                // props.fecharDialogo();
                setLoad(false);
              }).catch(error=>{
                console.log(error.response);
              }); 
          }else{
              API.delete(`roles/${dados.roles.id}/permissions/${listpermission[e.target.value].id}`, config)
                .then(a=>{
                  // window.location.reload();
                  // props.fecharDialogo();
                  setLoad(false);
                }).catch(error=>{
                  console.log(error.response);
                }); 
          }
        }else{
          setAlertas({...alertas, status:true, type:'warning', message:'Função não selecionada.'});
          setLoad(false);
        }    

      }

  }

  const atualizarListaEmenta=e=>{
    const config = {
      headers: {
        "Authorization": "Bearer " + props.user.token,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Credentials": "true"
      }
    };

    API.get('roles', config)
    .then(a=>{
        setListementa(a.data);
    }).catch(error=>{
      console.log(error.response);
    });
    // console.log(listementa);
  }

  const atualizarListaPermissoes =async e=>{
    const config = {
      headers: {
        "Authorization": "Bearer " + props.user.token,
        "Content-Type": "application/json",
      }
    };
    var listatemp = [];
    
    await API.get('permissions', config)
    .then(a=>{
        // setListpermission(a.data);
        a.data.map((e,i)=>(
          listatemp.push({
            status: false,
            id: e.id,
            name: e.name,
            description: e.description,
          })
        ));
    }).catch(error=>{
      console.log(error.response.data.error);
    });

    if(listatemp.length>0)  setListpermission(listatemp);
  }

  React.useEffect(a=>{
    if(listementa.length==0) atualizarListaEmenta();
    if(props.info){
        if(!dados.name) edicaoCampos();
        if(listpermission.length==0) atualizarListaPermissoes();
    }
  },[])

  const edicaoCampos =e=>{
   setDados({
     ...dados,
     name: props.info.name,
     email: props.info.email,
     id: props.info.id,
    //  roles: props.info.role[0]   
   });
  }

  const autoPreenchePermissoes=e=>{

    var listtempo = listpermission;
    var controle = false;

  listpermission.map((h,j)=>{ 
     e.map((a,i)=>{  
         if(a.id==h.id){
          listtempo[j].status = true;
          controle=true;
         }
      });
      if(!controle) listtempo[j].status = false;
      controle=false;
    });
    setListpermission([...listpermission]);
  }

  return (
    <Conteudo>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" scroll={'body'}  maxWidth='md' style={{minWidth:'700px'}}>
       <form>
       <DialogTitle id="form-dialog-title">{props.info? 'Gerenciar Funções':'Nova Função'}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
           Preencha os campos abaixo corretamente para que a conta seja {props.info?'atualizado':'inserido'}.
          </DialogContentText> */}
          <br/>
          <div className={''} style={{padding:'0.5rem', marginTop:'-2rem'}}>
        <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
            <Autocomplete
              id="combo-box-demo"
              // defaultValue={props.info? props.info.role[0].name:''}
              value={dados.roles}
              onChange={(event, newValue) => {
                if(newValue){
                  setDados({...dados, roles: newValue});
                  autoPreenchePermissoes(newValue.permission);
                }else{ setDados({...dados, roles:''});}
              }}
      
              options={listementa}
              getOptionLabel={(option) => option.name}
              style={{flex:'1', margin:'0rem 0.4rem'}}
              renderInput={(params) => <TextField {...params}  label="Selecionar função existente"/>}
            />
             <Button aria-describedby={idPopover} variant="contained" style={{flex:'0.3',margin:'0.2rem 0rem', fontSize:'12px'}} color="primary" onClick={handleClickPopover}>
                Nova Função
             </Button>
        </div>
        
        <br/>
          {/* check box  */}
          <FormControl component="fieldset" className={classes.formControl} >
            <FormLabel component="legend">Permissões</FormLabel>
            <FormGroup>
              {listpermission.length>0? listpermission.map((a,i)=>(
                <FormControlLabel
                  control={<Checkbox checked={a.status} onChange={e=> {handleChangeListPermission(e)}} name={a.name} value={i} disabled={(dados.roles.name==('Admin' || 'admin')) || (dados.roles=='')? true:false}/>}
                  label={<div style={{maxWidth:'600px',lineHeight:'1rem'}}>{a.name} - <small style={{color:'rgba(0,0,0,0.5)'}}>{a.description}</small></div>}
                  style={{margin:'0rem', boxShadow:'0px 0px 2px rgba(0,0,0,0.4)', width:'100%'}}
                />
              )):''}
            </FormGroup>
            <FormHelperText>Ao selecionar, as permissões são automaticamente atualizadas</FormHelperText>
          </FormControl>
          </div>
    
         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary"  variant="contained">
            Voltar
          </Button>
          {/* <Button type="submit" color="primary">
            {props.info?'Atualizar': 'Enviar'}
          </Button> */}
        </DialogActions>
       </form>
      </Dialog>
      {load? <Backdrop/>: ''}
      {alertas.status?
       <Snackbar open={true} autoHideDuration={6000} onClose={()=>setAlertas({...alertas, status:false, type:'', message:''})}>
       <Alert onClose={()=>setAlertas({...alertas, status:false, type:'', message:''})} severity={alertas.type}>
         {alertas.message}
       </Alert>
     </Snackbar>:""}
     {/* popover */}
     <Popover
        id={idPopover}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.estiloPopover}
      >
        <form style={{display:'flex',flexDirection:'column', width:'100%'}} onSubmit={submeterNovo}> 
          <TextField
            id="nomedanovafuncao"
            label="Nome da Função"
            style={{ margin:'0rem 0.8rem', marginTop:'1.5rem', flex:'1' }}
            // placeholder="Placeholder"
            // helperText="Full width!"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={novaFuncao.name}
            onChange={e=> setNovaFuncao({...novaFuncao, name:e.target.value})}
            required
          />
           <TextField
            id="descricaodanovafuncao"
            label="Descrição curta da nova função"
            style={{ margin:'1rem 0.8rem', flex:'1' }}
            // placeholder="Placeholder"
            // helperText="Full width!"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            multiline
            rows={3}
            value={novaFuncao.description}
            onChange={e=> setNovaFuncao({...novaFuncao, description:e.target.value})}
            required
          />
           <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<AddCircleIcon />}
              type='submit'
            >
              Adicionar
            </Button>
        </form>
      </Popover>
    </Conteudo>
  );
}