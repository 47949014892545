import styled from 'styled-components';

export const Conteudo = styled.div`
    
    .botaoParaBaixarComprovante{
        text-align: center;

        button{
            background-color: #ddd;
            padding: 0.5rem 1rem;
            margin: 1rem 0rem;
        }
    }
`;

export const CampoDeComentarios = styled.div`

        margin: 1rem 1rem !important;
        .comentHeader{
            /* border-bottom: 1px solid rgba(0,0,0,0.2); */
            cursor: pointer;
            
            h5{
                margin: 0.25rem;
                display:flex;
                flex-direction: row;
            }
        }
        .baloes{
            visibility: ${props=>props.showComents? "visible":"hidden"};
            display: ${props=>props.showComents? "flex":"none"};
            /* display: flex; */
            flex-direction: column;

            .balao{
                margin-bottom: 1rem;
                background:#cccccc; 
                padding:15px; 
                position:relative; 
                top:20px; 
                left:3%; 
                width:80%; 
                /* float:left;  */
               justify-content: flex-start;


                .balaoNome{
                    font-weight: bold;
                }
                .balaoText{
                    font-size: 14px;
                    text-align: justify;
                }
                .balaoData{
                    font-size: 11px;
                    padding: 0.25rem;
                }
            }
            .balao:before{ 
                content:''; 
                position: absolute; 
                width:20px; height:20px; 
                left:-10px; top:25px; 
                background:#cccccc; 
                -webkit-transform:rotate(45deg); 
            }

            .balao2{
                margin-bottom: 1rem;
                background:#cccccc; 
                padding:15px; 
                position:relative; 
                top:20px; 
                right:3%; 
                width:80%; 
                float:right; 
                /* display: flex;
                flex-direction: column; */
                justify-content: flex-end;

                .balaoNome{
                    font-weight: bold;
                    text-align: right;
                    
                    svg{
                        margin-top: -0.4rem;
                        font-size: 18px;
                        cursor:pointer;
                    }
                    svg:hover{
                        margin-top: -0.4rem;
                        font-size: 18px;
                        color: #F44336;
                        cursor:pointer;
                    }
                }
                .balaoText{
                    font-size: 14px;
                    text-align: justify;
                }
                .balaoData{
                    /* text-align: right; */
                    font-size: 11px;
                    padding: 0.25rem;
                }
            }
            .balao2:before{ 
                content:''; 
                position: absolute; 
                width:20px; height:20px; 
                right:-10px; top:25px; 
                background:#cccccc; 
                -webkit-transform:rotate(45deg); 
            }

            .nenhumEncontrado{
                display: flex;
                flex-direction: row;
                justify-content: center;
                justify-items:center;
                padding: 3rem 1rem;
            }
           
        }
        .iconAqui{
            transform: ${(props) => props.showComents ? 'rotate(90deg)  translateY(+10%)' : ''};
            /* transition: 0.3s !important; */
            /* transition-timing-function: ease-in !important; */
        }
       
`;

export const NovoComentario = styled.div`
    position: relative;
    bottom: 0;
    padding: 0.5rem 1rem !important;
    display:flex;
    flex-direction: column;

    label{
        font-size: 14px;
    }

    button{
        font-size: 14px;
        border: 0px;    
        border-radius: 5px;
        background-color: #005351;
        color: rgba(255,255,255);
        padding: 0.5rem 0.75rem;
    }
    button:hover{
        opacity: 0.8;
    }
    .campoDeTexto{
        width: 100%;
        padding: 0.75rem 1rem;
        border-radius: 5px;
        min-height: 100px;
    }

`