import React from 'react';
import { Container } from './styles';

import Tituloh3 from '../../../components/Tituloh3';
import Albunslist from '../../../components/Albunslist';

function Albuns(props) {
  return (
  <Container style={{maxWidth:'1920px', margin:'auto'}}>
        <Tituloh3 
          text="Álbuns"
          description={<>
          Nesta página é possível visualizar, excluir e adicionar os álbuns presentes na galeria do site.<br/>
          <b>Legenda: </b>
            <label style={{padding:'0.2rem',backgroundColor:'rgba(0,83,81,1)', color:'rgba(255,255,255)', borderRadius:'5px', fontSize:'12px'}}>
              Publicado </label>{' '}
            <label style={{padding:'0.2rem',backgroundColor:'rgba(255,237,0,.5)', color:'rgba(150,75,0,1)', borderRadius:'5px', fontSize:'12px'}}>
              Rascunho </label>  
          </>}
        />
        <Albunslist user={props.user}/>
  </Container>
    );
}

export default Albuns;