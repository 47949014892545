import React from 'react';
import { Container } from 'react-bootstrap';
import  {Conteudo} from './styles';

import API from '../../services/api';

// alertas 
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Login(props) {

    // let history = useHistory();
    // let location = useLocation();

    // let { from } = location.state || { from: { pathname: "/" } };
    // let login = () => {
    // fakeAuth.authenticate(() => {
    //     history.replace(from);
    // });
    // };

    const [alertas, setAlertas] = React.useState({
        status: false,
        type:'',
        message:""
      });

    const [dados,setDados] = React.useState({
        name:'',
        token:'',
        email:'',
        password:'',
    });

    const enviarForm=e=>{
        e.preventDefault();

        const dd = {
            email: dados.email,
            password: dados.password,
        }
        API.post('session',dd)
        .then(e=>{
            // console.log(e.data);
            setDados({
                name: e.data.name,
                email: e.data.email,
                token: e.data.token,
                id: e.data.id,
                roleId: e.data.role.id,
                permissions: JSON.stringify(e.data.role.permission)
            });
            props.setUser({
                name: e.data.name,
                email: e.data.email,
                token: e.data.token,
                id: e.data.id,
                roleId: e.data.role.id,
                permissions: JSON.stringify(e.data.role.permission)
            });
            // console.log('Permissoes: ',e.data.role.permission);
        }).catch(error=>{
            console.log(error.response);
            setAlertas({...alertas, status: true, type:"error", message:error.response.data.error});
        })
       
        if(dados.email && dados.name && dados.token){
            props.setUser(dados);
        }
    }

  return (
      <Container fluid className="m-0 p-0">
          <Conteudo>
              <form onSubmit={enviarForm}>
              <div className="umaCol">
                  <img src="files/images/login/image001.png"/>
                  <h5>Sistema Administrativo</h5>
                        <label>E-mail</label>
                        <input type="text" value={dados.email} onChange={e=>setDados({...dados, email: e.target.value})}/>
                        <label>Senha</label>
                        <input type="password" value={dados.password} onChange={e=> setDados({...dados, password: e.target.value})}/>
                  <button type="submit">Login</button>
              </div>
              </form>
          </Conteudo>
          {alertas.status?
            <Snackbar open={true} autoHideDuration={6000} onClose={()=>setAlertas({...alertas, status:false, type:'', message:''})}>
            <Alert onClose={()=>setAlertas({...alertas, status:false, type:'', message:''})} severity={alertas.type}>
                {alertas.message}
            </Alert>
        </Snackbar>:""}

      </Container>
  );
}

export default Login;