import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArchiveIcon from '@material-ui/icons/Archive';
import CreateIcon from '@material-ui/icons/Create';

import API from '../../services/api';
import {CardDeck} from 'react-bootstrap';
import {Options} from './styles';
import Bannersupload from '../Bannersupload';
import Dialogodeconfirmacao from '../Dialogosdeconfirmacao';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: '0.5rem',
  
  },
  rootrascunho:{
    maxWidth: 345,
    margin: '0.5rem',
    opacity: 0.8,
    ['&:hover']:{
      opacity:1,
    }
  }
});

export default function Bannerimagens(props) {
  const classes = useStyles();
  const [images,setImages] = useState([]);
  const [show, setShow] = useState(false);
  const [confirmacao, setConfirmacao] = useState([false,'']);
  const [editarlink, setEditarlink] = useState({
    status: false,
  });
  const [back, setBack] = React.useState(true);

  const [upload,setUpload] = useState(false);

  const carregamento =async e=>{
    const config = {
      headers: {
        'Authorization': 'Bearer ' + props.user.token,
      }
    };

    await API.get('private_banners', config)
    .then(a=>{
        setImages(a.data);
        setBack(false);
        // console.log(a.data);
    });

    setShow(true);
    // console.log(images);
  }

  React.useEffect(()=>{
      if(images.length===0){ carregamento();}
  },[]);

const abrirDialogo=e=>{
  setUpload(true);
}
const fecharDialogo=e=>{
  setUpload(false);
  setEditarlink({
    ...editarlink, 
    status: false, 
    id:"",
    link: "",
    name:"",
    publish: true, 
    image_url:""
  });
}
const deleteImage=e=>{
  const config = {
    headers: {
      'Authorization': 'Bearer ' + props.user.token,
    }
  };
  API.delete(`banners/${e}`, config)
  .then(a=>{
      // window.location.reload();
      setTimeout(e=>{
        carregamento();
      }, 1000)
      // console.log(a.data); 
  }).catch(error=>{
    console.log(error.response);
  });

}

const atualizar=e=>{
  const config = {
    headers: {
      'Authorization': 'Bearer ' + props.user.token,
    }
  };

  API.get('private_banners',config)
  .then(a=>{
    // console.log(a.data);
      a.data.map((a,i)=>(
          images.push({
              id: a.id,
              name: a.name,
              image_url: a.image_url,
              width: 4,
              height: 2   
          })
      ));
    
  });
  setShow(true);
}

// filtros
const ftCriar=obj=>{
  if (('slug' in obj && obj.slug =='create_banners')) {
    return true;
  } else {
    return false;
  }
}
const ftListar=obj=>{
  if (('id' in obj)) {
  // if (('id' in obj)) {
    return true;
  } else {
    return false;
  }
}
const ftEditar=obj=>{
  if (('slug' in obj && obj.slug =='edit_banners')) {
    return true;
  } else {
    return false;
  }
}
const ftExcluir=obj=>{
  if (('slug' in obj && obj.slug =='delete_banners')) {
    return true;
  } else {
    return false;
  }
}



  return (
    <div >
    <CardDeck>
      {(props.user.roles.filter(ftListar).length>0)? images.map((a,i)=>(
        <Card className={a.publish? classes.root:classes.rootrascunho} key={i} style={{backgroundColor: a.publish? '':'rgba(255,237,0,.5)', borderRadius:'0px'}}>
         <CardActionArea>
           <CardMedia
             component="img"
             alt={a.name}
             height="140"
             image={a.image_url}
             title={a.name}
           />
         </CardActionArea>
         <div style={{fontSize:'12px', backgroundColor:a.publish? 'rgba(0,83,81,1)':'rgba(255,237,0,.2)', color: a.publish? "#fff":'rgba(150,75,0,1)' , padding:"0.2rem"}}>
           LINK: {a.link? a.link : "Sem link"} 
           
         </div>
         <CardActions>
          <Options >
            <div style={{marginTop:'0.3rem', opacity:0.6}}>
                Opções:
            </div>
            <div>
                {(props.user.roles.filter(ftEditar).length>0)?
                  <Tooltip title="Editar">
                    <IconButton size={'small'} color="primary" onClick={()=> {setEditarlink({status:true, a});abrirDialogo()}}>
                        <CreateIcon style={{fontSize:'22px'}} />
                    </IconButton>
                  </Tooltip>
              :''}
              <a href={a.image_url} target="_blank">
                  <Tooltip title="Download">
                    <IconButton size="small" color="primary">
                        <ArchiveIcon/>
                    </IconButton>
                  </Tooltip>
                </a>
                {(props.user.roles.filter(ftExcluir).length>0)?
                <Tooltip title="Deletar">
                  <IconButton size="small" color="secondary" onClick={()=>setConfirmacao([true,a.id])}>
                    <DeleteIcon/>
                  </IconButton>
                </Tooltip>
              :''}
            </div>
           </Options>
         </CardActions>
        </Card>
    )): ''}   
    {(props.user.roles.filter(ftCriar).length>0)? 
      <Card className={classes.root} style={{display:'flex'}} onClick={abrirDialogo}>
        <CardActionArea style={{padding:'1rem'}}>
            <AddCircleIcon style={{fontSize:'40px'}}/>
          <Typography variant="h6" component="h3">
              Adicionar <br/>nova imagem
          </Typography>
        </CardActionArea>
      </Card>
   :''}
    
    </CardDeck>   

    {upload? <Bannersupload editInfo={editarlink.status? editarlink:''} fecharDialogo={()=>{fecharDialogo(); carregamento();}} user={props.user} newPosition={images.length+1}/> : ''}
    {confirmacao[0]?
    <Dialogodeconfirmacao
      tamanho="xs"
      message="Tem certeza de que deseja apagar esta imagem?"
      confirmar={()=>{deleteImage(confirmacao[1]);setConfirmacao([false,''])}}
      negar={()=>setConfirmacao([false,''])}
    />:""}
    <Backdrop open={back} style={{zIndex:1002}}>
        <CircularProgress style={{color:"#ffffff"}} />
    </Backdrop>
    </div>
  );
}