import React, {useState} from 'react';
import { Container } from './styles';

import Tituloh3 from '../../../components/Tituloh3';

import Blogcards from '../../../components/Blogcards';


function Agenda(props) {


  return (
  <Container >
        <Tituloh3 
          text="Blog"
          description="Nesta página é possível visualizar, excluir e adicionar os postagens ao blog do site."
        />
        <br/>
       
        {/* Lista de eventos  */}
        <Blogcards user={props.user}/>

  </Container>
    );
}

export default Agenda;