import React,{useState} from 'react';
import { Container } from './styles';

import Tituloh3 from '../../../components/Tituloh3';
import Funcionarioscards from '../../../components/Funcionarioscards';
import {Button} from '@material-ui/core';
import Funcionarionew from '../../../components/Funcionarionew';


function Funcionarios(props) {
  const [newfunc, setNewfunc] = useState(false);

  // filtros 
  const ftCriar=obj=>{
    if (('slug' in obj && obj.slug =='create_staff')) {
      return true;
    } else {
      return false;
    }
  }

  return (
  <Container style={{maxWidth:'1920px', margin:'auto'}}>
        <Tituloh3 
          text="Funcionários"
          description={<>Nesta página é possível visualizar, excluir e adicionar os funcionários presentes na página sobre nós do site.<br/>
           <b>Legenda: </b>
            <label style={{padding:'0.2rem',backgroundColor:'rgba(0,83,81,1)', color:'rgba(255,255,255)', borderRadius:'5px', fontSize:'12px'}}>
            Publicado </label>{' '}
            <label style={{padding:'0.2rem',backgroundColor:'rgba(255,237,0,.5)', color:'rgba(150,75,0,1)', borderRadius:'5px', fontSize:'12px'}}>
              Rascunho </label>  
          </>}
        />
         <br/>
        {/* Novo evento  */}
     
        <Funcionarioscards user={props.user}/>
        {newfunc?
          <Funcionarionew user={props.user} fecharDialogo={()=> setNewfunc(false)}/>
        :''}
  </Container>
    );
}

export default Funcionarios;