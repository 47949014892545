import React,{useState, useEffect} from 'react';
import { Conteudo } from './styles';
import API from '../../services/api';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import Configuracoesnew from '../Configuracoesnewmeu';
import Configuracoessenha from '../Configuracoessenhameu';

// chips
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
      boxShadow:'0px 0px 0px rgba(0,0,0,0)',
      border:0,
    },
    chip: {
      margin: theme.spacing(0.5),
      padding: '0rem 0rem',
      backgroundColor:'rgba(255,255,100,0.5)',
    },
  }));

function Configuracoesmeu(props) {
    const classes = useStyles();
    const [minhaconta, setMinhaconta] = useState();
    const [editar,setEditar] = useState(false);
    const [editsenha, setEditsenha] = useState(false);
    const carregamento =async e=>{
        const config = {
            headers: {
              'Authorization': 'Bearer ' + props.user.token,
            }
          };

        await API.get(`selfuser/${props.user.id}`, config)
        .then(a=>{
            setMinhaconta(a.data);
            // console.log(a);
        });

        // console.log(images);
      }
      useEffect(e=>{
        if(!minhaconta) carregamento();
      },[]);

      const ftEditar=obj=>{
        // if (('id' in obj && obj.id == 25)) {
        if (('slug' in obj)) {
          return true;
        } else {
          return false;
        }
      }

  return (
    <Conteudo>
       {(props.user.roles.filter(ftEditar).length>0)?
        <div className="minhaconta" >
            {'Minha Conta '}          
             <IconButton onClick={()=> setEditar(true)}>
                  <EditIcon style={{width:'20px'}}/>
              </IconButton> 
        </div>
          :''}
        {minhaconta?
        <div>
            <div className="informacoes">
                <b>Usuário:</b> {minhaconta.name} <br/> 
                <b>Email:</b> {minhaconta.email} <br/> 
                <b>Função:</b> {minhaconta.role[0].name}<br/>
                <b>Senha:</b> <span style={{color:'#054f77', cursor:'pointer'}} onClick={()=> setEditsenha(true)}> Alterar senha</span><br/>
                <br/>
                <b>Minhas Permissões: </b>
                <Paper component="ul" className={classes.root}>
                  {minhaconta.role[0].permission.map((data) => {
                    let icon;

                    if (data.label === 'xxxxx') {
                    icon = <TagFacesIcon />;
                    }
                    return (
                        <li key={data.key}>
                          <Chip
                            icon={icon}
                            label={data.name}
                            // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                            className={classes.chip}
                          />
                        </li>
                      );
                    })}

                </Paper>

            </div>
        </div>:''}
        {editar? <Configuracoesnew user={props.user}  info={minhaconta} fecharDialogo={()=> {setEditar(false); carregamento()}} />:''}
        {editsenha? <Configuracoessenha user={props.user}  info={minhaconta} fecharDialogo={()=> setEditsenha(false)} />:''}
    </Conteudo>
    );
}

export default Configuracoesmeu;