import React from 'react';
import Apinews from '../../services/apinews';
import { Conteudo } from './styles';

function Requisicaorss(props) {

    const [noticias,setNoticias] = React.useState([]);
    const [tamanho, setTamanho] = React.useState('');

    const consultarRss=e=>{
        Apinews.get()
        .then(e=>{
            // console.log(e.data.items);
            setNoticias(e.data.items);
        }).then(error=>{
            console.log(error);
        })
    }
    React.useEffect(e=>{
        if(noticias.length==0){
            consultarRss();
        }else{
            setTimeout(e=>{
                consultarRss();
            },120000);
        }
        var elemento = document.querySelector('#camponoti').getBoundingClientRect();
        setTamanho(window.innerHeight - elemento.top);
        // console.log('Distância: ', window.innerHeight - elemento.top);
    },[])

  return (
      <Conteudo tamanho={tamanho} open={props.open}>
          <h6>Ultimas Notícias</h6>
          <div className='lista-noticias' id="camponoti">
            {noticias.map((a,i)=>(
                <div key={i} className='noticia'>
                    <a href={a.link} target="_blank" className="noticiaLink">
                        {a.thumbnail? <img src={a.thumbnail} className='mini-imagem'/>:''}
                        <p>{a.title.slice(0,60)} (...)</p>
                    </a>
                </div>
            ))}
          </div>
      </Conteudo>
  );
}

export default Requisicaorss;