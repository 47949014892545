import React from 'react';
import { Customh3 } from './styles';

function Tituloh3(props) {
  return(
    <Customh3>
        <h3>{props.text}</h3>
        {props.description}
    </Customh3>
  );
}

export default Tituloh3;