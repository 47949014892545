import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '',
      },
    },
  }));

export default function Agendaoptions(props) {

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('body');

  const [info, setInfo] = React.useState({
      id: props.dados.id,
      title: props.dados.title,
      start_date: props.dados.start_date,
      end_date: props.dados.end_date,
      duration: props.dados.duration,
      location: props.dados.location,
      hoster: props.dados.hoster,
      description: props.dados.description,
      image: props.dados.image_url,
  })

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Button onClick={handleClickOpen('body')}>Editar</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">Editar informações do evento</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
          {/* Campos a serem alterados */}
          <form className={classes.root} noValidate autoComplete="off">
            <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="component-outlined">Título</InputLabel>
                <OutlinedInput id="component-outlined" value={info.title} onChange={e=>setInfo({...info, title:e.target.value})} label="Título" />
            </FormControl>
            <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="component-outlined">Duração</InputLabel>
                <OutlinedInput id="component-outlined" value={info.duration} onChange={e=>setInfo({...info, duration:e.target.value})} label="Duração" />
            </FormControl>
            <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="component-outlined">Local</InputLabel>
                <OutlinedInput id="component-outlined" value={info.location} onChange={e=>setInfo({...info, location:e.target.value})} label="Local" />
            </FormControl>
          </form>

          {/* Fim de campos a serem alterados  */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}