import styled from 'styled-components';

export const Customh3 = styled.div`
      text-align: left;
      font-family: Arial, Helvetica, sans-serif;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      padding: 0.5rem;
      color: rgba(0,0,0,0.7);

     h3{
     }
`;