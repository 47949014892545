import React, { useCallback } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import DeleteIcon from '@material-ui/icons/Delete'
import Divider from '@material-ui/core/Divider'

// impressao
import logoAlan from '../../files/images/comprovante/logoAlan.png'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation'
import RoomIcon from '@material-ui/icons/Room'
// fim impressão

import { Conteudo, CampoDeComentarios, NovoComentario } from './styles'

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { ptBR } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns'

// api
import API from '../../services/api'

// dropdown
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import Dialogodeconfirmacao from '../Dialogosdeconfirmacao'

// lista
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FolderIcon from '@material-ui/icons/Folder'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

// icones
import MenuBookIcon from '@material-ui/icons/MenuBook'
import MailIcon from '@material-ui/icons/Mail'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import ScheduleIcon from '@material-ui/icons/Schedule'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import WorkIcon from '@material-ui/icons/Work'
import VpnKeyIcon from '@material-ui/icons/VpnKey'

import { format } from 'date-fns'

// interruptor
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}))

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  )
}

// condigo do interruptor
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
})

export default function Relatorioview(props) {
  const classes = useStyles()
  const [dense, setDense] = React.useState(false)
  const [secondary, setSecondary] = React.useState(false)
  const [showComents, setShowComents] = React.useState(false)
  const [open, setOpen] = React.useState(true)
  const [comentarios, setComentarios] = React.useState([])
  const [confirmacao, setConfirmacao] = React.useState([false, ''])
  const [novocomment, setNovocomment] = React.useState({
    message: '',
    user_id: props.user.id,
    subscriber_id: props.info.id,
  })

  // interruptor
  const [state, setState] = React.useState({
    status: props.info.status,
  })

  const [infoPagamentos, setInfoPagamentos] = React.useState({
    event: props.info.event,
    course_id: props.info.course_id,
    name: props.info.name,
    cpf: props.info.cpf,
    company: props.info.company,
    number: props.info.number,
    email: props.info.email,
    payment: props.info.payment,
    note: props.info.note,
    status: props.info.status,
    date: props.info.date,
    payment_method: props.info.payment_method ? props.info.payment_method : '',
    payment_date: props.info.payment_date
      ? props.info.payment_date
      : new Date(),
  })

  const [liberarEditar, setLiberarEditar] = React.useState(false)
  const [auxiliar, setAuxiliar] = React.useState(true)

  const handleDateChange = (date) => {
    setInfoPagamentos({ ...infoPagamentos, payment_date: date })
  }

  const submeterMetodoDePagamento = (e) => {
    e.preventDefault()

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'Access-Control-Allow-Credentials': 'true',
      },
    }

    API.put(`private_subscriptions/${props.info.id}`, infoPagamentos, config)
      .then((a) => {
        setInfoPagamentos(a.data)
        setLiberarEditar(false)
        console.log(a)
      })
      .catch((error) => {
        console.log(error.response)
      })
  }
  const handleChange = (event) => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    setState({ ...state, status: event.target.value })
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'Access-Control-Allow-Credentials': 'true',
      },
    }

    const dados = {
      name: props.info.name,
      cpf: props.info.cpf,
      company: props.info.company,
      number: props.info.number,
      email: props.info.email,
      payment: props.info.payment,
      note: props.info.note,
      status: event.target.value,
    }

    API.put(`private_subscriptions/${props.info.id}`, dados, config)
      .then((a) => {
        setInfoPagamentos({ ...infoPagamentos, status: event.target.value })
        setLiberarEditar(false)
        // console.log(a);
      })
      .catch((error) => {
        console.log(error.response)
      })
  }

  const carregarComentarios = useCallback((e) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'Access-Control-Allow-Credentials': 'true',
      },
    }

    API.get(`/attendance-comments/${props.info.id}`, config)
      .then((a) => {
        setComentarios(a.data)
        // setLiberarEditar(false);
        // console.log(a);
      })
      .catch((error) => {
        console.log(error.response)
      })
  })

  const criarComentario = (e) => {
    e.preventDefault()

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        'Access-Control-Allow-Credentials': 'true',
      },
    }

    if (novocomment.message) {
      API.post(`/attendance-comments/`, novocomment, config)
        .then((a) => {
          carregarComentarios()
          setNovocomment({ ...novocomment, message: '' })
          if (!showComents) setShowComents(!showComents)
          // setLiberarEditar(false);
          // console.log(a);
        })
        .catch((error) => {
          console.log(error.response)
        })
    }
  }

  const deleteComentario = (e) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.user.token,
      },
    }
    API.delete(`/attendance-comments/${e}`, config)
      .then((a) => {
        // window.location.reload();
        carregarComentarios()
        setConfirmacao([false, ''])
      })
      .catch((error) => {
        console.log(error.response)
      })
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    props.fecharDialogo()
  }

  React.useEffect(
    (e) => {
      if (props.info.payment_method && props.info.payment_date && auxiliar) {
        setAuxiliar(false)
      }
      if (comentarios.length == 0) carregarComentarios()
    },
    [
      props.info.payment_method,
      props.info.payment_date,
      auxiliar,
      comentarios.length,
      carregarComentarios,
    ]
  )

  const imprimir = (e) => {
    var conteudo = document.getElementById('div_imprimir').innerHTML
    var rodape = document.getElementById('rodapeDaPagina').innerHTML
    var tela_impressao = window.open('about:blank')

    tela_impressao.document.write(`
    
    <html lang="pt-BR">
      <head>
        <style>
        
        @media print  
        {
          @page { margin: 0;  -webkit-print-color-adjust: exact;}

            font-size: 16px !important;
            div{
                page-break-inside: avoid;
            }
            label{
              page-break-inside: avoid;
            }
            -webkit-print-color-adjust: exact;
        }

            body{
              font-family: Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif; 
              font-size: 16px !important;
              width:100%;
              height: ${window.innerHeight}px;
              margin: 0px;
              padding: 0px;
            }

            .logoInicial{
                width: 100%;
                text-align:center;  
            }
            .imagemlogo{
              width: 250px;
            }
            .tituloDoCurso{
                width: 100%;
                text-align:center;

            }
            h3{
              background-color: #005451;
              padding: 2rem;
              color: #fff;
              margin: 0.2rem 3rem;
              -webkit-print-color-adjust: exact;
            }
            .dataEHora{
                margin: 1rem 3rem;
                font-size: 18px;
            }
            svg{
              width: 20px;
              margin-bottom: -4px;
            }
            .zonaDoAluno{
              font-size: 16px;
              margin: 1rem 0rem;
              background-color: #ddd;
              border: 1px solid #000;
              padding: 2rem;
              -webkit-print-color-adjust: exact;
            }
            .zonaDePagamento{
              text-align: right;
              font-size: 14px !important;
            }
            .dadosDoResponsavel{
              margin-top: 5rem;
              text-align: center;
              font-size: 13px !important;
            }
            .rodapeDaPagina{
              text-align: center;
              font-size: 13px;
              position: absolute;
              bottom: 0px;
              width: 100%;
              padding: 0.5rem 0rem;
              margin:0rem;
              border-bottom: 10px solid #1e1e1e;
            }
            .imagemlogoas{
              margin-bottom: -2.2rem;
              width: 300px;
            }
             
        </style>
      </head>
      <body>
          <div>
              <div class="logoInicial"><img class="imagemlogo" src=${window.location.origin}/files/images/comprovante/logoAlan.png></div>
              <div class="tituloDoCurso"><h3>Curso de Fibra Óptica (Redes FTTh)</h3></div>
              <div class="dataEHora">
                ${conteudo}
              </div>
              <div>
                ${rodape}
              </div>
          </div>
        
      </body>
    </html>
    `)
    setTimeout((e) => tela_impressao.window.print(), 200)
    setTimeout((e) => tela_impressao.window.close(), 300)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        scroll="body"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <b>{props.info.name}</b>{' '}
          <FiberManualRecordIcon
            style={{
              color:
                state.status == 0
                  ? '#ffed00'
                  : state.status == 1
                  ? '#52d869'
                  : 'rgba(255,25,60)',
            }}
          />
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            descrição aleatória
          </DialogContentText> */}

          {/* <Typography variant="h6" className={classes.title}>
              Icon with text
            </Typography> */}
          <DialogContentText id="alert-dialog-description">
            <div id="div_imprimir" style={{ display: 'none' }}>
              <div>
                <InsertInvitationIcon />{' '}
                {format(new Date(infoPagamentos.event.start_date), 'dd', {
                  locale: ptBR,
                })}{' '}
                e{' '}
                {format(new Date(infoPagamentos.event.end_date), 'dd', {
                  locale: ptBR,
                })}{' '}
                de{' '}
                {format(
                  new Date(infoPagamentos.event.start_date),
                  'LLLLLLLLL',
                  { locale: ptBR }
                )}
                , 08:00h às 18:00h
              </div>
              <div>
                <RoomIcon /> {infoPagamentos.event.location}
              </div>
              <div className="zonaDoAluno">
                <div>
                  <b>Inscrição</b>
                  <br />1 aluno - {infoPagamentos.payment} <br />
                  <br />
                  <b>Participante</b>
                  <br />
                  {infoPagamentos.name} - {infoPagamentos.cpf}
                </div>
                <br />
                <div className="zonaDePagamento">
                  Pagamento realizado dia{' '}
                  {format(new Date(infoPagamentos.payment_date), 'dd', {
                    locale: ptBR,
                  })}{' '}
                  de{' '}
                  {format(
                    new Date(infoPagamentos.payment_date),
                    'LLLLLLLLLLLLLL',
                    { locale: ptBR }
                  )}{' '}
                  de{' '}
                  {format(new Date(infoPagamentos.payment_date), 'yyyy', {
                    locale: ptBR,
                  })}
                  .<br />
                  {infoPagamentos.payment_method}.
                </div>
              </div>
              <div className="dadosDoResponsavel">
                <img
                  class="imagemlogoas"
                  src={`${window.location.origin}/files/images/comprovante/aaadaaadaaad.png`}
                />
                <br />
                ________________________________________
                <br />
                <br />
                Antonio Alan Rodrigues de Araújo, MSc
                <br />
                Engenheiro de Computação
                <br />
                Mestre em Engenharia Elétrica e de Computação
                <br />
                CREA/CE: 51905-D
                <br />
                Registro Nacional: 0612438147
                <br />
                CNPJ: 25.315.224/0001-13
                <br />
              </div>
            </div>
            <div id="rodapeDaPagina" style={{ display: 'none' }}>
              <div className="rodapeDaPagina">
                Eng. Alan Araújo – Engenharia de Telecomunicações <br />
                Ágil Engenharia de Telecomunicações EIRELI – 25.315.224/0001-13
                <br />
                (88) 9 9643.7710 | (88) 9 9352.8826 | www.alanaraujo.eng.br |
                alanaraujo.eng@gmail.com <br />
              </div>
            </div>
            <div
              style={{
                lineHeight: '16px',
                fontSize: '14px',
                border: '2px dashed rgba(0,0,0,0.2)',
                borderRadius: '5px',
                padding: '1rem',
              }}
              id="sua_div"
            >
              <div>Informações Gerais</div>
              <br />
              <MenuBookIcon style={{ width: '1.1rem', margin: '0.2rem' }} />
              <b></b>
              {`[${props.info.event.location}] ${new Date(
                props.info.event.start_date
              ).toLocaleDateString('pt-BR')} - ${props.info.event.title}`}
              <br />
              <VpnKeyIcon style={{ width: '1.1rem', margin: '0rem 0.2rem' }} />
              <b>Código:</b> {props.info.code} <br />
              <AssignmentIndIcon
                style={{ width: '1.1rem', margin: '0.2rem' }}
              />
              <b>CPF:</b>{' '}
              {props.info.cpf ? props.info.cpf : '(CPF não informado)'}
              <br />
              <MailIcon style={{ width: '1.1rem', margin: '0.2rem' }} />
              <b>Email:</b> {props.info.email}
              <br />
              <PhoneIphoneIcon style={{ width: '1.1rem', margin: '0.2rem' }} />
              <b>Telefone:</b> {props.info.number}
              <br />
              <WorkIcon style={{ width: '1.1rem', margin: '0.2rem' }} />
              <b>Empresa:</b>{' '}
              {props.info.company
                ? props.info.company
                : '(Empresa não informada)'}
              <br />
              <LocalAtmIcon style={{ width: '1.1rem', margin: '0.2rem' }} />
              <b>Pagamento:</b> {props.info.payment}
              <br />
              <ScheduleIcon style={{ width: '1.1rem', margin: '0.2rem' }} />
              <b>Inscrição:</b>{' '}
              {format(new Date(props.info.createdAt), "dd/MM/yyy 'às' kk':'mm")}
              <br />
              <SpeakerNotesIcon style={{ width: '1.1rem', margin: '0.2rem' }} />
              <b>Observação:</b>
              <div style={{ padding: '0.5rem 1rem', textAlign: 'justify' }}>
                {props.info.note ? props.info.note : '(Sem observação)'}
              </div>
              <br />
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0rem 1rem',
                }}
              >
                {/* <FormControlLabel
                      control={<IOSSwitch checked={state.paid} onChange={handleChange} name="paid" />}
                      label="Pagamento confirmado"
                      value="top"
                      labelPlacement="top"
                    /> */}
                <FormControl
                  variant="outlined"
                  className=""
                  margin="dense"
                  fullWidth
                >
                  <InputLabel
                    id="posicao"
                    style={{ backgroundColor: '#fff', padding: '0rem 0.5rem' }}
                  >
                    Status da Inscrição
                  </InputLabel>
                  <Select
                    labelId="posicao"
                    id="dposicao"
                    value={state.status}
                    onChange={(e) => {
                      handleChange(e)
                    }}
                  >
                    <MenuItem value="">{/* <em>None</em> */}</MenuItem>
                    <MenuItem value="0">Pagamento Não Confirmado</MenuItem>
                    <MenuItem value="1">Pagamento Confirmado</MenuItem>
                    <MenuItem value="2">Inscrição Cancelada</MenuItem>
                  </Select>
                </FormControl>

                <Conteudo>
                  {props.info.payment_method &&
                    props.info.payment_date &&
                    infoPagamentos.status == 1 && (
                      <div className="botaoParaBaixarComprovante">
                        <Button onClick={() => imprimir()}>
                          <AttachFileIcon /> Imprimir comprovante
                        </Button>
                      </div>
                    )}
                </Conteudo>

                {infoPagamentos.status == 1 && (
                  <form onSubmit={submeterMetodoDePagamento}>
                    <TextField
                      value={infoPagamentos.payment_method}
                      onChange={(e) =>
                        setInfoPagamentos({
                          ...infoPagamentos,
                          payment_method: e.target.value,
                        })
                      }
                      margin="dense"
                      id="location"
                      label="Método de pagamento (ex.: Transferência bancária BB)"
                      type="text"
                      required
                      fullWidth
                      disabled={!liberarEditar}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                      <KeyboardDatePicker
                        disabled={!liberarEditar}
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline-2"
                        label="Data de pagamento"
                        // className={classes.textJust}
                        value={infoPagamentos.payment_date}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        required
                        fullWidth={true}
                        // disabled = {values.abrirDialogo.estado}
                      />
                    </MuiPickersUtilsProvider>
                    {liberarEditar && (
                      <Button
                        variant="contained"
                        style={{ margin: '1rem 0rem' }}
                        type="submit"
                      >
                        Atualizar dados de pagamento
                      </Button>
                    )}
                    {!liberarEditar && (
                      <Button
                        onClick={() => setLiberarEditar(true)}
                        variant="contained"
                        style={{ margin: '1rem 0rem' }}
                      >
                        Editar
                      </Button>
                    )}
                  </form>
                )}
              </div>
            </div>

            <CampoDeComentarios showComents={showComents}>
              <br />

              <div
                className="comentHeader"
                onClick={() => setShowComents(!showComents)}
              >
                <h5>
                  <div className="iconAqui">&#10148;</div> Comentários (
                  {comentarios.length})
                </h5>
              </div>
              <Divider style={{ width: '117%', marginLeft: '-3rem' }} />
              <div className="baloes">
                {comentarios.length > 0 ? (
                  comentarios
                    .map((comment, i) => (
                      <div className="control">
                        <div
                          className={
                            comment.user_id == props.user.id
                              ? 'balao2'
                              : 'balao'
                          }
                        >
                          <div className="balaoNome">
                            {comment.user.name}{' '}
                            {comment.user_id == props.user.id ? (
                              <DeleteIcon
                                onClick={() =>
                                  setConfirmacao([true, comment.id])
                                }
                              />
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="balaoText">{comment.message}</div>
                          <div className="balaoData">
                            {format(
                              new Date(comment.createdAt),
                              'dd/MM/yyyy HH:mm',
                              { locale: ptBR }
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                    .reverse()
                ) : (
                  <div className="nenhumEncontrado">
                    Nenhum comentário cadastrado.
                  </div>
                )}

                {/* <div className="control">
                  <div className="balao2">
                    <div className="balaoNome">
                      <DeleteIcon /> Lorenna
                    </div>
                    <div className="balaoText">
                      O que eu faço?
                      Descubro o dia e horas, leio palavras e frases e soletro, falo o abc, conto números.
                    </div>
                    <div className="balaoData">
                      26/07/2021 08:30
                    </div>
                  </div>
                </div> */}

                {/* <div className="control">
                  <div className="balao">
                    <div className="balaoNome">
                      Lorenna
                    </div>
                    <div className="balaoText">
                      O que eu faço?
                      Descubro o dia e horas, leio palavras e frases e soletro, falo o abc, conto números, faço operações matemáticas, faço adivinhações, etc...
                    </div>
                    <div className="balaoData">
                      26/07/2021 08:30
                    </div>
                  </div>
                </div> */}
              </div>
            </CampoDeComentarios>

            <NovoComentario>
              <label>
                Você está comentando como <i>{props.user.name}</i>
              </label>
              <form onSubmit={criarComentario}>
                <textarea
                  placeholder="Adicionar comentário"
                  multiline
                  required
                  rows={4}
                  className="campoDeTexto"
                  value={novocomment.message}
                  onChange={(e) =>
                    setNovocomment({ ...novocomment, message: e.target.value })
                  }
                />
                <button>Comentar</button>
              </form>
            </NovoComentario>
          </DialogContentText>

          {confirmacao[0] ? (
            <Dialogodeconfirmacao
              tamanho="xs"
              message="Tem certeza de que deseja deletar este comentário?"
              confirmar={() => deleteComentario(confirmacao[1])}
              negar={() => setConfirmacao([false, ''])}
            />
          ) : (
            ''
          )}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
            Disagree
          </Button> */}
          <Button onClick={handleClose} color="primary">
            Retornar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
