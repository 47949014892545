import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {Link, useParams} from 'react-router-dom';
import API from '../../services/api';
import Moment from 'react-moment';
import { parseISO, isAfter } from 'date-fns';

import Agendaoptions from '../Agendaoptions';
import Dialogodeconfirmacao from '../Dialogosdeconfirmacao';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

import Agendanew from '../Agendanew';
// paginação
import Pagination from '@material-ui/lab/Pagination';

// cards 
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

// nova postagem
import {Button} from '@material-ui/core';
import Blognew from '../Blognew';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: window.innerWidth>675? 'row':'column',
        marginBottom: '1rem',
      },
      rootrascunho:{
        display: 'flex',
        flexDirection: window.innerWidth>675? 'row':'column',
        marginBottom: '1rem',
        opacity: 0.8,
        ['&:hover']:{
          opacity:1,
        }
      },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: window.innerWidth>675? '66.66%':'90%',
    flexShrink: 0,
    display:'flex',
    flexDirection: 'row',
    opacity: '80%',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    textAlign:'right !important',
  },
  maisDetalhes:{
    display: 'flex',
    flexDirection: window.innerWidth>675? 'row': 'column',
    fontSize:'16px',
    width: '100%'
  },
  maisInformacoes:{
    flex: 1,
    opacity:'90%',
    marginBottom: '2rem',
    '& a':{
        padding:'0.5rem 2rem',
        borderRadius:'5px',
        textDecoration:'none',
        border: '0px',
        display: 'inline-block',
    },
  },
  maisImagem:{
    flex: 1,
    '& img':{
        width: '90%',
        minHeight: 'auto',
        maxWidth:'400px',
        // float:'left'
    }
  },
  pagina: {
    display:'flex',
    flexDirection:'column',
    alignContent:'center',
    alignItems: 'center',

    '& > *': {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),

    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth:window.innerWidth>675? '80%': '100%',
    textAlign:'left',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    // width: window.innerWidth>675? '200':'100%',
        minHeight: 200,
        minWidth: '20%',
        width: window.innerWidth>675? 'auto':'100%',
        height: window.innerWidth>675? '' :200,
        backgroundSize: window.innerWidth>675? 'auto 100%':'auto 100%',
        // backgroundSize: window.innerWidth>675? '40vw auto':'100%',
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
        cursor:'pointer',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  info:{
    
      fontSize:'14px',
  },
  titu:{
      '&:hover':{
        color: 'rgba(0, 83, 81)',
        textShadow: '0px 0px 2px rgba(0,0,0,0.3)',
        cursor: 'pointer',
      }
  },
  prev:{
      padding:window.innerWidth>675? '1rem':'0.5rem',
  },

  aberto:{
    display: 'flex',
    flexDirection: 'column',
  },
  logoaberto:{
    minHeight: window.innerWidth>675?'400px':'200px',
    minWidth: '20%',
    width: window.innerWidth>675? 'auto':'100%',
    height: window.innerWidth>675? '' :200,
    backgroundSize: window.innerWidth>675? '100% auto ':'auto 100% ',
    // backgroundSize: window.innerWidth>675? '40vw auto':'100%',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',

  },
  textoAberto:{
    display: 'flex',
    flexDirection: 'column',
    maxWidth:window.innerWidth>675? '100%': '100%',
    textAlign:'left',
  },
  botaoAberto:{
    border: '0px',
    padding: '1rem',
    backgroundColor: 'rgba(0,0,0,0.2)',
    color: 'rgba(0,0,0,0.5)',
    fontWeight: 'bold',
  }
}));



export default function Agendaaccordion(props) {
  const {id} = useParams();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const [lista, setLista] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [confirmacao, setConfirmacao] = React.useState([false,'']);

  const [nbpg, setNbpg] = React.useState('1');

  const [editevent, setEditevent] = React.useState({
    status: false,
    dados:'',
  });

//   cards mostrar 
  const [mostrar,setMostrar] = React.useState(false);
  const [idpost,setIdpost] = React.useState(id);
  const [onepost,setOnepost] = React.useState([
    {
      title:'',autor:'',date:'',description:'', image:'',
    }
  ]);

//   nova postagem
const [newevent, setNewevent] = React.useState(false);


  React.useEffect(()=>{
    if(lista.length==0) consultarApi();
  },[]);

  const consultarApi= async e=>{
    const config = {
      headers: {
        'Authorization': 'Bearer ' + props.user.token,
      }
    };

    await API.get('private_posts',config)
    .then(a=>{
          setLista(a.data)
    }).catch(error=>{
      console.log(error.response);
    });

    setShow(true);
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const deleteEvent=e=>{
    const config = {
      headers: {
        'Authorization': 'Bearer ' + props.user.token,
      }
    };
    API.delete(`posts/${e}`, config)
    .then(a=>{
        window.location.reload(); 
    }).catch(error=>{
      console.log(error.response);
    });
  
  }
  const paginacao = (event, value) => {
    setNbpg(value);
  };

  //FUnção das postagens
  const abrirOnepost=(e,id)=>{
    setOnepost({
      id: id,
      title: e.title,
      author: e.author,
      date: e.date,
      description: e.body,
      image_url: e.image_url,
    });
    setIdpost(id);
    setMostrar(true);
    window.scrollTo(0, 0);
}
const fecharOnepost=e=>{
  setMostrar(false);
  setIdpost('');
  setIdpost(false);
  window.scrollTo(0, 0);
}


  var aux;
  var npp = 5;

  // filtros 
const ftCriar=obj=>{
  if (('slug' in obj && obj.slug =='create_posts')) {
    return true;
  } else {
    return false;
  }
}
const ftListar=obj=>{
  if (('id' in obj)) {
  // if (('id' in obj)) {
    return true;
  } else {
    return false;
  }
}
const ftEditar=obj=>{
  if (('slug' in obj && obj.slug =='edit_posts')) {
    return true;
  } else {
    return false;
  }
}
const ftDeletar=obj=>{
  if (('slug' in obj && obj.slug =='delete_posts')) {
    return true;
  } else {
    return false;
  }
}

  return (
    <div style={{maxWidth:'1024px', margin:'auto'}}>
        {(mostrar || idpost)? '' :
          (props.user.roles.filter(ftCriar).length>0)?
          <div style={{textAlign:'right'}}>
            <Button onClick={()=> setNewevent(true)} variant='contained' color='secondary'>Nova Postagem</Button>
           
          </div>
          :''
          }
           <br/>
        {(mostrar || idpost)?'' : 
        (props.user.roles.filter(ftListar).length>0)?
          lista.slice(((nbpg-1)*npp),(nbpg*npp)).map((a,i)=>(
            <div>
                <Card className={a.publish? classes.root:classes.rootrascunho} key={i} style={{backgroundColor: a.publish? '':'rgba(255,237,0,.5)'}}>

                    <CardMedia
                        className={classes.cover}
                        image=""
                        title={a.title}
                        onClick={()=>abrirOnepost(a,i)}
                        style={{
                            backgroundImage:`url(${a.image_url})`,
                        }}
                    />
          
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <Typography component="h5" variant="h5" className={classes.titu} onClick={()=>abrirOnepost(a,i)}>
                      {a.title}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" className={classes.info}> 
                      Por {a.author} - <Moment format="DD/MM/YYYY">{a.date}</Moment>
                    </Typography>
                  </CardContent>
                  <div className={classes.controls}>
                    <Typography variant="subtitle1" color="textSecondary" className={classes.prev} onClick={()=>abrirOnepost(a,i)} style={{cursor:'pointer',}}> 
                        <div style={{fontSize:'14px',padding:'0.5rem'}} dangerouslySetInnerHTML={{ __html:(a.body.substr(0,350)+" (...)")}}></div>
                    </Typography>
                  </div>
                </div>
                
              </Card>
              </div>
          )):''}

           {/* mostrar apenas uma postagem */}
           {(mostrar || (lista[idpost] && idpost))? 
          <Card className={classes.aberto}>
                <div style={{textAlign:'right'}}>
                    {(props.user.roles.filter(ftEditar).length>0)?
                    <Button onClick={()=> setEditevent({...editevent, status: true,dados:lista[idpost]})} variant='contained' color='secondary'><CreateIcon/> Editar</Button>
                    :''}
                     {(props.user.roles.filter(ftDeletar).length>0)?
                    <Button style={{marginLeft:'1rem'}}  onClick={()=>setConfirmacao([true,lista[idpost].id])} variant='contained' color=''><DeleteIcon/> Excluir</Button>
                    :''}
                    <br/>
                    <br/>
                </div>

          <CardMedia
              className={classes.logoaberto}
              image=""
              title={idpost? lista[idpost].title:onepost.title}
              style={{
                  backgroundImage:`url(${idpost? lista[idpost].image_url: onepost.image_url})`,
                  backgroundPosition: 'center center'
              }}
          />
      
            <div className={classes.textoAberto}>
              <CardContent className={classes.content}>
                <Typography component="h5" variant="h5" className={classes.titu}>
                  {idpost? lista[idpost].title : onepost.title}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" className={classes.info}> 
                  Por {idpost? lista[idpost].author:onepost.author} - <Moment format="DD/MM/YYYY">{idpost? lista[idpost].date:onepost.date}</Moment>
                </Typography>
              </CardContent>
              <div className={classes.controls}>
                <Typography variant="subtitle1" color="textSecondary" className={classes.prev}> 
                  <div dangerouslySetInnerHTML={{ __html:idpost? (""+lista[idpost].body):onepost.description}} />
                </Typography>
              </div>
              <br/>
            </div>
            
            <button onClick={fecharOnepost}  className={classes.botaoAberto}>Voltar</button>

            {/* <iframe src={`http://www.facebook.com/plugins/comments.php?href=https://alanaraujo.netlify.app/blog/${idpost? idpost:onepost.id}&numposts=5`} scrolling="yes" frameborder="0" style={{border:'none', overflow:'hidden', width:'90%', minHeight:'500px', margin:'auto'}} allowTransparency="true">Carregando...</iframe> */}

          </Card>
          :''}

          {(mostrar || idpost)?'':
            <div className={classes.pagina}>
              
              {show? <Pagination count={(Math.ceil(lista.length)/npp)} variant="outlined" shape="rounded" onChange={paginacao}/>:''}
            </div>}

      {confirmacao[0]?
      <Dialogodeconfirmacao
        tamanho="xs"
        message="Tem certeza de que deseja excluir esta postagem?"
        confirmar={()=>deleteEvent(confirmacao[1])}
        negar={()=>setConfirmacao([false,''])}
      />:""}

        {editevent.status?
          <Blognew user={props.user} fecharDialogo={()=> {setEditevent({...editevent,dados:'', status: false});consultarApi()}} info={editevent.dados}/>
        :''}

         {/* Diálogo de adicionar novo  */}
         {newevent?
          <Blognew user={props.user} fecharDialogo={()=> {setNewevent(false);consultarApi()}} info={editevent.dados}/>
        :''}
    </div>
  );
}